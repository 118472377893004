.menu-item-list-page {
  padding: 20px;
  background-color: #fff;

  h6 {
    margin-bottom: 20px;
    color: #333;
  }

  hr {
    margin: 15px 0;
  }

  .menu-item-form {
    display: flex;
    flex-direction: column;

    .form-row {
      display: flex;
      justify-content: space-between;
     
      margin-bottom: 20px;

      .form-column {
        display: flex;
        flex-direction: column;
       
        padding: 10px;

        label {
          font-weight: 600;
          margin-bottom: 5px;
          
        }

        .dropdown {
          

          button.btn-dropdown {
            width: 100%;
            color: black; /* Dropdown text color */
            border: 1px solid #ddd;
            border-radius: 5px;
            padding: 8px;
          }

          .dropdown-menu {
           
          }
        }

        .checkbox-group {
          display: flex;
          align-items: center;
          margin-top: 10px;
          gap: 15px; /* Add gap for better spacing between checkboxes and labels */
          
          label {
            margin-right: 10px;
            font-size: 14px; /* Font size for checkboxes text */
          }

          input[type="checkbox"] {
            width: 16px;
            height: 16px; /* Adjust checkbox size */
          }
        }

        .radio-group {
          display: flex;
          align-items: center;
          margin-top: 10px;
          gap: 15px; /* Add gap for better spacing between radio buttons and labels */

          label {
            margin-left: 10px;
            font-size: 14px; /* Font size for radio buttons text */
          }

          input[type="radio"] {
            width: 16px;
            height: 16px; /* Make radio buttons a bit larger for better visibility */
          }
        }
      }
    }
 /* Description Area */
 .description-area {
  margin-bottom: 20px;

  label {
    font-weight: 600;
    
    color: #333;
    margin-bottom: 10px;
  }

  textarea {
    width: 100%;
    min-height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    resize: vertical;
  }
}

/* Item Suggestions Section */
.item-suggestions-section {

  border-radius: 5px;

  h6 {
    background-color: #e7f3ff; /* Blue background */
    
   padding:10px 10px;
    color: #333;
    margin-top: 30px;

  }

  .suggestions-row {
    margin-bottom: 20px;

    .suggestions-column {
      label {
        font-weight: 600;
       
        color: #333;
        margin-bottom: 10px;
      }
    }
  }

  .suggestions-info {
    label {
      font-weight: 600;
     
      color: #333;
    }

    p {
      margin-top: 5px;
     
      color: #888;
    }
  }
}
.tags-information-section {
 
   
  .section-title{
    background-color: #e7f3ff !important; /* Blue background */
        
       padding:10px 10px;
        color: #333;
        margin-top: 30px;
      }

  .general-container{
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

.general-title{
  background-color: #ddd;
  padding:10px;
}
  .section-title1 {
  
  
    font-weight: bold;
    margin-bottom: 20px;
  }

  .form-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width:100%;

    .cuisine-tags-input {
      padding: 8px;
      width:100%;
     
      border-radius: 4px;
      border: 1px solid #ced4da;
    }
  }

  .checkbox-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .checkbox-column {
      display: flex;
      flex-direction: column;
     
      justify-content: flex-start;

      .checkbox-row {
        display: flex;
        align-items: center;
        gap: 10px;
        label {
          font-weight: bold;
        }
      }

      span {
        margin-top: 4px;
        
        color: var(--dark-blue);
      }
    }
  
  }
}
.swiggy-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .form-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .form-column {
      flex: 1;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      label {
        display: block;
        font-weight: initial;
        margin-bottom: 8px;
      }

      .input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input, .dropdown-select {

          margin:0px;
          padding: 8px;
          border: 1px solid #ced4da;
          border-radius: 4px;
        }
      }

      .input-text, .textbox-component input,
      .textbox-component textarea {
       
        padding: 8px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      .dropdown-select {
       
        padding: 8px;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }

      .checkbox-group {
        display: flex;
        align-items: center;
       
      
        input[type="checkbox"] {
          margin-right: 10px;
          width: 16px; // Adjust size if necessary
          height: 16px;
        }
      
        label {
          font-weight: normal;
          margin-left: 5px;
          white-space: nowrap; // Prevent the text from wrapping
        }
    
  }
}
  }
}
 
  .zomato-checkbox-container {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    .checkbox-row {
      display: flex;
      flex-wrap: wrap; // Allows items to wrap to the next row if needed
      justify-content: flex-start; // Align all items to the left
      column-gap: 20px; // Space between checkbox items
      row-gap: 10px; // Space between rows when wrapping
    }
  
    .checkbox-item {
      display: inline-flex; // Forces checkbox and label to stay together in a row
      align-items: center; // Vertically align checkbox with label
      gap: 8px; // Space between checkbox and label
    }
  
    label {
      
      white-space: nowrap; // Prevents label from wrapping onto the next line
    }
  }
  .variation-form-main-container{
    padding:20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  
  .variation-form-container,.variation-form-container1  {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
   
  
    .form-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
  
      .variation-title-container {
        display: flex;
        align-items: center;
  
        .variation-title {
          margin-left: 10px;
          font-weight: bold;
        }
      }
  
      .add-variation-btn {
        background-color:var(--dark-blue);
        color: white;
        padding: 5px 20px;
        border-radius: 5px;
        border: none;
      }
    }
  
    .form-grid {
      display: grid;
      grid-template-columns: repeat(5, 1fr); /* Adjust column count */
      gap: 20px;
  
      .form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  
        label {
          font-weight: 500;
          margin-bottom: 5px;
        }
  
        &.full-width {
          grid-column: span 5; /* Make serves and combo span full width */
        }
      }
  
      /* Adjusting combo-checkbox for alignment */
      .combo-checkbox {
        display: flex;
        align-items: center; /* Ensure checkbox and label are aligned vertically */
        flex-direction: row; /* Align checkbox and label horizontally */
        margin-top: 10px; /* Space between dropdown and checkbox */
    
        input[type='checkbox'] {
          margin-right: 10px;
          width: 16px; // Adjust size if necessary
          height: 16px;
        }
  
        label {
          margin: 0; /* No extra left margin for the label */
        }
      }
    }
  }
}
.inventory-form {
 
  background-color: white;

  .section-title {
   
    font-weight: normal;
    color: #333;
    background-color: #e3f2fd;
    padding: 10px;
    border-radius: 4px;
  }

  .form-grid {
    padding:20px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* First row with 3 inputs */
    grid-gap: 20px;
    margin-top: 20px;
    
    /* Ensure that the dropdown and checkbox are placed in the next row */
    .full-width {
      grid-column: span 3; /* Make dropdown and checkbox span full width */
    }
  }

  .form-item {
    display: flex;
    flex-direction: column;
  
    label {
      font-weight: 500;
      margin-bottom: 5px;
    }
  
    .dropdown-checkbox-row {
      display: flex;
      align-items: flex-start; /* Align items from the top */
     justify-content: space-between;
  
      /* Dropdown styling */
      .dropdown-button {
       
        margin-right: 20px; /* Adds space between dropdown and checkbox */
      }
  
      /* Styling for checkbox and label alignment */
      .checkbox-column {
        display: flex;
        align-items: flex-start; /* Aligns checkbox and text to the top */
        
        .checkbox-label-column {
          display: flex;
          flex-direction: column; /* Align label and small text vertically */
          margin-left: 10px; /* Adds space between checkbox and label */
          
          label.checkbox-label {
          
            font-weight: 500;
          }
  
          small {
          
            color: #888;
            margin-top: 3px; /* Adds space between label and small text */
          }
        }
      }
    }
  }
  /* Container for the entire form and buttons */
.button-container {
  display: flex;
  justify-content: flex-end; /* Aligns the buttons to the right */
  align-items: center;
  background-color: #FDECEC; /* Light pink background color */
  padding: 20px;
  border-radius: 5px; /* Adds the rounded corners */
}

/* Full-width input form */
.form-item {
  width: 100%;
  margin-right: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-section {
  display: flex;
  justify-content: flex-end !important; // Aligns the buttons to the right
  margin-top: 50px;


.cancel-button {
  background-color: var(--dark-blue);
  margin-right: 10px; // Add space between Cancel and Save buttons
}
.save-button{
  background-color: var(--grey);
}
}
}   
}
  // Column layout for small screens
  @media (max-width: 575.98px) {
    .form-row {
      flex-direction: column;
    }
    .form-column {
      width: 100%; /* Full width on mobile */
    }
    .checkbox-group,
    .radio-group {
      align-items: left;
      text-align: left;
      flex-direction: column;
      gap: 5px; /* Less gap on mobile for compact display */
    }
  }

  // For min-width: 576px, use column layout
  @media (min-width: 576px) {
    .form-row {
      flex-direction: column;
    }
    .form-column {
      width: 100%; /* Full width for this breakpoint */
    }
  }

  // For larger breakpoints (768px and up)
  // Row layout unless the content reaches 100%, then it switches to column
  @media (min-width: 768px) {
    .form-row {
      flex-direction: row; /* Switch to row layout */
      flex-wrap: wrap;
    }
    .form-column {
      width: 48%; /* Adjust width for this breakpoint */
    }
  }

  @media (min-width: 992px) {
    .form-row {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .form-column {
      width: 32%; /* Adjust to 32% for medium devices */
    }
  }

  @media (min-width: 1200px) {
    .form-row {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .form-column {
      width: 33%; /* Adjust to 33% for large devices */
    }
  }

  @media (min-width: 1400px) {
    .form-row {
      flex-direction: row;
      flex-wrap: wrap;
    }
    .form-column {
      width: 33.33%; /* Adjust to 33.33% for extra large devices */
    }
  }
}
}
.purchase-order-list-page {
  padding: 20px;
  background-color: whitesmoke;

  .purchase-order-list-container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;

    .filter-section {
      display: flex;
      flex-direction: column;

      .row {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;
        padding: 0px;

        .filter-item {
          padding: 0px;
          flex: 1;
          display: flex;
          flex-direction: column;

          label {
            font-size: 12px;
            font-weight: 500;
            margin-bottom: 8px;
          }
          .date-picker {
            width: 100%;
            margin: 0px;
            padding: 8px;
            border-radius: 5px;
            border: 1px solid #cdcdcd;
        }

          input,
          select {
            font-size: 12px;
            padding: 8px;
            border: 1px solid #ced4da;
            border-radius: 4px;
          }
        }

        .search-show-all-buttons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-top: 28px;

          .search-button {
            width: 100%;
            background-color: var(--dark-blue);
            color: white;
            border: none;
            margin-right: 10px;
          }

          .show-all-button {
            width: 100%;
            background-color: #f0f0f0;
            color: #333;
          }
        }
      }
    }

    .summary-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      background-color: #f8f9fa;
      border-radius: 8px;
      margin-top: 20px;

      .selected-dates {
        display: flex;
        gap: 20px;

        .selected-date {
          font-weight: 500;
        }
      }

      .clear-all-button {
        background-color: #ffffff;
        color: #000;
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
    }

    .purchase-order-table-section {
      margin-top: 20px;
      overflow-x: auto;
      scroll-behavior: smooth; /* Smooth horizontal scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      
      /* Hide scrollbar for Chrome, Safari and Edge */
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .purchase-order-table {
      width: 100%;
      border-collapse: collapse;
      text-align: left;

      th,
      td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        max-width: 200px; /* Max width for ellipses */
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      th {
        background-color: #e0e0e0;
        font-weight: 600;
        font-size: 14px;
      }

      td {
        font-size: 14px;
        vertical-align: top;

        .order-subtype {
          font-style: italic;
          font-weight: normal;
          color: #888;
        }

        .status.printed {
          display: inline-block;
          padding: 4px 8px;
          background-color: #dff7df;
          color: #28a745;
          border-radius: 12px;
          font-weight: 500;
          font-size: 12px;
        }

        .actions-column {
          display: flex;
          gap: 30px !important;
          cursor: pointer;

          .action-icon {
            cursor: pointer;
            font-size: 18px;
            color: #6c757d;

            &:hover {
              color: #000;
            }
          }
        }
      }
    }

    .pagination-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #daebff;
      border-radius: 8px;
      margin-top: 20px;
      flex-wrap: nowrap; /* Prevent wrapping in larger screens */
      gap: 15px;

      .pagination-buttons {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }

      .pagination-icons-section {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap; /* Allow wrapping in smaller screens */
        flex-direction: row;

        .pagination-icon {
          margin-right: 5px;
          font-size: 18px;
          color: #6c757d;
        }

        .pagination-text {
          font-size: 12px;
          font-weight: 500;
          color: #333;
        }
      }

      .pagination {
        display: flex;
        gap: 10px;
        flex-wrap: wrap; /* Wrap pagination items on smaller screens */

        .page-item {
          .page-link {
            padding: 8px 12px;
            border: 1px solid #ddd;
            border-radius: 4px;
            color: #333;
          }

          &.active .page-link {
            background-color: var(--dark-blue);
            color: white;
            border: none;
          }
        }
      }
    }
  }

  /* Responsive Design Starts Here */

  @media (max-width: 575.98px) {
    .purchase-order-list-container {
      .filter-section {
        .row {
          flex-direction: column;
        }
      }

      .summary-section {
        flex-direction: column;
        gap: 10px;
      }

      .pagination-section {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;

        .pagination-buttons {
          width: 100%;
          margin-bottom: 10px;
        }

        .pagination-icons-section {
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;

          flex-wrap: wrap;
        }
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767.98px) {
    .purchase-order-list-container {
      .filter-section {
        .row {
          flex-wrap: wrap;
          justify-content: space-between;

          .filter-item {
            flex: 0 0 calc(50% - 10px);
          }
        }
      }

      .pagination-section {
        flex-direction: column;
        align-items: center;

        .pagination-buttons {
          width: 100%;
          margin-bottom: 10px;
        }

        .pagination-icons-section {
          flex-direction: row;
          justify-content: space-around;
          flex-wrap: wrap;
        }
      }
    }
  }

  @media (min-width: 768px)  {
    .purchase-order-list-container {
      .filter-section {
        .row {
          .filter-item {
            flex: 0 0 calc(33.333% - 10px);
          }
        }
      }

      .pagination-section {
        flex-direction: column;
        align-items: center;

        .pagination-buttons {
          width: 100%;
          margin-bottom: 10px;
        }

        .pagination-icons-section {
          flex-direction: row;
          justify-content: space-between;
          flex-wrap: wrap;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 992px) {
    .purchase-order-list-container {
      .filter-section {
        .row {
          .filter-item {
            flex: 0 0 calc(25% - 10px);
          }
        }
      }

      .pagination-section {
        flex-direction: column;
        align-items: center;

        .pagination-buttons {
          margin-bottom: 10px;
        }

        .pagination-icons-section {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .purchase-order-list-container {
      .filter-section {
        .row {
          .filter-item {
            flex: 0 0 calc(20% - 10px);
          }
        }
      }

      .pagination-section {
        flex-direction: row;
        justify-content: space-between;

        .pagination-buttons {
          margin-bottom: 0;
        }

        .pagination-icons-section {
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }
  }

  @media (min-width: 1400px) {
    .purchase-order-list-container {
      .filter-section {
        .row {
          .filter-item {
            flex: 0 0 calc(16.666% - 10px);
          }
        }
      }
    }
  }
}

.sidebar-container {
  position: fixed;
  
  left: 0;
  width: 55px;
  height: 100vh;
  background-color: var(--white);
  transition: width 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;

  &:hover {
    width: 200px;
  }

  .sidebar-list {
    list-style: none;
    padding: 0;
    margin-top: 70px;
    

    .sidebar-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 15px;
      cursor: pointer;
      transition: background 0.3s ease, color 0.3s ease;
      color: var(--grey);
      margin-bottom: 10px;

      &:hover {
        background-color: var(--show-all);
      }

      .sidebar-icon {
        font-size: 17px;
        color: var(--grey);
        min-width: 20px;
        text-align: center;
      }

      .sidebar-text {
        opacity: 0;
        visibility: hidden;
        white-space: nowrap;
        max-width: 0;
        overflow: hidden;
        transition: opacity 0.3s ease, max-width 0.3s ease, visibility 0.3s ease;
        color: var(--grey);
      }

      &:hover .sidebar-text {
        opacity: 1;
        visibility: visible;
        max-width: 150px;
        margin-left: 10px;
        color: var(--black);
      }

      .sidebar-text {
        &.close-sidebar {
          cursor: pointer;
        }

        &.close-sidebar:active {
          width: 55px;
        }
      }
    }

    .active {
      background-color: var(--secondary-nav-color);
      color: var(--white);
    }
  }

  &.open .sidebar-item .sidebar-text {
    opacity: 1;
    visibility: visible;
    max-width: 150px;
    margin-left: 10px;
    color: var(--black);
  }

  @media (max-width: 575.98px) {
    .sidebar-container {
      top: 50px;
    }
   
    &:hover {
      width: 200px;
    }

    .sidebar-text.close-sidebar {
      cursor: pointer;
    }

    .sidebar-text.close-sidebar:active {
      width: 55px;
    }
  }



  @media (min-width: 576px) and (max-width: 767.98px) {
   
    &:hover {
      width: 200px;
     
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    &:hover {
      width: 200px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    &:hover {
      width: 200px;
    }
  }

  @media (min-width: 1200px) {
    &:hover {
      width: 200px;
    }
  }
}

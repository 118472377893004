.bulk-products {
    padding: 20px;
    background-color: var(--whitesmoke);
    .breadcrumb-container {
        display: flex;
        align-items: center;
        padding: 10px ;
        font-size: 16px;
        color: var(--grey);
       
      background-color: var(--white);
    
      .breadcrumb-icon {
        color: var(--grey);
        margin-right: 8px;
      }
    
      .breadcrumb-text {
        margin: 0 5px;
        text-decoration: none; // Remove underline
        color: inherit;
    &:hover {
      text-decoration: none; // Ensure no underline on hover
     
    }
      }
    
      .breadcrumb-separator {
        margin: 0 5px;
        color: var(--fade-grey);
      }
      }
  
      .bulk-brand-container{
  
        padding:5px;
        border-radius: 10px;
    
        background-color: var(--whitesmoke);
      
  }
  .bulk-brand {
    padding: 20px ;
    background-color: var(--white);
    border-radius: 10px;
  
    .brand-table {
       
        background-color: var(--white);
        border-radius: 10px;
      
        .header-row {
          display: flex;
          justify-content: space-between; /* Push items to opposite corners */
       
         
        
      
        .text-heading1 {
            padding: 5px !important;
           
            text-align: left !important;
            align-self: flex-start; /* If parent is flexbox */
        }
      
  
    .add-new-button {
        display: flex;
    
        gap: 15px;
  
        .add-button{
          background-color: var(--dark-blue); /* Use brand-specific dark-blue color */
          color: var(--white);   
          border: none;
          border-radius: 4px;
          padding: 3px 10px;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s ease;
        }
        .save-button {
            background-color: var(--dark-blue); /* Use brand-specific dark-blue color */
            color: var(--white);   
            border: none;
            border-radius: 4px;
            padding: 3px 10px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
  
          
        }
    }
        }
  
  }
  .table-container {
    max-height: 400px; /* Set the height of the table container */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    position: relative;
    margin-bottom: 20px; /* Add space below the table container */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #999 transparent;
    height:100vh;
  
    &::-webkit-scrollbar {
      height: 8px; /* Set the height for horizontal scrollbar */
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--whitesmoke); /* Color of the scrollbar */
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent; /* Transparent background */
    }
  
    .custom-table {
      width: 100%; /* Ensure the table fills the container */
      border-collapse: collapse;
      margin-top: 20px;
 
  
      thead {
        position: sticky;
        top: 0; /* Fix the header at the top */
        z-index: 2;
        background-color: var(--whitesmoke); /* Header background color */
      }
  
      th,
      td {
        padding: 8px;
        text-align: center;
        border-bottom: 1px solid var(--border-bottom);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
        box-sizing: border-box; /* Include padding and border */
        min-width: 120px; /* Prevent columns from shrinking too much */
      }
  
      th {
        font-weight: 600;
        background-color: var(--th);
      }
  
      td {
        background-color: var(--white);
        height: 50px; /* Uniform row height */
      }
  
      .small-column {
        width: 80px;
      }
  
      td input {
        padding: 8px;
        width: auto;
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        outline: none;
        font-family: 'Poppins', sans-serif;
        background: transparent;
      }
  
      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 5px;
      }
    }
  
    .icon-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--grey);
  
      &:hover {
        color: var(--black);
      }
    }
  }
  
  
    .button-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        gap: 15px;
  
        .next-button {
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            padding: 10px 20px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
  
            &:hover {
                background-color: var(--hover-blue);
            }
        }
    }
  }
  }
      
@media (max-width: 575.98px) {
  .breadcrumb-container{
    display:none;
}
  .bulk-brand {
    .brand-table {
      .header-row {
        display: flex;
        flex-direction: column; /* Stack heading and buttons vertically */
        align-items: flex-start; /* Align heading and buttons to the left */
    
        .text-heading1 {
          margin-bottom: 10px; /* Add spacing between heading and buttons */
          text-align: left;
        }
    
        .add-new-button {
          display: flex; /* Place buttons in a row */
          flex-direction: row; /* Ensure buttons are in a single row */
          gap: 10px; /* Spacing between buttons */
    
          .add-button,
          .save-button {
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            padding: 7px 15px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
    
            &:disabled {
              background-color: var(--grey);
              cursor: not-allowed;
            }
          }
        }
      }
    
    
  }
  }
  .table-container {
    overflow-x: scroll;
    white-space: nowrap; /* Prevent shrinking */
    height: auto; /* Allow the container to adjust height dynamically */

    .custom-table {
      width: auto; /* Prevent shrinking of the table */
      min-width: 600px; /* Ensure a minimum width for scrolling */
    }
  }

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 600px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 800px; /* Slightly larger minimum width for medium devices */
    }
  }
}
/* Media Queries for Different Screen Sizes */
@media (max-width: 1024px) {
  .bulk-brand .table-container {
    max-height: calc(100vh - 200px); /* Adjust height for medium screens */
  }
}

.slick-carousel-container {
  width: 100%;
  height: 400px;
  overflow: hidden;

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  /* Responsive Design */
  @media (max-width: 575.98px) {
    height: 200px;

    img {
      height: 200px;
    }
  }

  @media (min-width: 576px) {
    height: 300px;

    img {
      height: 300px;
    }
  }

  @media (min-width: 768px) {
    height: 300px;

    img {
      height: 300px;
    }
  }

  @media (min-width: 992px) {
    height: 400px;

    img {
      height: 400px;
    }
  }

  @media (min-width: 1200px) {
    height: 400px;

    img {
      width: 100%;
      object-fit: cover;
      height: 400px;
    }
  }

  @media (min-width: 1400px) {
    height: 500px;

    img {
      width: 100%;
      height: 500px;
      object-fit: cover;
    }
  }
}

/* Loader Container */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* Moving Skeleton Banner */
.skeleton-banner {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #f0f0f0 25%,
    #e0e0e0 50%,
    #f0f0f0 75%
  );
  background-size: 200% 100%;
  animation: shimmerMove 1.5s infinite ease-in-out;
  border-radius: 8px; /* For rounded edges */
}

/* Shimmer Movement Animation */
@keyframes shimmerMove {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}

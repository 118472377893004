.offcanvas-container {
  border-radius: 0 !important; /* Remove border radius for the entire offcanvas */
}
.address-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  flex-grow: 1; /* Ensures the form grows to fill the available space */
  overflow-y: auto; /* Allows the form to scroll */
  max-height: calc(100vh - 60px); /* Adjust for the header and footer */
border-radius: 2px;
  .address-group {
    margin-bottom: 20px;
  }

  .address-label {
    margin-bottom: 5px;
    color: var(--grey);
    font-weight: bold;
  }

  .address-city-state {
    display: flex;
    justify-content: space-between;

    .address-group {
      flex: 1;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .address-save-options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    border-radius: 5px;

    .address-save-btn {
      flex: 1;
      margin-right: 10px;
      padding: 5px 15px;
      background-color: #f8f9fa;
      border: 1px solid #ced4da;
      cursor: pointer;
      text-align: center;
      border-radius: 5px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        background-color: #e2e6ea;
      }
    }
  }
}

.address-label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: var(--grey);
  display: block; /* Ensure the label spans the full width above buttons */
}
.save-as-buttons {
  display: flex; /* Align buttons in a row */
  justify-content: space-between; /* Space buttons equally */
  gap: 10px; /* Spacing between buttons */

  .save-as-button {
    flex: 1; /* Equal width for all buttons */
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #0056b3; /* Default text color */
    background-color: #e7f1ff; /* Default light blue background */
    border: 2px solid transparent; /* Transparent border by default */
    border-radius: 8px; /* Rounded corners */
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover and active states */



    &.active {
      color: #ffffff; /* White text for active state */
      background-color: #0056b3; /* Blue background for active state */
      border: 2px solid #0056b3; /* Blue border for active state */
    }
  }
}


.address-save-continue {
  position: sticky; /* Keeps the section at the bottom inside the offcanvas */
  bottom: 0; /* Align to the bottom of the offcanvas */
  width: 100%; /* Span across the width of the offcanvas */
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 10; /* Ensure it stays above the content in the offcanvas */

  .address-save-continue-btn {
    width: 100%;
    text-align: center;
    background-color: var(--dark-blue);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}


.address-title {
  color: var(--grey);
  font-weight: bold;
}

/* Media Queries for specific device sizes */

/* Small screens (max-width: 575.98px) */
@media (max-width: 575.98px) {
  .offcanvas {
    width: 100% !important; /* Full width for extra-small screens */
    height: 100% !important; /* Full height */
    bottom: 0 !important; /* Position at the bottom */
    left: 0 !important; /* Align to left */
    right: 0 !important; /* Align to right */
    top: auto !important; /* Remove top alignment */
  }
}

/* Medium screens (min-width: 576px) */
@media (min-width: 576px) {
  .offcanvas {
    width: 100% !important; /* Full width for small screens */
    height: 100% !important; /* Full height */
    bottom: 0 !important; /* Position at the bottom */
    left: 0 !important; /* Align to left */
    right: 0 !important; /* Align to right */
    top: auto !important; /* Remove top alignment */
  }
}

/* Medium screens (min-width: 768px) */
@media (min-width: 768px) {
  .offcanvas {
    width: 100% !important; /* Full width for medium screens */
    height: 100% !important; /* Full height */
    bottom: 0 !important; /* Position at the bottom */
    left: 0 !important; /* Align to left */
    right: 0 !important; /* Align to right */
    top: auto !important; /* Remove top alignment */
  }
}

/* Right Offcanvas for large devices */
@media (min-width: 992px) {
  .offcanvas {
    width: 500px !important; /* Adjust width for large screens */
    right: 0 !important; /* Position offcanvas to the right */
    left: auto !important; /* Remove left alignment */
    height: 100% !important; /* Full height */
  }
}
@media (min-width: 1200px) {
  .offcanvas {
    width: 500px !important; /* Adjust width for large screens */
    right: 0 !important; /* Position offcanvas to the right */
    left: auto !important; /* Remove left alignment */
    height: 100% !important; /* Full height */
  }
}
@media (min-width: 1400px) {
  .offcanvas {
    width: 500px !important; /* Adjust width for large screens */
    right: 0 !important; /* Position offcanvas to the right */
    left: auto !important; /* Remove left alignment */
    height: 100% !important; /* Full height */
  }
}

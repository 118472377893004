.locationDropdownContainer {
  position: relative; /* Make container relative to position modal */
  cursor:pointer;


.locationDropdown {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  .locationIcon {
    margin-right: 5px;
  }

  .locationInput {
    border: none;
    background: none;
    outline: none;
    
    width: 150px;
  }

  .locationDropdownIcon {
    margin-left: 5px;
  }
}

.locationModal {
  max-height: 50vh; /* Set a maximum height for the modal */
  overflow-y: auto; 
  position: absolute; /* Position the modal absolutely relative to the container */
  top: 100%; /* Position it right below the dropdown */
  left: 0;
  width: 350px;
  background-color: white;
  border-radius: 8px;
 
  z-index: 1000;
  margin-top: 5px; /* Small gap between dropdown and modal */

  /* Add box shadow */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);

  .modalContent {
   padding: 20px;
  height: 100%;
  overflow: auto; /* Allow scrolling if content overflows */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 15px;

    .modalHeading {
      margin: 0;
     color:var(--grey);
      font-weight: 400px;
    }

    .locationInputWrapper {
      display: flex;
      gap: 0px;

      .modalInput {
        flex: 1;
        border: 1px solid var(--dark-blue);
        border-radius: 4px 0 0 4px;
        font-size: 14px;
      }

      .checkButton {
        padding: 8px 16px;
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        margin-top:0px;
      }
    }

    .currentLocation {
      display: flex;
      align-items: center;
      gap: 5px;
     
      color: var(--dark-blue);
      cursor: pointer;

      .currentLocationIcon {
        color: #2060be;
      }
    }
     .line{
      margin:0px;
      color:var(--light-grey);
     }
    .savedAddresses {
     
      color:var(--grey);
      font-weight: 400px;
    }

    .savedAddress {
      .addressItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;

        .address-office{
          font-weight: 400px;
          color:var(--grey);
        }

        .selectLabel {
          display: flex;
          align-items: center;
          gap: 5px;
    
          .select-address{
            color:var(--grey);
            font-weight: 400px;
          }
          input[type="radio"] ,address{
            position: relative; /* Allows the use of top */
            top: -4px; /* Move the radio button up by 5px */
            margin-left: 5px;
            color: var(--dark-blue);
          }
        }
      }

      .address-text {
        margin-top: 5px;
        color: var(--grey);
       
        overflow: hidden; /* Hides the overflowing content */
        text-overflow: ellipsis; /* Displays ellipsis (...) when the content overflows */
       
      }
    }
  }
}
@media (max-width: 768px) {
  .locationModal {
    left: 0; /* Ensure it aligns to the left */
    right: 0; /* Expand to full width */
    width: 100%; /* Occupy full screen width */
    border-radius: 0; /* Remove rounded corners for a cleaner mobile view */
    max-height: 60vh; /* Adjust height for better usability on smaller screens */
  }

  .modalContent {
    padding: 15px; /* Reduce padding for smaller screens */
  }
}


}
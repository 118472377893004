
.breadcrumb-container {
  display: flex;
  align-items: center;
  padding: 10px ;
  font-size: 16px;
  color: #555;
 
background-color: var(--white);

.breadcrumb-icon {
  color: #333;
  margin-right: 8px;
}

.breadcrumb-text {
  margin: 0 5px;
  text-decoration: none; // Remove underline
  color: inherit;
&:hover {
text-decoration: none; // Ensure no underline on hover

}
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #aaa;
}
}
.brand-products-container {
  display: flex;
  gap: 20px;
  padding: 20px;

  /* Left Section (Sidebar) */
  .left-section {
    flex: 0 0 200px;
    display: flex;

    .sidebar {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .sidebar-category {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        padding: 10px;
        border-radius: 10px;
        transition: background-color 0.3s ease;

        img {
          width: 50px;
          height: 50px;
          object-fit: cover;
          border-radius: 50%;
        }

        &:hover {
          background-color: #f0f0f0;
        }

        &.active-category {
          background-color: #e6f7f7;
          border: 1px solid #00aaff;
        }
      }
    }
  }

  /* Right Section (Products) */
  .right-section {
    flex: 1;
    flex-direction: column;
    padding: 10px;
   
    .products-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .view-all-link {
        color: var(--dark-blue);
        text-decoration: none;
      }
    }

    .shop-cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .shop-card {
        flex: 1 1 calc(33.33% - 20px); /* Default: 3 cards per row */
        max-width: calc(33.33% - 20px);
      }
    }
  }

  /* Media Queries */

  /* From 1400px and above */
  @media (min-width: 1400px) {
    flex-direction: row !important;

    .shop-card {
      flex: 1 1 calc(33.33% - 20px); /* 3 cards per row */
    }
  }

  /* From 1200px to 1399px */
  @media (min-width: 1200px) {
    flex-direction: row !important;

    .shop-card {
      flex: 1 1 calc(33.33% - 20px); /* 3 cards per row */
    }
  }

  /* From 992px to 1199px */
  @media (min-width: 992px) {
    flex-direction: row !important;

    .shop-card {
      flex: 1 1 calc(33.33% - 20px); /* 3 cards per row */
    }
  }

  /* From 768px to 991px */
  @media (min-width: 768px) {
    flex-direction: row !important;

    .shop-card {
      flex: 1 1 calc(50% - 20px); /* 2 cards per row */
    }
  }

  
  /* Only max-width: 575.98px */
  @media (min-width: 576px) {
    flex-direction: column ;

    .shop-cards-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .shop-card {
      flex: 1 1 calc(100% - 10px); /* One card per row */
      max-width: calc(100% - 10px); /* Ensure each card takes up full width minus the gap */
    }
  }


  /* Only max-width: 575.98px */
@media (max-width: 575.98px) {
  flex-direction: column;

  .shop-cards-wrapper {
    display: flex !important;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between the cards */
  }

  .shop-card {
    flex: 1 1 calc(50% - 10px) !important; /* Two cards per row */
    max-width: calc(50% - 10px) !important; /* Ensure each card takes up 50% of the width minus the gap */
  }
}

}

.product-list-section {
  margin: 20px auto; /* Center the section horizontally */
  padding: 10px;
  max-width: 80%; /* Restrict the width to 80% of the page */
  
  .product-list-header {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    color: #333;
  }

  .product-list-container {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: 20px; /* Space between cards */
  }

  .card-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
    min-height: 140px; /* Ensures consistent height for all cards */
    width: 100%; /* Occupies full width inside container */

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    .image-container {
      width: 80px;
      height: 80px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 5px;
      }
    }

    .details-container {
      flex: 1;
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; /* Align title and price vertically */

      .product-title {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        margin-bottom: 5px;
        text-transform: capitalize;
      }

      .price-details {
        font-size: 14px;
        color: #888;

        .price {
          color: #333;
          font-weight: 600;
        }

        .mrp {
          text-decoration: line-through;
          margin-left: 5px;
          color: #aaa;
        }

        .discount {
          color: #28a745; /* Green for discounts */
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }

    .action-controls {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px; /* Space between buttons and quantity controls */

      .quantity-controls {
        display: flex;
        align-items: center;
        gap: 10px;

        button {
          border: none;
          background-color: #007bff;
          color: #fff;
          font-size: 14px;
          width: 40px; /* Ensure buttons are consistent in size */
          height: 40px; /* Consistent height */
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background-color: #0056b3;
          }

          &:disabled {
            background-color: #ddd;
            cursor: not-allowed;
          }
        }

        .quantity {
          width: 40px; /* Consistent input field size */
          text-align: center;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .add-to-cart-btn {
        width: 140px;
        height: 40px;
        background-color: #007bff;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        line-height: 40px; /* Vertically center the text */

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .loading-message {
    font-size: 16px;
    color: #888;
    text-align: center;
    padding: 20px;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product-list-section {
    max-width: 90%; /* Occupy 90% of the screen for smaller devices */
  }

  .card-item {
    flex-direction: column;
    align-items: flex-start;

    .image-container {
      width: 100%;
      height: auto;
    }

    .details-container {
      margin-left: 0;
      margin-top: 10px;
    }

    .action-controls {
      width: 100%; /* Full width on mobile */
      .add-to-cart-btn {
        width: 100%; /* Full width on mobile */
      }
    }
  }
}

.add-stock {
  padding: 20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
      display: flex;
      align-items: center;
      padding: 10px ;
      font-size: 16px;
      color: var(--grey);
     
    background-color: var(--white);
  
    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }
  
    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none; // Remove underline
      color: inherit;
  &:hover {
    text-decoration: none; // Ensure no underline on hover
   
  }
    }
  
    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--fade-grey);
    }
    }
.stock-purchase-container{

    padding:5px;
    border-radius: 10px;

    background-color: var(--whitesmoke);
  
}
.add-stock-page-container {
    
  padding: 20px;
  background-color: var(--white);
  border-radius: 8px;


  .add-stock-page {
    
    padding: 10px;
    background-color: var(--white);
    border-radius: 8px;
    border: 1px solid var(--border) !important;
  }

  .add-stock-header-class {
    border: 1px solid var(--border) !important;
    padding: 10px;
    border-radius: 10px;

    .add-stock-header {
      font-weight: bold;
     
      margin-bottom: 20px;
    }
  }

  .add-stock-form {
    .form-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      gap: 15px;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      width: 48%;
    }

    .po-number-group {
      display: flex;
      flex-direction: column;

      .po-number-editable {
        display: flex;
        align-items: center;

        .po-input {
          border: none;
          border-bottom: 1px solid var(--border-bottom);
          width: 100%;
          padding-right: 20px;
          padding: 5px;
        }

      }
    }
    .Payment-container {
      display: flex;
      align-items: center; /* Vertically align items in the row */
      gap: 10px; /* Add spacing between items */
      flex-wrap: wrap; /* Allow wrapping for smaller screens if needed */
    
      label {
        font-size: 16px; /* Adjust the font size */
        margin-right: 10px; /* Add spacing after the label */
        color: var(--grey); /* Adjust label color */
      }
    
      input[type='radio'] {
        margin-right: 5px; /* Add spacing between the radio button and its label */
      }
    }
    
    .form-control {
      padding: 6px;
      font-size: 16px;
      border: 1px solid var(--border);
      border-radius: 4px;
      
    }
  }

  .invoice-details-container {
    background-color: var(--white);
    padding: 20px;
    border-radius: 8px;
    border: 1px solid var(--border);
    margin-top: 20px;

    .form-row {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      margin-bottom: 15px;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      width: 48%;

      

      
    }

    .input-component {
      border: none;
      border-bottom: 1px solid var(--border-bottom);
      padding: 8px 0;
      font-size: 16px;
      width: 100%;

      &:focus {
        outline: none;
        border-bottom: 1px solid var(--dark-blue);
      }
    }
    

  

    .form-control-file {
      display: block;
     
    }
  }
  
}
  .material-details {
    background-color: var(--white);
    border: 1px solid var(--border);
    padding: 10px;
    margin-top: 10px;
    border-radius: 10px;     
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid var(--border-bottom);
      .add-request-header1 {
        font-weight: bold;
        margin: 0;
      
      }
      
        .add-new-button {
          display: flex;
          align-items: center;
          background-color: var(--dark-blue);
          color: var(--white);
          border-radius: 4px;
          padding:4px;
         
        }
      
       
      
    }
  
    .table-bordered {
      width: 100%;
      margin-top: 20px;
      border-collapse: collapse;
      padding: 5px;
    
      th,
      td {
        padding: 5px;
        border-bottom: 1px solid var(--border-bottom);
      }
    
      th {
        background-color: var(--th);
        text-align: left;
      }
    
      .table-input {
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        width: 100%;
      }
    
      .empty {
        background-color: #f1f1f1;
      }
  }          

    .button-container {
      margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 15px;
           
          

           
    .save-button {
      background-color: var(--dark-blue);
      color: var(--white);
      padding: 7px;
      border-radius: 4px;
    }
  }
  }
  // Media queries for responsiveness using min-widths
  @media (max-width: 1400px) {
    .form-row {
      flex-direction: row !important;
    }
    .form-group {
      width: 48%;
    }
  }

  @media (max-width: 1200px) {
    .form-row {
      flex-direction: row !important;
    }
    .form-group {
      width: 48%;
    }
  }

  @media (max-width: 992px) {
    .form-row {
      flex-direction: row !important;
    }
    .form-group {
      width: 48%;
    }
  }

  @media (max-width: 768px) {
    .form-row {
      flex-direction: column !important;
    }
    .form-group {
      width: 100% !important;
    }
    .table-bordered {
      display: block;
width: 100%; /* Ensure the table container spans the full width */
overflow-x: auto; /* Allow horizontal scrolling */
white-space: nowrap; /* Prevent table contents from wrapping */

/* Ensure the table doesn't shrink for all screen sizes */
table {
table-layout: fixed; /* Prevent columns from resizing */
width: 100%; /* Maintain table size */
}
 }
  }

  @media (max-width: 576px) {
    .form-row {
      flex-direction: column !important;
    }
    .form-group {
      width: 100% !important;
    }
    .table-bordered {
      display: block;
width: 100%; /* Ensure the table container spans the full width */
overflow-x: auto; /* Allow horizontal scrolling */
white-space: nowrap; /* Prevent table contents from wrapping */

/* Ensure the table doesn't shrink for all screen sizes */
table {
table-layout: fixed; /* Prevent columns from resizing */
width: 100%; /* Maintain table size */
}
 }
  }

  // Apply column layout for screens 575.98px and below
  @media (max-width: 575.98px) {
    .header {
      flex-direction: row !important; /* Keep text and button in a row */
      justify-content: space-between; /* Ensure proper spacing between text and button */
      align-items: center; /* Center align content vertically */
      overflow: hidden; /* Prevent overflow */
    }
  
    .form-row {
      flex-direction: column !important;
    }
    .form-group {
      width: 100% !important;
    }
   
    .add-request-header1 {
      font-size: 14px; /* Adjust font size for smaller screens */
    }
  
    .add-new-button {
      padding: 6px 12px; /* Increase padding slightly for better appearance */
      font-size: 14px; /* Adjust font size */
    }
    
    .table-bordered {
      display: block;
width: 100%; /* Ensure the table container spans the full width */
overflow-x: auto; /* Allow horizontal scrolling */
white-space: nowrap; /* Prevent table contents from wrapping */

/* Ensure the table doesn't shrink for all screen sizes */
table {
table-layout: fixed; /* Prevent columns from resizing */
width: 100%; /* Maintain table size */
}
 }
    
  }
}
.file-display {
  display: flex;
  align-items: center;
  margin-top: 10px;

  .file-name {
      color: #007bff;
      cursor: pointer;
      text-decoration: underline;
      margin-right: 10px;

      &:hover {
          text-decoration: none;
      }
  }

  .remove-file {
      background-color: red;
      color: white;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
      line-height: 0;

      &:hover {
          background-color: darkred;
      }
  }
}
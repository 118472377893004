.medicine-details-container {
  display: flex;
  gap: 20px;
  padding: 20px;

  .medicine-details-left {
    flex: 1;
    text-align: center;
    border-radius: 8px;
    padding: 10px;

    .carousel {
      border: 1px solid #ddd;
      width: 100%;
      margin: auto;

      .carousel-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px; // Set fixed height for carousel
      }

      .medicine-image {
       
        max-width: 100%;
        object-fit: contain; // Ensures the image is contained within its box
      }

      // Ensure the carousel indicators are visible
      .carousel-indicators {
        bottom: -20px; // Adjust position if needed
        li {
          background-color: var(--dark-blue); // Active indicator color
          border-radius: 50%;
          width: 8px;
          height: 8px;
          margin: 0 4px;
        }
      }
    }

    .medicine-image-pagination {
      margin-top: 10px;

      .pagination-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        background-color: #ccc;
        border-radius: 50%;
        margin: 0 5px;

        &.active {
          background-color: var(--dark-blue);
        }
      }
    }
  }

  .medicine-details-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    position: relative;

    .fixed-content {
      background-color: var(--white); // Background color for fixed section
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd; // Divider line
      position: sticky; // Makes the content sticky
      top: 0;
      z-index: 10;
    }

    .scrollable-content {
      overflow-y: auto; // Makes the right container scrollable
     
      padding-right: 10px; // Add some padding to avoid scrollbar overlapping content
    }

    .medicine-title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .medicine-company,
    .medicine-size,
    .medicine-mrp,
    .medicine-price,
    .medicine-discount,
    .medicine-info {
      margin-bottom: 5px;
    }

    .medicine-mrp {
      text-decoration: line-through;
      color: var(--grey);
    }

    .medicine-price {
      font-weight: bold;
      color: var(--dark-blue);
    }

    .applied-discount {
      color: var(--green);
      background-color: #e0f7ea; // Light green background for discount text
      padding: 2px 6px;
      border-radius: 5px;
      display: inline-block;
    }

    .medicine-info {
      color: #666;
    }

    .medicine-features {
      padding: 20px;
      background-color: rgb(232, 246, 255);
      display: flex;
      justify-content: space-between;
      margin-top: 22px;
      border-radius: 10px;

      .feature {
        display: flex;
        align-items: center;

        i {
          margin-right: 5px;
          color:var(--dark-blue);
        }
      }
    }
  }

  .composition-section {
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin-bottom: 10px;

    .composition-title {
      font-weight: 600;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }

    i {
      margin-right: 5px;
      color: var(--green);
    }
  }

  .product-highlights {
    .product-highlights-title {
      font-weight: 600;
      margin-bottom: 5px;
    }

    .product-highlights-list {
      padding-left: 20px;
      list-style-type: disc;
      line-height: 1.6;
    }
  }
}

// Responsive styles for different screen sizes
@media (min-width: 768px), (min-width: 576px), (max-width: 575.98px) {
  .medicine-details-container {
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .medicine-details-container {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .medicine-details-container {
    flex-direction: row;
  }
}

@media (min-width: 1400px) {
  .medicine-details-container {
    flex-direction: row;
  }
}

.new-manual-stock-list-container {
   padding:20px;
   background-color: whitesmoke; 

.new-manual-stock-list {
    padding: 20px;
    background-color: #fff;

    .header-section {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-bottom: 20px;
        flex-wrap: wrap; /* Allow wrapping when needed */

        .add-button {
            background-color: var(--dark-blue);
            color: white;
            border: none;
            padding: 3px;
            border-radius: 4px;
            cursor: pointer;
        }

        .import-dropdown, .export-dropdown {
            color: black;
            padding: 3px;
        }
    }

    .hr-line {
        margin: 20px 0;
        border: 1px solid #e0e0e0;
    }

    .filters-section {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 20px;
        flex-wrap: wrap; /* Enable wrapping behavior */
        justify-content: flex-start;

        .filter-item {
            flex: 1;
            min-width: 250px; /* Set a reasonable min-width for responsiveness */
            display: flex;
            flex-direction: column;

            label {
                font-weight: 500;
                margin-bottom: 8px;
            }

            .date-picker {
                width: 100%;
                margin: 0px;
                padding: 7px;
                border-radius: 6px;
                border: 1px solid #cdcdcd;
            }

            .input-component,
            .dropdown-component,
            .datepicker-component {
                width: 100%;
            }
        }

        .filter-buttons {
            display: flex;
            gap: 10px;
            flex-wrap: wrap; /* Allow wrapping */

            .load-button,
            .show-all-button {
                padding: 5px;
                margin-top: 30px;
                background-color: var(--white);
                border: 1px solid var(--dark-blue);
                color: var(--dark-blue);
                white-space: nowrap; /* Prevent wrapping inside buttons */
            }

            .show-all-button {
                border-color: black;
                color: black;
            }
        }
    }

    .table-section {
        margin-top: 20px;
        overflow-x: auto; /* Enable horizontal scroll if needed */

        table {
            width: 100%;
            min-width: 800px; /* Ensure table has a minimum width */
            border-collapse: collapse;
            text-align: left;

            th, td {
                padding: 10px;
                border-bottom: 1px solid #ddd;
            }

            th {
                background-color: #ececec;
                font-weight: 600;
            }

            td {
                font-size: 14px;
            }

            .input-component {
                width: 100%;
            }

            .favorite-icon {
                cursor: pointer;
                color: #ccc; /* Default star color */
                transition: color 0.3s ease;
            }

            .favorite-icon.favorite {
                color: orange; /* Star color when marked as favorite */
            }
        }
    }

    .save-button-class {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        background-color: #effbff;

        .save-button {
            background-color: var(--dark-blue);
            border: none;
        }
    }

    /* Media Queries */

    /* From 1400px and above - Row Layout with wrapping */
    @media (min-width: 1400px) {
        .filters-section {
            flex-direction: row !important;
            flex-wrap: wrap; /* Allow wrapping when max-width is reached */
        }
    }

    /* From 1200px and above - Row Layout with wrapping */
    @media (min-width: 1200px) {
        .filters-section {
            flex-direction: row !important;
            flex-wrap: wrap; /* Allow wrapping when max-width is reached */
        }
    }

    /* From 992px and above - Row Layout with wrapping */
    @media (min-width: 992px) {
        .filters-section {
            flex-direction: row !important;
            flex-wrap: wrap; /* Allow wrapping when max-width is reached */
        }
    }

    /* From 768px and above - Row Layout with wrapping */
    @media (min-width: 768px) {
        .filters-section {
            flex-direction: row !important;
            flex-wrap: wrap; /* Allow wrapping when max-width is reached */
        }
    }

    /* From 576px to 767px - Switch to Column Layout */
    @media (min-width: 576px) {
        .filters-section {
           
            align-items: flex-start;
            flex-wrap: wrap;
        }
        
        .header-section {
            flex-direction: row;
           
            flex-wrap: wrap;
        }
    }

    /* Below 576px (Mobile View) - Column Layout */
    @media (max-width: 575.98px) {
        .header-section {
            width: 100%;
            display: flex;
            flex-direction: column !important; /* Move header down when max-width is reached */
        }
        
        .filters-section {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }
}
}
.button-component {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: var(--dark-blue); // Replace $color with your desired base color
    }
  
    &:disabled {
      cursor: not-allowed;
      background-color: #ccc;
    }
  }
  
.refundpage-section {
    padding: 20px;
    background-color: whitesmoke;
  
    .refundpage-container {
      width: 100%;
      background: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
     .return-container{
     padding:10px;
        border:1px solid #ccc
      }
      .refund-container{
        margin-top: 20px;
        padding:10px;
        border:1px solid #ccc
      }
  
      .refundpage-subheading {
      
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 600;
      }
  
      .form-row {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin-bottom: 15px;
        flex-wrap: nowrap;
      }
  
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        label {
          margin-bottom: 8px;
        }
  
        input {
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 4px;
        }
      }
  
      .save-button-container {
        display: flex;
        justify-content: flex-end;
      }
  
      .save-button {
        margin-top: 10px;
        background-color: var(--dark-blue);
        color: white;
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
      }
    }
  }
  
  @media (min-width: 1400px),
  (min-width: 1200px),
  (min-width: 992px),
  (min-width: 768px),
  (min-width: 576px) {
    .refundpage-container {
      .form-row {
        display: flex;
        flex-direction: row; /* Keep the fields in a row */
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  }
  
  @media (max-width: 575.98px) {
    .refundpage-container {
      .form-row {
        display: block;
        flex-direction: column; /* Stack the fields in a column */
      }
  
      .form-group {
        margin-bottom: 15px;
      }
  
      .save-button-container {
        justify-content: center; /* Center the save button on small screens */
      }
    }
  }
  
.remove-item-popup-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Apply a blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .remove-item-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    .popup-content {
      text-align: center;

      .popup-text {
        text-align: left;
        font-weight: 500;
      }

      .popup-actions {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .btn {
          color: var(--dark-blue);
          margin: 0 10px;
          text-decoration: none;
          text-align: center;
          cursor: pointer; /* Ensure it's clear these are clickable */
          padding: 8px 16px;
          border: 1px solid var(--dark-blue);
          border-radius: 4px;
          background-color: transparent;

          &:hover {
            background-color: var(--dark-blue);
            color: white;
          }
        }
      }
    }
  }
}

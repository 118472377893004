.bulk-coupon-main-page {
  padding: 20px;
  background-color: var(--whitesmoke);

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px ;
    font-size: 16px;
    color: var(--grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }

  .bulk-coupon-container {
    padding: 5px;
    border-radius: 10px;
    background-color: var(--whitesmoke);
  }

  .bulk-coupon {
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;

    .coupon-table {
      background-color: var(--white);
      border-radius: 10px;

      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      

      .text-heading1 {
        padding: 10px !important;
        text-align: left !important;
        align-self: flex-start;
      }

      .add-new-button {
        display: flex;
      
        gap: 15px;
        .add-button {
          background-color: var(--dark-blue);
          color: var(--white);
          border: none;
          border-radius: 4px;
          padding: 7px 15px;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s ease;

          &:disabled {
            background-color: var(--grey);
            cursor: not-allowed;
          }
        }

        .save-button {
          background-color: var(--dark-blue);
          color: var(--white);
          border: none;
          border-radius: 4px;
          padding: 7px 15px;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s ease;

          &:disabled {
            background-color: var(--grey);
            cursor: not-allowed;
          }
        }
      }
    }
    }

    .table-container {
      position: relative; /* For positioning */
      margin-top: 40px;
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: hidden; /* Disable vertical scrolling for the container */
      height: auto; /* Dynamic height adjustment */
      white-space: nowrap; /* Prevent text wrapping */
      -ms-overflow-style: none; /* IE and Edge compatibility */
      scrollbar-width: thin; /* Firefox */
      height:50vh;
    
      /* Custom scrollbar for WebKit browsers */
      &::-webkit-scrollbar {
        height: 8px; /* Horizontal scrollbar height */
      }
    
      &::-webkit-scrollbar-track {
        background: transparent;/* Scrollbar track background */
      }
    
      &::-webkit-scrollbar-thumb {
        background: var(--whitesmoke); /* Thumb color */
        border-radius: 4px;
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background: var(--very-light-grey); /* Thumb hover color */
      }
    
      .custom-table {
        min-width: max-content; /* Ensure table does not shrink */
        width: auto; /* Let the table width adjust dynamically */
        border-collapse: collapse;
        table-layout: auto; /* Allow dynamic column widths */
    
        thead {
          th {
            position: sticky; /* Sticky header */
            top: 0; /* Fix headers at the top of the container */
            z-index: 2;
            background-color: var(--th);
            padding: 12px;
            text-align: center;
            border-bottom: 2px solid var(--border-bottom);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            color: var(--grey);
          }
        }
    
        tbody {
          td {
            padding: 12px;
            text-align: center;
            border-bottom: 1px solid var(--border-bottom);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            color: var(--grey);
            height: 50px;
            box-sizing: border-box; /* Include padding and borders in element's width/height */
          }
    
          td input {
            padding: 10px;
            width: 100%; /* Stretch input to match cell width */
            border: none;
            border-bottom: 1px solid var(--border-bottom);
            outline: none;
            font-family: 'Poppins', sans-serif;
          }
        }
      }
    }
    
    .button-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      gap: 15px;

      .next-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 8px 16px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
   
    .bulk-coupon {
      .coupon-table {
        .header-row {
          display: flex;
          flex-direction: column; /* Stack heading and buttons vertically */
          align-items: flex-start; /* Align heading and buttons to the left */
      
          .text-heading1 {
            margin-bottom: 10px; /* Add spacing between heading and buttons */
            text-align: left;
          }
      
          .add-new-button {
            display: flex; /* Place buttons in a row */
            flex-direction: row; /* Ensure buttons are in a single row */
            gap: 10px; /* Spacing between buttons */
      
            .add-button,
            .save-button {
              background-color: var(--dark-blue);
              color: var(--white);
              border: none;
              border-radius: 4px;
              padding: 7px 15px;
              cursor: pointer;
              font-weight: bold;
              transition: background-color 0.3s ease;
      
              &:disabled {
                background-color: var(--grey);
                cursor: not-allowed;
              }
            }
          }
        }
      
      
    }
    }
    .table-container {
      overflow-x: scroll;
      white-space: nowrap; /* Prevent shrinking */
      height: auto; /* Allow the container to adjust height dynamically */
  
      .custom-table {
        width: auto; /* Prevent shrinking of the table */
        min-width: 600px; /* Ensure a minimum width for scrolling */
      }
    }
  }
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .table-container {
      overflow-x: scroll;
      white-space: nowrap;
  
      .custom-table {
        width: auto;
        min-width: 600px;
      }
    }
  }
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .table-container {
      overflow-x: scroll;
      white-space: nowrap;
  
      .custom-table {
        width: auto;
        min-width: 800px; /* Slightly larger minimum width for medium devices */
      }
    }
  }
  @media (min-width: 992px) {
    .table-container {
      overflow-x: auto;
      white-space: nowrap;
      .custom-table {
        min-width: 100%; /* Allow table to use full width */
      }
    }
  } 
}
.bill-details-card {
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  color: var(--grey);
  margin-top: 10px;

  .bill-apply-coupon-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--white);
    border-radius: 8px;
    padding: 10px 15px;
    color: var(--dark-blue);
    cursor: pointer;
    width: 100%;
    margin-bottom: 20px;

    .fa-arrow-right {
      font-size: 18px;
      color: var(--dark-blue);
    }
  }

  .applied-coupon-section {
    display: flex;
    flex-direction: column;
    border: 1px solid #d1e7dd;
    background-color: #f0fdf4;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;

    .applied-coupon-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .coupon-check-icon {
        color: #28a745;
        margin-right: 10px;
        font-size: 20px;
      }

      .applied-coupon-text {
        font-size: 16px;
        font-weight: 500;
        color: #1c7430;
      }

      .coupon-remove-icon {
        font-size: 18px;
        color: #dc3545;
        cursor: pointer;

        &:hover {
          color: #a71d2a;
        }
      }
    }

    .applied-coupon-subtext {
      font-size: 14px;
      color: #6c757d;
      margin-top: 5px;
    }
  }

  .bill-details {
    .bill-details-header {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    hr {
      border: 0;
      border-top: 1px solid #e0e0e0;
      margin: 0;
    }

    .bill-delivery-note {
      color: #28a745;
      margin-top: 10px;
    }

    &.text-success {
      color: #28a745;
    }
  }

  .bill-estimated-payable {
    margin-top: 5px;
    color: var(--grey);
    display: flex; /* Enables flexbox layout */
    justify-content: space-between; /* Aligns items to the edges */
    align-items: center; /* Centers items vertically */
  }
  
  .bill-estimated {
    margin: 0; /* Removes unnecessary top margin */
  }
  
  .bill-amount {
    margin: 0; /* Removes unnecessary margin */
    font-weight: bold; /* Ensures the amount is visually distinct */
    color: var(--dark-blue);
  }
  

  .add-address-btn {
    background-color: var(--dark-blue);
    color: #fff;
    font-weight: 300;
    border-radius: 8px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    border: none;
    min-height: 20px;
    margin-top: "5px";

    &:hover {
      background-color: var(--dark-blue);
    }
  }
}

.order-export-main-page{
    background-color: var(--whitesmoke);
    padding:20px;
  
    .breadcrumb-container {
      display: flex;
      align-items: center;
      padding: 10px ;
      
      color: var(--grey);
     
    background-color: var(--white);
  
    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }
  
    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none; // Remove underline
      color: inherit;
  &:hover {
    text-decoration: none; // Ensure no underline on hover
   
  }
    }
  
    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--light-grey);
    }
    }
  
      .order-export-container{
  
        padding:5px;
        border-radius: 10px;
    
        background-color: var(--whitesmoke); 
      
  }
  .order-export {
    padding: 20px ;
    background-color: var(--white);
    border-radius: 10px;
  
  .order-table {
    background-color: var(--white);
    border-radius: 10px;
  
    .header-row {
      display: flex;
      justify-content: space-between; /* Push items to opposite corners */
      align-items: center;
     
    
  
    .text-heading1 {
        padding: 10px !important;
       
        text-align: left !important;
        align-self: flex-start; /* If parent is flexbox */
    }
  
    .add-new-button {
        display: flex;
        justify-content: flex-end; /* Aligns the button to the right */
        align-items: center;
        gap: 15px;

        .file-upload1{
          background-color: var(--dark-blue); /* Use a green color for the Add New button */
          color: var(--white);   
          border: none;
          border-radius: 4px; 
          cursor: pointer;
        }
        .upload-button {
            background-color: var(--dark-blue); /* Use a green color for the Add New button */
            color: var(--white);    
            border: none;
            border-radius: 4px; 
            cursor: pointer;
        }

        .save-button {
            background-color: var(--dark-blue); /* Use dark-blue color for the Save button */
            color: var(--white);    
            border: none;
            border-radius: 4px; 
            cursor: pointer;
        }
    }
  }
        
  
    
  .table-container {
    margin-top: 40px;
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: auto; /* Enable vertical scrolling */
    white-space: nowrap; /* Prevent text wrapping */
    height: 300px; /* Set height for the scrollable container */
    -ms-overflow-style: none; /* IE and Edge */
    // scrollbar-width: none; /* Firefox */
  
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Webkit-based browsers */
    }
  
    .custom-table {
    //   width: 100%;
      border-collapse: collapse;
      table-layout: fixed; /* Force equal column width */
  
      thead {
        background-color: var(--th); /* Background for the header */
      }
  
      th {
        position: sticky; /* Make header sticky */
        top: 0; /* Stick to the top of the container */
        z-index: 2; /* Ensure header stays above body rows */
        background-color: var(--th); /* Header background color */
        padding: 6px 6px; /* Ensure padding is applied */
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
        border-bottom: 1px solid var(--border-bottom);
        box-sizing: border-box; /* Include padding in height/width calculations */
        height: 50px; /* Uniform header height */
      }
  
      tbody td {
        padding: 6px 6px;
        text-align: center;
        border-bottom: 1px solid var(--border-bottom);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color:var(--grey);
        height: 50px; /* Uniform row height */
        box-sizing: border-box;
      }
  
      th:first-child, td:first-child {
        text-align: center;
      }
  
      .small-column {
        width: 100px; /* Set specific width for small columns */
      }
  
      td input {
        padding: 6px 6px;
        width: auto;
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        outline: none;
        font-family: 'Poppins', sans-serif;
      }
  
      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 15px;
      }
      .icon-button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px;
        color: var(--grey);
    
        &:hover {
          color: var(--black);
        }
      }
    
    }
}
  .button-row {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
    gap: 15px;
  
    .next-button {
        background-color: var(--dark-blue);
        color:var(--white);
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease;
  
        &:hover {
            background-color: var(--hover-blue);
        }
    }
  }
  
  }
  }
  @media (max-width: 575.98px) {
.order-export {
.order-table{
  .header-row{
    .text-heading1{
      font-size: 18px;
    }
    .add-new-button{
      display:flex;
      
      .file-upload1{
        padding: 8px 16px;
        font-size: 14px;
      }
      .upload-button{
        padding: 8px 16px;
        font-size: 14px;
      }
      .save-button{
        padding: 8px 16px;
        font-size: 14px;
      }
    }
  }
}

}
  }
}
.modal-lg {
  max-width: 800px;
  margin: 0px ;
  padding: 0px !important;
  display: block;
  height: auto;

  .login-main-container {
    border-radius: 10px;
    padding: 20px;

    .login-row {
      margin: 0;
      width: 100%;
      display: flex !important;
      flex-wrap: wrap;

      .login-left-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--dark-blue);
        border-radius: 8px;
        position: relative;
        padding: 0;
      }
     
.login-doc-image {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ensures the entire image fits without being cut off */
  position: relative; /* Removed absolute positioning */
  max-width: 100%;
  max-height: 100%;
}
      .login-right-container {
        padding: 10px !important;
        position: relative;

        .login-text-left {
          padding: 0;

          .login-pharma-logo {
            width: 120px;
           
            margin-bottom: 10px;
          }

          .login-text-left1 {
            padding: 0;

            .login-header-text {
              color:var(--grey);
              margin-bottom: 15px;
            }

            .login-input-spacing {
              margin-bottom: 15px;
            }

            .login-checkbox-container {
              display: flex;
              align-items: center;
              margin-bottom: 15px;
              margin-top: 5px;
            
              .login-forgot-password-text {
                margin-left: 8px; // Adjust this value as needed to control the space between the checkbox and text
               
                color:var(--dark-blue);
              }
            }
            

            .login-button {
              width: 100%;
              margin-top: 10px;
            }
            .login-or-text {
              text-align: center;            
              color: #666;
              margin: 5px 5px; // Adjust this margin for spacing between buttons
            }
          .otp-button{
            width:100%;
          }
            

            .login-google-button-class {
              margin-top: 10px;
            }

            .login-sign-in {
              margin-top: 10px;
              align-items: center;
              text-align: center;
            }
            .login-sign-in1 {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  /* Skeleton styles */
  .skeleton {
    &.login-left-container {
      background-color: var(--dark-blue);
      border-radius: 8px;
      position: relative;
      padding: 0;
      display: flex;

      .login-doc-image {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: 40%;
      }
    }

    &.login-pharma-logo {
      width: 100px;
      height: 100px;
      background-color: #e0e0e0;
      border-radius: 8px;
      margin-top: 20px;
      margin-bottom: 1.5rem;
      min-height: 10px;
    }

    &.login-header-text {
      background-color: #e0e0e0;
      height: 20px;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    &.login-input-spacing {
      background-color: #e0e0e0;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      margin-bottom: 15px;
    }

    &.login-google-button-class {
      background-color: #e0e0e0;
      height: 40px;
      border-radius: 7px;
      margin-bottom: 10px;
      width: 100%;
    }

    &.login-sign-in {
      margin-top: 10px;
      background-color: #e0e0e0;
      border-radius: 4px;
      width: 100%;
      height: 20px;
    }
  }

  /* Media Queries for responsiveness */
  /* Min-width 576px */
  @media (max-width: 575.98px) {
    .login-main-container {
      display: flex;
      flex-direction: column;

      .login-row {
        .login-left-container {
          display: none !important;
        }

        .login-right-container {
          padding: 15px;
        }
      }
    }
  }

  /* Min-width 576px */
  @media (min-width: 576px) {
    .login-main-container {
      .login-row {
        display: flex !important;
        flex-direction: column;
        width: 100%;

        .login-left-container {
          display: none !important;
        }
      }
    }
  }

  /* Min-width 768px */
  @media (min-width: 768px) {
    .login-main-container {
      .login-row {
        display: flex !important;
        flex-direction: column;

        .login-left-container {
          display: none !important;
        }
      }
    }
  }

  /* Min-width 992px */
  @media (min-width: 992px) {
    .login-main-container {
      .login-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .login-left-container {
          display: block !important;
        }
      }
    }
  }

  /* Min-width 1200px */
  @media (min-width: 1200px) {
    .login-main-container {
      .login-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .login-right-container {
          padding: 30px;
        }
      }
    }
  }

  /* Min-width 1400px */
  @media (min-width: 1400px) {
    .login-main-container {
      .login-row {
        display: flex;
        flex-direction: row;
        width: 100%;

        .login-right-container {
          padding: 30px;
        }
      }
    }
  }
}

.admin-add-user-section {
  padding: 20px;
  background-color: var(--whitesmoke);

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px ;
   
    color: var(--light-grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color:var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color:var(--grey);
  }
  }
  .categorylist-container{
    padding:5px;
    border-radius: 10px;

    background-color: var(--whitesmoke);
  }
  .admin-user-form {
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;
    .text-heading1 {
      padding: 10px !important;
      margin-bottom: 20px;
      text-align: left !important;
      align-self: flex-start; /* If parent is flexbox */
  }
   
    .form-row {
         
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;

        label {
          margin-bottom: 5px;
          font-weight: bold;
        }
        label.required::after {
          content: ' *';
          color:var(--red);
          margin-left: 5px;
        }
 
        .date-input {
          width: 100%;
          margin: 0px;
          padding: 7px;
          border-radius: 5px;
          border: 1px solid var(--border);
      }
        .file-upload {
          padding: 6px;
          border-radius: 7px;
          border: 1px solid var(--border);
        }
      }
    }
    
    .form-row1 {
     width:50%;

     
     label {
      margin-bottom: 5px;
      font-weight: bold;
    }
    label.required::after {
      content: ' *';
      color:var(--red);
      margin-left: 5px;
    }
    }
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .submit-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 6pxpx ;

        &:hover {
          background-color: #083d73;
        }
      }
    }
  }
   /* Media Queries for Responsive Design */
  @media (min-width: 1400px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 1200px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 992px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 768px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 576px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    /* Stack fields in a column for small screens */
    @media (max-width: 575.98px) {
      .form-row {
        display: flex;
        flex-direction: column;
      }
  
      .form-group {
        margin-bottom: 15px;
      }
   
      .form-row1{
        width:100% !important;
        .form-group {
          margin-bottom: 15px;
          width:100%;
        }

      }
      .submit-button {
        width: 100%;
       
      }
    }
  
}
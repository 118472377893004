.detailed-status-modal {
    .modal-content {
      border-radius: 12px;
      padding: 10px;
    }
  
    .status-timeline {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      position: relative;
    
  
    .status-step {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;
  
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        left: 21px; /* Aligns with the center of the icon */
        top: 45px; /* Adjusts the height between icons */
        width: 2px; /* Line width */
        height: 40px; /* Height of connecting lines */
        background-color: #ddd; /* Line color */
      }
  
      &.active .status-title {
        font-weight: bold;
        color: var(--dark-blue);
      }
  
      .icon-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #f3f4f6;
        position: relative;
        z-index: 1; /* Places icon above line */
  
        .fa-icon {
          font-size: 20px;
          color: #6c757d;
        }
  
        /* Specific icon colors for each step */
        .order-placed-icon {
          color: #28a745;
        }
        .order-packed-icon {
          color: #ffc107;
        }
        .ready-for-dispatch-icon {
          color: #17a2b8;
        }
        .dispatched-icon {
          color: #007bff;
        }
        .delivered-icon {
          color: #6f42c1;
        }
      }
  
      .status-content {
        flex-grow: 1;
  
        .status-title {
         
          color: #343a40;
        }
  
        .status-time {
        
          color: #6c757d;
        }
  
        .status-detail {
          margin-top: 8px;
          padding: 12px;
          border: 1px solid #007bff;
          border-radius: 8px;
          background-color: #f8f9fa;
  
          .status-detail-header {
          
            font-weight: bold;
          }
  
          .status-detail-description {
           
            color: #6c757d;
          }
        }
  
        .status-expected {
         
          color: #6c757d;
        }
      }
    }
  }
}
.terms-container {
    background-color: #f0f0f0;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .header {
      background-color: #666;
      color: white;
      text-align: center;
      width: 100%;
      padding: 20px 0;
  
      h1 {
        font-size: 24px;
        font-weight: bold;
      }
  
      .breadcrumb {
        font-size: 14px;
        margin-top: 5px;
      }
    }
  
    .template-box {
      max-width: 1200px;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
    }
  
    .content-box {
      width: 100%;
      padding: 20px;
  
      h2 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      .effective-date {
        font-size: 14px;
        color: gray;
        margin-bottom: 10px;
      }
  
      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-top: 20px;
      }
  
      p {
        font-size: 14px;
        margin-bottom: 10px;
      }
  
      .contact-list {
        list-style-type: none;
        padding-left: 0;
        font-size: 14px;
      }
    }
  
    .contact-box {
      background-color: #333;
      color: white;
      text-align: center;
      padding: 20px;
      border-radius: 10px;
      margin-top: 20px;
  
      h2 {
        font-size: 18px;
        font-weight: bold;
      }
  
      .contact-number,
      .contact-email {
        margin-top: 10px;
        font-size: 14px;
      }
  
      .contact-btn {
        background-color: #ffcc00;
        color: black;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-top: 15px;
      }
    }
  }
  
.shop-section {
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 8px;
  margin: 0px;

  .carousel-section {
    margin-bottom: 40px;
  }

  .section-title {
    font-weight: 700;
    font-style: normal;
    color: #333;
    margin: 10px 0px 20px 20px;
  }

  .carousel-row {
    display: flex;
    justify-content: flex-start; /* Align cards to the left */
    align-items: center;
    margin: 0; /* Remove extra margin to reduce gaps */
  }

  .carousel-col {
    display: flex;
    justify-content: center;
    padding: 0 5px; /* Reduce the padding to reduce gaps between cards */
  }

  /* Carousel Controls */
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    align-items: center !important;
    justify-content: center !important;
    background-color: #858282;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: transform 0.5s ease-in-out;
    background-size: 50% 50%;
    margin: 0 20px; /* Adjust margin around controls */
  }

  .carousel-control-prev,
  .carousel-control-next {
    width: auto; /* Prevents full width taking of the controls */
    height: auto; /* Auto height for controls */
  }
}

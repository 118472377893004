.bulk-allocate-coupon-main-page{
  padding: 20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
      display: flex;
      align-items: center;
      padding: 10px ;
      font-size: 16px;
      color: var(--grey);
     
    background-color: var(--white);
  
    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }
  
    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none; // Remove underline
      color: inherit;
  &:hover {
    text-decoration: none; // Ensure no underline on hover
   
  }
    }
  
    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--fade-grey);
    }
    }
    .bulk-allocate-coupon-container{

      padding:5px;
      border-radius: 10px;
  
      background-color: var(--whitesmoke);
    
}
.bulk-allocate-coupon {
  padding: 20px ;
  background-color: var(--white);
  border-radius: 10px;

.coupon-table {
  background-color: var(--white);
  border-radius: 10px;

  .header-row {
    display: flex;
    justify-content: space-between; /* Push items to opposite corners */
    align-items: center;
   
  

  .text-heading1 {
      padding: 10px !important;
     
      text-align: left !important;
      align-self: flex-start; /* If parent is flexbox */
  }

  
  .add-new-button {
    display: flex;
    align-items: center;
    gap: 15px;
    
    .add-button {
      background-color: var(--dark-blue); /* Use brand-specific dark-blue color */
      color: var(--white);
      border: none;
      border-radius: 4px;
      padding: 7px;
      cursor: pointer;
      
      transition: background-color 0.3s ease;

      &:disabled {
        background-color: var(--grey); /* Optional: Indicate disabled state */
        cursor: not-allowed;
      }
    }

    .save-button {
      background-color: var(--dark-blue); /* Use brand-specific dark-blue color */
      color: var(--white);
      border: none;
      border-radius: 4px;
      padding: 7px ;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:disabled {
        background-color: var(--grey); /* Optional: Indicate disabled state */
        cursor: not-allowed;
      }
    }
  }
}
      
}
  
.table-container {
  position: relative; /* For positioning the scroll icon */
  margin-top: 40px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  height: 300px; /* Fixed height for vertical scrolling */
  white-space: nowrap; /* Prevent text wrapping */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */

  /* Custom scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
      height: 8px; /* Height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
      background: transparent; /* Background of the scrollbar track */
  }

  &::-webkit-scrollbar-thumb {
      background: var(--whitesmoke); /* Thumb color */
      border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: var(--very-light-grey); /* Thumb color on hover */
  }

  .custom-table {
      width: 100%; /* Ensure the table takes full width of the container */
      border-collapse: collapse;
      table-layout: fixed; /* Consistent column widths */
      min-width: 800px; /* Set a minimum width for the table to prevent shrinking */

      thead {
          th {
              position: sticky; /* Sticky header */
              top: 0; /* Fix header at the top of the container */
              z-index: 2;
              background-color: var(--th);
              padding: 12px;
              text-align: center;
              border-bottom: 2px solid var(--border-bottom);
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              color: var(--grey);
              min-width: 120px; /* Set minimum width for table headers */
          }
      }

      tbody {
          td {
              padding: 12px;
              text-align: center;
              border-bottom: 1px solid var(--border-bottom);
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              color: var(--grey);
              height: 50px;
              box-sizing: border-box;
              min-width: 120px; /* Set minimum width for table cells */
          }

          td input {
              padding: 10px;
              width: 100%; /* Ensure the input takes the full width of the cell */
              border: none;
              border-bottom: 1px solid var(--border-bottom);
              outline: none;
              font-family: 'Poppins', sans-serif;
          }
      }
  }

  .icon-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--grey);

      &:hover {
          color: var(--black);
      }
  }
}




}

@media (max-width: 575.98px) {
  .bulk-allocate-coupon {
    .coupon-table {
      .header-row {
        display: flex;
        flex-direction: column; /* Stack heading and buttons vertically */
        align-items: flex-start; /* Align heading and buttons to the left */
    
        .text-heading1 {
          margin-bottom: 10px; /* Add spacing between heading and buttons */
          text-align: left;
        }
    
        .add-new-button {
          display: flex; /* Place buttons in a row */
          flex-direction: row; /* Ensure buttons are in a single row */
          gap: 10px; /* Spacing between buttons */
    
          .add-button,
          .save-button {
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            padding: 7px 15px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
    
            &:disabled {
              background-color: var(--grey);
              cursor: not-allowed;
            }
          }
        }
      }
    
    
  }
  }
  .table-container {
    overflow-x: scroll;
    white-space: nowrap; /* Prevent shrinking */
    height: auto; /* Allow the container to adjust height dynamically */

    .custom-table {
      width: auto; /* Prevent shrinking of the table */
      min-width: 600px; /* Ensure a minimum width for scrolling */
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 600px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 800px; /* Slightly larger minimum width for medium devices */
    }
  }
}

    @media (min-width: 992px) {
      overflow-x: scroll; /* Force horizontal scrolling */
    }
  
    @media (min-width: 1200px) {
      overflow-x: scroll; /* Force horizontal scrolling */
    }
  
    @media (min-width: 1400px) {
      overflow-x: scroll; /* Force horizontal scrolling */
    }
  }
  

.popup-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
    backdrop-filter: blur(5px); // Apply a blur effect
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .order-popup {
      background-color: var(--white);
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      width: 50%; // Adjust width as needed
      max-width: 400px; // Prevent it from being too wide on large screens
      position: relative; // For close button positioning
  
      .close-popup-btn {
        position: absolute;
        top: 5px;
        right: 10px;
        background: none;
        border: none;
        font-size: 40px;
     
        color: var(--dark-blue);
        cursor: pointer;
  
        &:hover {
          color: var(--red); // Change color on hover
        }
      }
  
      .popup-content {
        text-align: center;
  
        .popup-header {
          margin-bottom: 20px;
  
          .popup-image {
            width: 300px;
            height: auto;
            border-radius: 8px;
            margin: 0 auto; // Center the image
            display: block;
          }
        }
  
        .fetch-orders-btn {
          background-color: var(--dark-blue);
          color: var(--white);
          border: none;
          padding: 10px 20px;
          border-radius: 4px;
          cursor: pointer;
          font-weight: 500;
          display: block; // Ensure the button is block-level
          margin: 0 auto; // Center the button horizontally
  
        }
      }
    }
    @media (max-width: 575.98px) {
      .order-popup {
        width: 90%;
        padding: 15px;
      }
  
      .close-popup-btn {
        font-size: 30px;
      }
  
      .fetch-orders-btn {
        padding: 8px 16px;
      }
    }
  
    @media (min-width: 576px) {
      .order-popup {
        width: 80%;
        max-width: 350px;
      }
    }
  
    @media (min-width: 768px) {
      .order-popup {
        width: 70%;
        max-width: 400px;
      }
  
      .fetch-orders-btn {
        padding: 12px 24px;
      }
    }
  
    @media (min-width: 992px) {
      .order-popup {
        width: 60%;
        max-width: 500px;
      }
    }
  
    @media (min-width: 1200px) {
      .order-popup {
        width: 50%;
        max-width: 600px;
      }
    }
  
    @media (min-width: 1400px) {
      .order-popup {
        width: 40%;
        max-width: 700px;
      }
    }
  
  }
  
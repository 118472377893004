
.input-placeholder::placeholder {
   padding:6px;
    color: var(--light-grey); /* Optionally, change the color as well */

}

.form-control {
  margin-bottom: 0px !important;
   border: 1px solid rgb(128, 127, 127);
   padding: 2px; /* Adjust padding as needed */
}

.form-group {
    margin-bottom: 0px !important;
   padding:6px;
    gap:5px;
}

.admin-login-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  width: 100vw;
  min-height: 80vh;

  .admin-login-logo {
    margin-bottom: 20px;
    img {
      width: 100px;
      height: auto;
    }
  }

  .admin-login-box {
    display: flex;
    flex-direction: row; /* Default layout: side-by-side */
    align-items: stretch;
    width: 100%; /* Full width for responsiveness */
    max-width: 900px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

    .admin-login-left {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dark-blue);
      padding: 20px;

      .login-doc-image {
        width: 100%; 
        max-width: 300px; 
        object-fit: contain;
      }
    }

    .admin-login-right {
      flex: 1;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .sign-in-header {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      .form-group {
        margin-bottom: 15px;

        .login-input {
          width: 100%;
          padding: 12px 15px;
          border: 1px solid #ccc;
          border-radius: 4px;
          font-size: 16px;
        }
      }

      .login-btn {
        width: 100%;
        background-color: var(--dark-blue);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 16px;
        margin-top: 10px;
        text-align: center;

        &:hover {
          background-color: #004080;
        }
      }
    }
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .admin-login-box {
    flex-direction: column; /* Stack sections vertically */
  }

  .admin-login-left {
    display: none!important;
  }

  .admin-login-right {
    padding: 10px; /* Add padding for better spacing */
  }
}
@media (max-width: 768px) {
  .admin-login-box {
    flex-direction: column !important; /* Force stacking */
  }

  .admin-login-left {
    order: -1; /* Ensure left section appears above */
    padding-bottom: 10px;
    text-align: center;
  }

  .admin-login-right {
    padding: 10px; /* Add padding for better spacing */
  }
}

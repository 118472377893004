.login-main-container {
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  .login-row {
    display: flex;
    flex-wrap: wrap;

    .login-left-container {
      display: none; /* Hide by default */
      background-color: var(--dark-blue);
      border-radius: 8px;
      position: relative;
      padding: 0;

      .login-doc-image {
        max-width: 60%;
        position: absolute;
        bottom: 10px;
        left: 10px;
      }
    }

    .login-right-container {
      padding: 20px 30px;
      background-color: #fff;
      border-radius: 8px;
      text-align: center;

      .login-pharma-logo-class img.login-pharma-logo {
        width: 120px;
        margin-bottom: 15px;
      }
      .otp-header-text {
        font-size: 16px; // Adjust the font size
        font-weight: 400; // Normal font weight
        color: #6c757d; // Gray color for the main text
        margin-bottom: 8px;
      
        span {
          font-weight: 700; // Bold for the mobile number
          color: #000; // Black for the mobile number
        }
      
        .edit-number-link {
          color: #007bff; // Blue color for the "Edit" text
          font-size: 14px; // Slightly smaller font for the "Edit" text
          cursor: pointer; // Pointer cursor for clickable text
          text-decoration: underline; // Add underline for "Edit"
          margin-left: 8px; // Space between mobile number and "Edit"
      
          &:hover {
            text-decoration: none; // Remove underline on hover
            color: #0056b3; // Darker blue on hover
          }
        }
      }
      

      .otp-header {
        font-size: 20px;
        color: var(--dark-blue);
        margin-bottom: 10px;
      }

      .otp-subtext {
        font-size: 14px;
        color: #555;
        margin-bottom: 20px;

        .otp-edit {
          color: var(--dark-blue);
          cursor: pointer;
          font-weight: bold;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .login-input-spacing1 {
        margin-bottom: 20px;
      }

      .timer-text {
        font-size: 14px;
        color: #888;
        margin-bottom: 20px;
      }

      .otp-button {
        width: 100%;
        margin-top: 10px;
      }

      .login-sign-in {
        margin-top: 20px;

        .login-sign-in-text {
          font-size: 13px;
          color: #888;

          .login-text-link {
            color: var(--dark-blue);
            font-weight: bold;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .back-to-login {
        margin-top: 15px;

        .btn-link {
          color: var(--dark-blue);
          font-size: 14px;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  /* Optional Media Queries */
  @media (min-width: 992px) {
    .login-left-container {
      display: block; /* Show the left section only on larger screens */
    }
  }
}

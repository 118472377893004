.orders-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .filter-dropdown {
    margin-bottom: 20px;
    display: inline-block;
    align-items:end; /* Align the dropdown to the right */
  
    .filter-select {
      width: 100%;
      max-width: 300px;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #007bff; /* Blue border */
      border-radius: 4px;
      background-color: #fff;
      color: #007bff; /* Blue text */
      cursor: pointer;
  
      &:hover {
        border-color: #0056b3; /* Darker blue on hover */
      }
  
      &:focus {
        outline: none;
        border-color: #0056b3; /* Darker blue when focused */
      }
    }
  }
  
  
  .orders-title {
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    width: 100%;
    font-size: 24px;
    color: #333;
  }

  .order-container {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .order-status-container {
        display: flex;
        align-items: center;
        gap: 10px;

        .order-status-icon {
          width: 50px;
          height: 50px;
          object-fit: contain;
        }

        .order-status-details {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .order-status {
            font-weight: bold;
            font-size: 16px;

            &.pending {
              color: #f0ad4e; /* Amber for pending */
            }

            &.processing {
              color: #0275d8; /* Blue for processing */
            }

            &.shipped {
              color: #5bc0de; /* Cyan for shipped */
            }

            &.completed {
              color: #5cb85c; /* Green for completed */
            }

            &.cancelled {
              color: #d9534f; /* Red for cancelled */
            }

            &.failed {
              color: #d9534f; /* Same red for failed */
            }
          }

          .order-date {
            font-size: 14px;
            color: #666;
          }
        }
      }

      .order-info-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 5px;

        .order-id {
          font-size: 14px;
          color: #666;
        }

        .order-total {
          font-size: 18px;
          font-weight: bold;
          color: #333;
        }
      }
    }

    .order-details {
      font-size: 14px;
      color: #555;

      .product-list {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .customer-name {
        font-weight: bold;
        color: #333;
      }
    }

    .order-footer {
      text-align: right;

      .track-order-btn {
        padding: 8px 16px;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  .no-orders-content {
    text-align: center;

    .no-orders-image {
      width: 200px;
      height: auto;
      margin-bottom: 20px;
    }

    .no-orders-text {
      font-size: 16px;
      color: #666;
    }
  }
}

// Media Queries for Responsive Design
@media (max-width: 768px) {
  .orders-page {
    .orders-title {
      font-size: 20px;
    }

    .order-container {
      padding: 15px;
    }

    .order-header {
      flex-direction: column;
      align-items: flex-start;

      .order-info-right {
        align-items: flex-start;
      }
    }
  }
}

@media (max-width: 576px) {
  .orders-page {
    .order-container {
      .order-header {
        .order-status-container {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}

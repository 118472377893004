/* loginPopup.scss */
.custom-popover .popover-body {
  padding: 0px; /* Remove default padding */
}

.login-popup-content {
  width: 250px;
  background-color:var(--white);
  border-radius: 8px;
  padding: 10px;
  font-family: Arial, sans-serif;
  color: #333;
}

.login-header {
  margin-bottom: 20px;

  .username {
    font-size: 18px;
    font-weight: bold;
    color: var(--grey);
    margin: 0;
  }

  .user-phone {
    font-size: 14px;
    color: var(--grey);
    margin: 5px 0;
  }

  .user-details-link {
    font-size: 14px;
    color: var(--dark-blue);
    text-decoration: none;
  }
}

.login-menu {
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  .menu-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    text-align: left;

    .span {
      text-align: left !important;
      align-items: flex-start !important;
    }
    .menu-icon {
      color: var(--dark-blue);
      font-size: 15px;
      margin-right: 10px;
    }

    .chevron-icon {
      color: #bbb;
      font-size: 14px;
    }
  }
}

.login-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  
  .footer-link {
    color: var(--grey);
    text-decoration: none;
  }

  .logout-link {
    color: var(--dark-blue);
    text-decoration: none;
    font-weight: bold;
  }
}

.iconsGroup {
  display: flex;
  align-items: center;
  color: var(--dark-blue);
  justify-content: space-between;
  font-weight: 500;
  padding: 0;

  .iconsGroupDownloadApp,
  .iconsGroupProfileIcon,
  .Login-text,
  .iconsGroupCartText {
    margin-right: 20px;
    cursor: pointer;
  }

  .iconsGroupCart {
    position: relative;
    cursor: pointer;
  }

  .profile-icon-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 15px; /* Add margin to separate profile and cart */
  }

  .iconsGroupProfileIcon {
    font-size: 1.2rem;
    margin-right: 20px; /* Adjust spacing around the profile icon */
    cursor: pointer;
  }

  @media (max-width: 575.98px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
    

    .iconsGroupCart {
      display: flex;
      margin-left: auto;
    }
  }

  @media (min-width: 576px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
   
    .iconsGroupCart {
      display: flex;
      margin-left: auto;
    }
  }

  @media (min-width: 768px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
    

    .iconsGroupCart {
      display: flex;
      margin-left: auto;
    }
  }

  @media (min-width: 992px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
    .iconsGroupCartText {
      display: flex;
    }
  }

  @media (min-width: 1200px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
    .iconsGroupCartText {
      display: flex;
    }
  }

  @media (min-width: 1400px) {
    .iconsGroupDownloadApp,
    .iconsGroupProfileIcon,
    .Login-text,
    .iconsGroupCartText {
      display: flex;
    }
  }
}

/* Modal styles */
.download-app-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.download-app-modal {
  background-color: white;
  width: 90%; /* Default width for small screens */
  max-width: 500px; /* Maximum width for larger screens */
  height: auto;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* Media Queries for Modal Popup */
@media (max-width: 575.98px) {
  .download-app-modal {
    display: flex;
    max-width: 350px; /* Smaller width for small screens */
  }
}
@media (min-width: 576px) {
  .download-app-modal {
    width: 400px; /* Medium width for larger small screens */
  }
}

@media (min-width: 768px) {
  .download-app-modal {
    width: 500px; /* Medium width for tablets */
  }
}

@media (min-width: 992px) {
  .download-app-modal {
    width: 600px; /* Larger width for desktops */
    height: 400px; /* Fixed height for larger screens */
  }
}
@media (min-width: 1200px) {
  .download-app-modal {
    width: 500px; /* Larger width for desktops */
    height: 300px; /* Fixed height for larger screens */
  }
}
@media (min-width: 1400px) {
  .download-app-modal {
    width: 500px; /* Larger width for desktops */
    height: 300px; /* Fixed height for larger screens */
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.download-app-modal-content {
  display: flex;
  gap: 20px;
  flex-grow: 1;
}

.download-app-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;

  .pharma-logo {
    width: 100px; /* Smaller logo size for responsiveness */
  }

  .download-app-description {
    color: var(--grey);
    margin: 0;
  }

  .google-play-button {
    width: 100px;
    cursor: pointer;
  }
}

.download-app-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .qr-code {
    width: 130px; /* Larger QR code */
    height: 130px;
  }

  .scan-text {
    font-size: 12px;
    color: var(--grey);
    margin: 0;
  }
}

.cart-badge {
  position: absolute;
  top: -10px; /* Adjust the vertical position */
  right: -10px; /* Adjust the horizontal position */
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 50%;
  width: 20px; /* Slightly larger for better visibility */
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  z-index: 1;
}
@media (max-width: 992px) {
  .iconsGroupDownloadApp,
  .Login-text,
  .iconsGroupCartText {
    font-size: 0.9rem; // Reduce font size for smaller screens
    margin-right: 10px;
  }
}

@media (max-width: 768px) {
  .iconsGroupDownloadApp,
  .Login-text,
  .iconsGroupCartText {
    font-size: 0.8rem; // Further reduce font size
    margin-right: 8px;
  }

  .cart-badge {
    top: -8px;
    right: -8px;
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .iconsGroup {
    justify-content: center; // Center align content for very small screens
  }

  .iconsGroupDownloadApp,
  .Login-text,
  .iconsGroupCartText {
    font-size: 0.8rem;
    margin: 5px;
  }
}


.cart-section {
 
  background-color: var(--whitesmoke);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


  .cart-container {
    display: flex;
    flex-direction: column; /* Default column layout */
    padding: 20px;
    background-color: #fff;

    .left-section {
      flex: 1;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      background-color: #fff;

      /* Default to no scrolling */
      overflow-y: visible; /* No scroll by default */
      max-height: none; /* Show full content */

      /* Hide scrollbar styles for various browsers */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */

      &::-webkit-scrollbar {
        display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
      }

      .selected-address-card {
        padding: 16px;
        border-radius: 8px;
        background-color: #f5faff;
    
        .address-header {
          display: flex;
          align-items: center;
          gap: 10px;
    
          .location-icon {
            color: #104a99;
            font-size: 20px;
          }
    
          .deliver-to {
            font-weight: 500;
            font-size: 14px;
            color: #071b33;
          }
    
          .address-name {
            font-weight: bold;
            font-size: 14px;
            color: #071b33;
          }
    
          .office-tag {
            background-color: #eff5ff;
            color: #104a99;
            padding: 4px 8px;
            border-radius: 12px;
            font-size: 12px;
          }
    
          .change-address-btn {
            margin-left: auto;
            background: none;
            border: none;
            color: #2060be;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
    
            &:hover {
              text-decoration: underline;
            }
          }
        }
    
        .address-details {
          margin-top: 8px;
    
          .address-line {
            font-size: 14px;
            color: #6d6d6d;
            line-height: 1.5;
          }
    
          .delivery-date {
            font-size: 14px;
            color: #37a745;
            font-weight: 500;
            margin-top: 4px;
    
            strong {
              font-weight: bold;
            }
          }
        }
      }
    
      .address-dropdown {
        margin-top: 16px;
        padding: 16px;
        background-color: #fff;
        border: 1px solid #dce7ff;
        border-radius: 8px;
    
        .dropdown-title {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 8px;
          color: #104a99;
        }
    
        .address-item {
          padding: 8px;
          border-bottom: 1px solid #eff5ff;
          cursor: pointer;
    
          &:hover {
            background-color: #f5faff;
          }
    
          .dropdown-address {
            font-size: 14px;
            color: #6d6d6d;
          }
    
          .address-type {
            font-size: 12px;
            color: #104a99;
          }
        }
    
        .add-new-address-btn {
          margin-top: 12px;
          background-color: #104a99;
          color: white;
          padding: 8px 16px;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
    
          &:hover {
            background-color: #083366;
          }
        }
      }
    }
    
      .cart-items-header {
        font-weight: bold;
        margin-bottom: 20px;
      }

      .cart-items-container {
        gap: 10px;
        margin-bottom: 20px;

        .cart-items {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          margin-top: 40px;
          gap: 10px;
          border: none; /* Remove border for card items */
        }
      }

      .add-more-medicines-container {
        padding: 0px;
        text-align: center;
        margin-top: 30px;
        min-height: 30px;
        min-width: 10px;

        .add-more-medicines-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 10px;
          background-color: var(--white);
          border: 1px solid var(--dark-blue);
          border-radius: 5px;
          cursor: pointer;
          color: var(--dark-blue);

          .fa-plus {
            margin-right: 8px;
          }

          .add-more-medicines-text {
            margin: 0;
            text-align: center;
            font-weight: bold;
          }
        }
      }

      .upload-prescription-header {
        font-weight: bold;
        margin-top: 20px;
        min-height: 50px;
      }

      .upload-prescription-container {
        padding: 0px;
        text-align: center;
        width: 100px;
        margin-top: 20px;

        .upload-prescription-btn {
          background-color: white;
          border: 1px solid var(--dark-blue);
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding: 15px;
          border-radius: 10px;
          color: var(--dark-blue);

          .fa-plus {
            padding-left: 0;
          }
        }

        .add-prescription-text {
          font-weight: 200;
          color: var(--dark-blue);
        }
      }
      .uploaded-files {
        position: relative;
        width: 120px; // Adjust the size as needed
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Add shadow to the container
      
        .file-preview {
          width: 100%;
          height: 100%;
          border: 2px solid #ddd; // Border directly on the image
          border-radius: 8px; // Rounded corners for the image
          object-fit: cover; // Ensure the image fills the area while maintaining aspect ratio
        }
      
        .remove-file-btn {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 24px; // Adjust the size of the button
          height: 24px;
          background-color: #ff4d4f; // Red background
          color: #fff; // White text
          border: none;
          border-radius: 50%; // Round shape
          font-size: 16px; // Font size of the `✖`
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); // Add shadow to the button
          transition: background-color 0.3s;
      
          &:hover {
            background-color: #d9363e; // Darker red on hover
          }
        }
      }
      

      /* Deliver To Section */
      .deliver-to-section {
        padding: 20px;
        background-color: #f8fbff;
        border-radius: 8px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .address-info {
          display: flex;
          flex-direction: column;

          .address-header {
            font-weight: bold;
            color: #0d47a1;
          }

          .address-details {
            color: #6c757d;
            font-size: 14px;
            margin-top: 4px;
          }
        }

        .change-address {
          font-size: 14px;
          color: #0d47a1;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .right-section {
      flex: 0 0 300px; /* Fixed width for the right section */
      padding: 20px;
      background-color: #fff;
    }
  

  /* Responsive styles */

  @media (max-width: 575.98px) {
    .cart-container {
      flex-direction: column; /* Arrange items in a column */

      .left-section {
        display: block;
        width: 100%;
        margin: 0px;
        overflow-y: visible; /* Disable scrolling for this screen size */
        max-height: none; /* Remove max-height to show full content */
      }

      .right-section {
        display: block;
        width: 100%;
        margin: 0px;
      }
    }
  }

  @media (min-width: 992px) {
    .cart-container {
      flex-direction: row; /* Arrange items in a row */
      justify-content: space-between;

      .left-section {
        overflow-y: auto; /* Enable scrolling for larger screens */
        max-height: 100vh; /* Set max height to allow scrolling */
      }
    }
  }
}
.uploaded-files {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;

  .uploaded-file-item {
    display: flex;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px;

    .file-preview {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .file-name {
      font-size: 12px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .remove-file-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: red;
      color: white;
      border: none;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 14px;
    }
  }
}

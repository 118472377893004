.offcanvas {
  position: fixed;
  top: 0;
  right: -100%;
  width: 300px;
  height: 100%;
 border-radius: 20px;
  color: var(--white);
  z-index: 1050;
  transition: right 0.3s ease-in-out;

  &.show {
    right: 0;
  }

  .offcanvas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: var(--white);

    .offcanvas-title {
      margin: 0;
      color: var(--dark-blue) !important;
    }

    .close-button {
      background: none;
      border: none;
      color: var(--dark-blue);
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .offcanvas-body {
    padding: 1rem;
    
  .offcanvas-category-title {
      font-size: 1.1rem !important;
      margin-bottom: 1rem !important;
      color:var(--dark-blue);
    }

    .category-list {
      list-style: none;
      padding: 0;

      .category-item {
        padding: 0.75rem 1rem;
        background-color: var(--white);
        color: var(--dark-blue);
        border-bottom: 1px solid var(--border-bottom);
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}
.btn-dropdown {
  background-color: var(--white); /* Set background color */
  border: 1px solid #ccc; /* Optional: Add a border */
  color: black; /* Set text color */
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Ensure text and arrow are separated */
  align-items: center; /* Vertically align text and arrow */
  padding-right: 20px; /* Ensure space between text and arrow */

  &:hover,
  &:focus,
  &:active,
  &.show { /* Add `.show` to handle when the dropdown is open */
    background-color: var(--white) !important; /* Force background to remain white */
    color: black !important; /* Ensure text color stays black */
    box-shadow: none !important; /* Remove any box-shadow on focus/active */
    border-color: #ccc; /* Maintain border color */
  }

  &:after {
    margin-left: auto; /* Push the arrow to the right */
    margin-right: 0; /* Ensure no gap on the right side */
  }
}

.dropdown-toggle::after {
  font-size:12px;
  content: ""; /* Keep the arrow visible by default */
  margin-left: auto; /* Push the arrow to the right corner */
}

.dropdown-menu {
  font-size: 12px;
  width: 100%; /* Ensure dropdown menu aligns with button width */
  text-align: left; /* Align dropdown text to the right */
}

.dropdown-item {
  font-size: 12px;
  text-align: left; /* Align each item in the dropdown to the right */
  
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important; /* Disable hover background */
    color: inherit; /* Keep text color unchanged */
  }
}

.skeleton {
  background-color: #e0e0e0;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
   
  &.img-fluid {
    width: 100%;
    height: 100%;
  }
  .shimmer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: -150%; /* Start off-screen */
    background: linear-gradient(
      90deg, 
     
      #e0e0e0 0%,
      #f0f0f0 50%,
      #e0e0e0 100%

    );
    animation: shimmer 2s ease-in-out infinite; /* Slower, smoother transition */
  }

  @keyframes shimmer {
    0% {
      transform: translateX(-150%); /* Start further left */
    }
    100% {
      transform: translateX(150%); /* End further right */
    }
  }

 
}

.secondary-navbar {
  background-color: var(--very-light-blue);

  .navbar-menu {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;

    .menu-item {
      .menu-button {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        color: var(--dim-grey);
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: var( --pale-shy-blue); /* Change border color on hover */
          color: #fff;/* Ensure text color remains unchanged */
        }
      
        &.active {
          background-color: var( --pale-shy-blue);/* Change border color on hover */
          color: #fff; /* Keep text color consistent */
        }
       
        .dropdown-icon {
          font-size: 12px;
          margin-left: 5px;
        }
      }
    }
  }
}

/* Modal Styling */
.custom-modal {
  position: absolute;
  z-index: 1000;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full-width modal */
  padding: 10px;

  .modal-content {
    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 15px;
     
      color: var(--black);
      padding: 10px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;

      .close-button {
        background: transparent;
        border: none;
        color: var(-black);
        cursor: pointer;
        font-size: 18px;
      }
    }

    .modal-list {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px;
        border-bottom: 1px solid var(--border-bottom);
        cursor: pointer;

        &:hover {
          background-color:var(--snowy-grey);
        }
      }
    }
  }
}

/* Media Queries */
@media (max-width: 575.98px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: none; /* Hide all items except Items Menu */
      }

      .items-menu-button {
        display: flex; /* Show only the Items Menu button */
        justify-content: center;
        width: 100%;
        background-color: var( --charcoal-grey);
        color: var(--white);

        .menu-button {
          color: var(--white);
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .items-modal {
    display: block;
  }
}

@media (min-width: 576px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: none; /* Hide all items except Items Menu */
      }

      .items-menu-button {
        display: flex; /* Show only the Items Menu button */
        justify-content: center;
        width: 100%;
        background-color: var( --charcoal-grey);
        color: var(--white);

        .menu-button {
          color: var(--white);
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .items-modal {
    display: block;
  }
}

@media (min-width: 768px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: none; /* Hide all items except Items Menu */
      }

      .items-menu-button {
        display: flex; /* Show only the Items Menu button */
        justify-content: center;
        width: 100%;
        background-color: var( --charcoal-grey);
        color: var(--white);

        .menu-button {
          color: var(--white);
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }

  .items-modal {
    display: block;
  }
}

@media (min-width: 992px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: flex; /* Show full navbar */
      }

      .items-menu-button {
        display: none; /* Hide Items Menu button on larger screens */
      }
    }
  }

  .items-modal {
    display: none; /* Hide Modal on larger screens */
  }
}

@media (min-width: 1200px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: flex; /* Show full navbar */
      }

      .items-menu-button {
        display: none; /* Hide Items Menu button on larger screens */
      }
    }
  }

  .items-modal {
    display: none; /* Hide Modal on larger screens */
  }
}

@media (min-width: 1400px) {
  .secondary-navbar {
    .navbar-menu {
      .menu-item {
        display: flex; /* Show full navbar */
      }

      .items-menu-button {
        display: none; /* Hide Items Menu button on larger screens */
      }
    }
  }

  .items-modal {
    display: none; /* Hide Modal on larger screens */
  }
}

.payment-bar-chart {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  .chart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  .custom-labels {
    display: flex;
    flex-direction: column;
    gap: 16px; /* Space between rows */
    margin-top: 10px;

    .custom-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .payment-type {
        flex: 1;
        font-size: 14px;
        color: #333;
        text-align: left;
        word-wrap: break-word;
      }

      .bar-container {
        flex: 2;
        height: 10px;
        background-color: #e0e0e0;
        border-radius: 5px;
        overflow: hidden;
        display: flex;
        align-items: center;

        .filled-bar {
          height: 100%;
          background-color: #73d2f1;
          border-radius: 5px;
          transition: width 0.3s ease;
        }
      }

      .payment-value {
        flex: 1;
        font-size: 14px;
        color: #333;
        text-align: right;
        white-space: nowrap;
      }
    }
  }

  /* Responsive styles */
  @media (max-width: 575.98px) {
    .custom-labels {
      gap: 10px; /* Slightly reduce the gap for smaller screens */
    }

    .custom-label {
      flex-direction: row; /* Maintain row layout for consistency */
      justify-content: space-between;

      .payment-type,
      .payment-value {
        font-size: 12px; /* Adjust font size for smaller screens */
      }

      .bar-container {
        height: 8px; /* Reduce bar height slightly */
      }
    }
  }

  @media (min-width: 576px) and (max-width: 768px) {
    .custom-labels {
      gap: 12px;
    }

    .custom-label {
      flex-direction: row;

      .payment-type,
      .payment-value {
        font-size: 13px;
      }

      .bar-container {
        height: 10px;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 992px) {
    .custom-labels {
      gap: 14px;
    }

    .custom-label {
      flex-direction: row;

      .payment-type,
      .payment-value {
        font-size: 14px;
      }

      .bar-container {
        height: 10px;
      }
    }
  }

  @media (min-width: 992px) {
    .custom-labels {
      gap: 16px;
    }

    .custom-label {
      flex-direction: row;

      .payment-type,
      .payment-value {
        font-size: 14px;
      }

      .bar-container {
        height: 10px;
      }
    }
  }
}

.add-item-page-section {
    padding: 20px;
    background-color: #f4f4f4;
  
    .add-item-page {
      background-color: var(--white);
      border-radius: 10px;
  
      .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
  
        .button-group {
          display: flex;
          gap: 10px;
          justify-content: flex-end;
          width: 100%;
        .items-button{
          padding:5px;
          background-color: var(--dark-blue);
          color: white;
          border:none !important;
          min-width: 140px;
        }
          .exit-button {
            padding:5px;
            background-color: var(--dark-blue);
            color: white;
            min-width: 140px;
          }
  
          .save-button {
            padding:5px;
            background-color: white;
            color: var(--dark-blue);
            min-width: 140px;
            border: 1px solid var(--dark-blue);
          }
  
          .back-button {
            padding:5px;
            background-color: white;
            color: var(--dark-blue);
            min-width: 140px;
            border: 1px solid var(--dark-blue);
          }
        }
      }
  
      .form-table {
        width: 100%;
        margin-top: 20px;
        border-collapse: collapse;
  
        th, td {
          padding: 3px;
          text-align: left;
        }
  
        th.table-header {
          background-color: #eeeeee;
          font-weight: normal;
        }
  
        td {
          vertical-align: middle;
  
          .no-margin-bottom {
            margin-bottom: 0 !important;
          }
  
          .checkbox-group {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
  
            input[type='checkbox'] {
              margin-right: 10px;
              border: 1px solid #38a169;
              background-color: #e6f9e8;
            }
  
            .item-text-group {
              font-weight: 600;
            }
          }
        }
      }
  
      .section-divider {
        margin: 30px 0;
        border: 0;
        border-top: 1px solid #e0e0e0;
      }
  
      .self-item-recipe {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        padding: 10px;
  
        .checkbox-group1 {
          display: flex;
          align-items: center; /* Aligns the checkbox and label vertically centered */
          gap: 10px;
  
          label {
            margin-top: 0;
            color: #6c757d;
          }
        }
  
        .item-text {
          padding-left: 0;
          margin-top: 10px;
          color: #6c757d;
        }
      }
    }
  
    /* Media Queries for min-width */
  
    @media (min-width: 1400px) {
      .button-group {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
  
        button {
          padding: 8px 16px;
        }
      }
    }
  
    @media (min-width: 1200px) {
      .button-group {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
  
        button {
          padding: 8px 16px;
        }
      }
    }
  
    @media (min-width: 992px) {
      .button-group {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
  
        button {
          padding: 8px 16px;
        }
      }
    }
  
    @media (min-width: 768px) {
      .form-table {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        display: block;
      }
  
      .self-item-recipe {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
  
        .checkbox-group1 {
          display: flex;
          align-items: center; /* Ensures vertical center alignment */
          gap: 10px;
  
          label {
            margin-top: 0;
            color: #6c757d;
          }
        }
  
        .item-text {
          margin-top: 10px;
          padding-left: 0;
          color: #6c757d;
        }
      }
    }
  
    @media (min-width: 576px) {
      .button-group {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
  
        button {
          padding: 8px 16px;
        }
      }
  
      .self-item-recipe {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
  
        .checkbox-group1 {
          display: flex;
          align-items: center; /* Ensures vertical center alignment */
          gap: 10px;
  
          label {
            margin-top: 0;
            color: #6c757d;
          }
        }
  
        .item-text {
          margin-top: 10px;
          padding-left: 0;
          color: #6c757d;
        }
      }
    }
  
    /* Media Queries for max-width */
  
    @media (max-width: 575.98px) {
      .button-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .exit-button, .save-button {
          width: calc(50% - 5px);
        }
  
        .back-button {
          width: 100%;
          margin-top: 10px;
        }
  
        button {
          padding: 6px 12px;
        }
      }
  
      .form-table {
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        display: block;
      }
  
      .self-item-recipe {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
  
        .checkbox-group1 {
          display: flex;
          align-items: center; /* Ensures vertical center alignment */
          gap: 10px;
  
          label {
            margin-top: 0;
            color: #6c757d;
          }
        }
  
        .item-text {
          margin-top: 10px;
          padding-left: 0;
          color: #6c757d;
        }
      }
    }
  }
  
.multi-select-dropdown {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    padding: 7px;
    border: 1px solid var(--border);
    border-radius: 4px;
    font-size: 14px;
    outline: none;

    &:focus {
      border-color: var(--dark-blue);
    }
  }

  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    .selected-item {
      display: flex;
      align-items: center;
      background: var(--whitesmoke);
      border: 1px solid var(--border);
      border-radius: 20px;
      padding: 5px 10px;
      font-size: 12px;

      .remove-button {
        background: none;
        border: none;
        color: var(--red);
        margin-left: 5px;
        font-weight: bold;
        cursor: pointer;

       
      }
    }
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    background: var(--white);
    border: 1px solid var(--border);
    border-radius: 4px;
    z-index: 10;
    padding: 0;
    margin: 5px 0 0;

    li {
      padding: 10px;
      font-size: 14px;
      cursor: pointer;
      list-style: none;
      transition: background 0.2s ease;

      &:hover {
        background: #f0f0f0;
      }

      &.selected {
        background: #e0e0e0;
        font-weight: bold;
        cursor: default;
      }
    }

    .no-options {
      text-align: center;
      color: #888;
      font-size: 14px;
      padding: 3px;
    }
  }
}

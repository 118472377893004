.details-page2 {
  display: flex; /* Use Flexbox to arrange the left and right sections */
  flex-direction: column; /* Default to column layout for smaller screens */
  padding: 20px;

  .left-section2 {
    margin: 0px;
    border-radius: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100vh; /* Set maximum height to control scrolling area */
    
    /* Hide scrollbar styles for various browsers */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }
    /* Common styles for the left section content */
    .cancelled-details2 {
      padding: 20px;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid #e0e0e0;
    }

    .cancelled-header2 {
      display: flex;
      align-items: center;
      background-color: #f8d7da;
      padding: 10px;
      border-radius: 8px;
      margin-bottom: 15px;
    }

    .cancelled-icon2 {
      width: 24px;
      margin-right: 10px;
    }

    .cancelled-status2 {
      color: #721c24;
      font-weight: bold;
    }

    .info2 {
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px 20px;
      margin-bottom: 15px;
    }

    .patient-details2 {
      margin: 0;
      color: var(--grey);
    }

    .line2 {
      border: none;
      border-top: 1px solid #ddd;
      margin: 15px 0;
    }

    .email-section2 {
      margin-bottom: 20px;
    }

    .email-details2 {
      margin: 0 0 5px;
    }

    .add-email-btn2 {
      margin-top: 5px;
      background-color: var(--dark-blue);
      color: var(--white);
      border: none;
      padding: 5px 10px;
      border-radius: 4px;
      cursor: pointer;
    }

    .ordered-items2 {
      margin-bottom: 20px;
    }

    .ordered-items-header2 {
      font-weight: bold;
      margin-bottom: 10px;
    }

    .details-box2 {
      margin-top: 20px;
    }

    .bordered-box2 {
      padding: 10px;
      border: 1px solid #e0e0e0;
      border-radius: 4px;
      margin-bottom: 10px;
      background-color: #fff;
    }

    .address-label2 {
      font-weight: bold;
      margin-right: 5px;
    }

    .arrow-icon2 {
      font-size: 12px;
      color: var(--dark-blue);
      margin-left: 5px;
    }

    .payment-mode2 {
      margin: 0;
    }

    .cancellation-bordered-box2 {
      background-color: #f0f4f8;
      color: var(--grey);
      padding: 10px;
      border-radius: 4px;
    }

    .order-cancel-details2 {
      margin: 5px 0;
    }
  }

  .right-section2 {
    width: 100%; /* Full width for column layout on smaller screens */
  }
}

/* Media queries for column arrangement on smaller screens */
@media (max-width: 575.98px) {
  .details-page2 {
    flex-direction: column; /* Set column layout for smaller screens */
    margin: 0px;

    .left-section2 {
      width: 100%;
      overflow-y: visible; /* Disable scrolling */
    }
    .right-section2 {
      width: 100%; /* Full width for each section */
    }
  }
}

@media (min-width: 576px) {
  .details-page2 {
    flex-direction: column; /* Set column layout for medium devices */
    margin: 0px;

    .left-section2 {
      width: 100%;
      overflow-y: visible; /* Disable scrolling */
    }
    .right-section2 {
      width: 100%; /* Full width for each section */
    }
  }
}

/* Media query for min-width: 768px */
@media (min-width: 768px) {
  .details-page2 {
    flex-direction: column; 

    .left-section2 {
      width: 100%;
      display: block;
      
    }
    .right-section2 {
      width: 100%; /* Full width for each section */
    }
  }
}

/* Media queries for row arrangement for larger screens */
@media (min-width: 992px) {
  .details-page2 {
    flex-direction: row; /* Row layout for large devices */
    min-height: 100dvh;
    -webkit-box-pack: center;
    margin: 0 50px;
    padding: 20px;


    .left-section2 {
      display: block; /* Ensure both sections are visible */
      width: 70%; /* Adjust width for larger screens */
      padding: 20px;
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    }

    .left-section2::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section2 {
      display: block; /* Ensure both sections are visible */
      width: 30%;
      background-color: white;
      padding: 20px;
    }
  }
}

@media (min-width: 1200px) {
  .details-page2 {
    flex-direction: row; /* Row layout for extra large devices */
    min-height: 100dvh;
    -webkit-box-pack: center;
    margin: 0 50px;
    padding: 20px;

    .left-section2 {
      display: block; /* Ensure both sections are visible */
      width: 70%;
      padding: 20px;
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none;/* Hide scrollbar in IE and Edge */
    }

    .left-section2::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section2 {
      display: block; /* Ensure both sections are visible */
      width: 30%;
      background-color: white;
      padding: 20px;
    }
  }
}

@media (min-width: 1400px) {
  .details-page2 {
    flex-direction: row; /* Row layout for even larger screens */
    min-height: 100dvh;
    margin: 0 50px;
    padding: 20px;

    .left-section2 {
      display: block; /* Ensure both sections are visible */
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    }

    .left-section2::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section2 {
      display: block;
    }
  }
}

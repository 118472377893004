.user-page {
  padding: 10px;
  background-color: var(--whitesmoke);

  .main-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  

  /* Left Section */
  .left-section {
    min-height: 100px; 

    .user-page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
     
      color: var(--grey);
    

    .controls {
      display: flex;
      align-items: center;
     

      
    }
  }

    .user-card-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;

      .card-value {
        font-size: 14px;
      }

      .user-card-footer {
        justify-content: space-between;
        display: flex;
      }
    }

    .no-sales-recorded {
      background-color: var(--white);
      border-radius: 8px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      text-align: center;
      margin-top: 20px;

     
    }
    .online-orders-card {
      background-color: var(--white);
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
  
      .tabs {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid var(--border-bottom);
        padding-bottom: 10px;
        margin-bottom: 10px;
  
        span {
         
          color: var(--grey);
          cursor: pointer;
  
          &.active-tab {
            color: var(--dark-blue);
            border-bottom: 2px solid var(--dark-blue);
            font-weight: bold;
          }
        }
      }
  
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
  
        .order-stats {
          display: flex;
          justify-content: space-between;
          width: 30%;
  
         
        }
  
        .controls {
          display: flex;
          align-items: center;
  
          .refresh-icon {
            font-size: 18px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
  
      .no-online-orders {
        background-color: var(--white);
        border-radius: 8px;
        text-align: center;
        margin-top: 20px;
        padding: 20px;
  
        .no-orders-icon {
          font-size: 48px;
          color: var(--very-light-grey);
        }
  
        p {
          margin-top: 10px;
          
          color: var(--grey);
        }
      }
    
  }
  .discounts-expenses-section {
   
    display: flex;
    gap: 20px;
    
    .card {
      background-color: var(--white);
      border-radius: 8px;
      padding: 20px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 20px;
      flex: 1;
      
      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
       
        .controls {
          display: flex;
          align-items: center;
          
          .refresh-icon {
            margin-left: 10px;
            font-size: 18px;
            cursor: pointer;
          }
        }
      }
      
      .no-discount,
      .expenses-info {
        text-align: center;
        
        .no-discount-icon,
        .expense-chart-icon {
          font-size: 48px;
          color: var(--very-light-grey);
        }
        
        p {
          margin-top: 10px;
          
          color: var(--grey);
        }
      }
      
      .expenses-info {
        .total-expense {
          margin-bottom: 20px;
          .total-expense-title{
            color:var(--black);   
            font-weight: normal;
          }
          span {
          
            font-weight: bold;
          }
        }
        
        .expense-categories {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          background-color: var(--white);
          
          .expense-category {
            display: flex;
            align-items: center;
            justify-content: space-between;
            
            color:var(--grey);
            
            .color-indicator {
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
            }
            
            .electricity { background-color: #00c49f; }
            .petty-cash { background-color: #ff8042; }
            .other { background-color: #0088fe; }
            .advertisement { background-color: #ffbb28; }
          }
        }
      }
      
      .view-link {
        text-align: right;
        a {
         
          color: #00c49f;
          text-decoration: underline;
        }
      }
    }
  }
  .product-sales-card {
   
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  
    .product-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h6 {
        margin: 0;
       
        font-weight: bold;
      }
  
      .controls {
        display: flex;
        align-items: center;
  
        .refresh-icon {
          margin-left: 10px;
          font-size: 18px;
          cursor: pointer;
        }
      
      }
    }
  
    .product-tabs {
      display: flex;
      justify-content: flex-start;
      margin-top: 10px;
  
      span {
        padding: 10px 20px;
        cursor: pointer;
        border-bottom: 2px solid transparent;
      }
  
      .active-tab {
        border-bottom: 2px solid var(--dark-blue);
        color: var(--dark-blue);
      }
    }
  
    .product-list {
      margin-top: 20px;
  
      .product-item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 16px;
        border-bottom: 1px solid #e0e0e0;
  
        &:last-child {
          border-bottom: none;
        }
      }
    }
  
    .product-note {
      margin-top: 15px;
      font-size: 12px;
      color: var(--very-light-grey);
      text-align: left;
    }
  }
  
  
  }

  /* Right Section (Order Statistics and Sales Details) */
  .right-section {

    padding:10px;
   
.order-statistics{
  padding:10px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    
  .order-statistics-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin-bottom: 20px;
  }
      
    .controls {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .refresh-icon {
        margin-left: 10px;
        font-size: 18px;
        cursor: pointer;
      }
    }

    .statistics-info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    
      color: var(--very-light-grey);

      strong {
        color:var(--grey);
      }
    }

  }
    /* Total Sales Card */
    .sales-details {
      padding:10px;
      background-color: var(--white);
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-top: 45px;
      .controls {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
  
        .refresh-icon {
          margin-left: 10px;
          font-size: 18px;
          cursor: pointer;
        }
      }

      .total-sales-row {
        display: flex;
        justify-content: space-between;
       
        margin-bottom: 15px;
      }

      .payment-types {
        .payment-type {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
         

          .progress-bar {
            background-color: #ddd;
            width: 100px;
            height: 10px;
            border-radius: 5px;
            margin: 0 10px;
          }

          span:last-child {
            font-size: 14px;
          }
        }
      }
    }
    .order-statistics{
      margin-top: 20px;
     
  }
  .taxes-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  
    h6 {
    
      font-weight: normal;
      margin-bottom: 20px;
    }
  
    .controls {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
  
      .refresh-icon {
        font-size: 18px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  
    .taxes-details {
     
      margin-bottom: 20px;
      
    }
  
    .tax-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      padding: 10px 0;
      border-bottom: 1px solid #eee;
  
      &:last-child {
        border-bottom: none;
      }
  
      span {
        font-size: 16px;
      }
  
      .tax-amount {
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 20px;
  
        &.yellow {
          background-color: #ffc107;
          color: #fff;
        }
  
        &.blue {
          background-color: #17a2b8;
          color: #fff;
        }
      }
    }
  }
  
}
  }



  /* Modal for Detailed Stats */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  

  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;

    .detailed-stats {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        border-bottom: 1px solid #eee;

        &:last-child {
          border-bottom: none;
        }
      }

      .total {
        background-color: #ffeef0;
        font-weight: bold;
      }
    }
  }
}
}

  /* Media Queries for Wide Screens (1400px and above) */
@media (min-width: 1400px) {
  .main-content {
    flex-direction: row;
    .left-section {
      width: 70%;
    }
    .right-section {
      width: 30%;
    }
  }
}

/* Media Queries for Large Screens (1200px to 1399px) */
@media (min-width: 1200px) {
  .main-content {
    flex-direction: row;
    .left-section {
      width: 70%;
    }
    .right-section {
      width: 30%;
    }
  }
}

/* Media Queries for Medium Screens (992px to 1199px) */
@media (min-width: 992px) {
  .main-content {
    flex-direction: row; /* Row layout */
    .left-section {
      width: 70%; /* Left section takes 70% */
      display: block; /* Display the left section */
    }
    .right-section {
      width: 30%; /* Right section takes 30% */
      display: block; /* Display the right section */
    }
  }
}

/* Media Queries for Tablet Screens (768px to 991px) */
@media (min-width: 768px) and (max-width: 991px) {
  .main-content {
    flex-direction: column; /* Column layout */
    width: 100%; /* Full width */
    .left-section,
    .right-section {
      width: 100%; /* Each section takes full width */
      display: block; /* Ensure both sections are displayed */
    }
  }
}

/* Media Queries for Small Screens (Below 768px) */
@media (max-width: 767.98px) {
  .main-content {
    flex-direction: column; /* Column layout */
    width: 100%; /* Full width */
    .left-section,
    .right-section {
      width: 100%; /* Each section takes full width */
      display: block !important; /* Ensure both sections are displayed */
    }
  }
}

/* Media Queries for Extra Small Screens (Below 576px) */
@media (max-width: 575.98px) {
  .main-content {
    flex-direction: column;
    .left-section {
      width: 100%;
      .user-page-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        color: #666;
      .clock-icon {
        display:none;
      }
     
      .controls {
        display: flex;
        flex-direction: column; /* Align children vertically */
        align-items: center;
      
        .dropdown {
          margin-bottom: 10px; /* Add spacing between dropdown and date input */
        }
      
        .date-input {
          display: flex;
        }
      
        .refresh-icon {
          margin-left: 10px;
          font-size: 18px;
          cursor: pointer;
        }
      }
      
    }
      .user-card-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr) !important;
        gap: 20px;
      }
      .discounts-expenses-section {
        flex-direction: column;
      }
    }
    .right-section {
      width: 100%;
    }
  }
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
    --dark-blue: #0071BD; /* Define your primary color */
    --dark-grey: #40464D; /* Define your secondary color */
    --grey: #4F585E; /* Define your accent color */
    --light-grey: #6C7789;
    --black:#000000;
    --white:#fff;
    --green:#28a745;
    --light-green:#d4f1d4;
    --pale-grey:#f1f4f9;
    --sky-blue:#e9f6ff;
    --border-line:#ddd;
    --red:#f70808;
    --secondary-nav-color: #ddeff8;
    --whitesmoke: #F5F5F5; 
    --fade-grey: #aaa;  //breadcrumb separator color
   --border-bottom: #ddd;
   --th:#dedede;
   --show-all: #f0f0f0;
   --border:#cdcdcd;
   --fade-black:#313131;
   --expired:#D4351C;
   --paused:#FFC107;
   --yellow:#ffc311;
   --fade-red:#721c24;
   --light-red:#f8d7da;
   --medium-grey:#888888;
   --golden-yellow:#f5c342;
   --light-blue:#73d2f1;
   --dim-grey:#616161;
   --pale-shy-blue:#cbe0f7;
   --very-light-blue:#ddeff8;
   --snowy-grey:#f8f9fa;
   --charcoal-grey:#565656;
    /* Add more color variables as needed */
  }
 
body{
    font-family: 'Poppins',sans-serif;
   
    h1 {
        font-size: 48px; /* Adjust h1 font size */
      }
      
      h2 {
        
        font-size: 36px; /* Adjust h2 font size */
      }
      
      h3 {
        font-size: 24px; /* Adjust h3 font size */
      }
      
      h4 {
        font-size: 20px; /* Adjust h4 font size */
      }
      
      h5 {
        font-size: 18px; /* Adjust h5 font size */
      }
      
      h6 {
        font-size: 16px; /* Adjust h6 font size */
      }
      
      p {
        font-size: 16px; /* Adjust paragraph font size */
      }
      
      span {
        font-size: 14px; /* Adjust span font size */
      }
      
      a {
        font-size: 14px; /* Adjust anchor font size */
      }
     li {
      font-size: 14px;
        
      }
      .label{
        font-size: 14px; 
      }
      .content-wrapper {
        padding-bottom: 60px; /* Adjust this value to the height of the BottomNavigation */
      }
}
/* Font sizes for specific elements */
//  media width

.container-cw{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  font-family: Poppins,sans-serif;
}

@media (min-width: 1600px) and (max-width: 1919px) {
  .container-cw {
      max-width: 1400px;
  }
}


@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .container-cw {
      max-width: 1300px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .container-cw {
      max-width: 1200px;
  }
}


// ----------------------font-size--------------------------------------------------

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  body{


  h1 {
    font-size: 28px; /* Adjust h1 font size */
  }
  
  h2 {
    
    font-size: 24px; /* Adjust h2 font size */
  }
  
  h3 {
    font-size: 20px; /* Adjust h3 font size */
  }
  
  h4 {
    font-size: 18px; /* Adjust h4 font size */
  }
  
  h5 {
    font-size: 18px; /* Adjust h5 font size */
  }
  
  h6 {
    font-size: 16px; /* Adjust h6 font size */
  }
  
  p {
    font-size: 16px; /* Adjust paragraph font size */
  }
  
  span {
    font-size: 14px; /* Adjust span font size */
  }
  
  a {
    font-size: 14px; /* Adjust anchor font size */
  }
  .label{
    font-size: 14px; 
  }
  li{
   font-size: 14px;
}
  }
}
// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) { 
  body{


  h1 {
    font-size: 28px; /* Adjust h1 font size */
  }
  
  h2 {
    
    font-size: 24px; /* Adjust h2 font size */
  }
  
  h3 {
    font-size: 20px; /* Adjust h3 font size */
  }
  
  h4 {
    font-size: 18px; /* Adjust h4 font size */
  }
  
  h5 {
    font-size: 18px; /* Adjust h5 font size */
  }
  
  h6 {
    font-size: 16px; /* Adjust h6 font size */
  }
  
  p {
    font-size: 16px; /* Adjust paragraph font size */
  }
  
  span {
    font-size: 14px; /* Adjust span font size */
  }
  
  a {
    font-size: 14px; /* Adjust anchor font size */
  }
  .label{
    font-size: 14px; 
  }
  li{
    font-size: 14px;
 }
}
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) { 
  body{


  h1 {
    font-size: 40px; /* Adjust h1 font size */
  }
  
  h2 {
    
    font-size: 28px; /* Adjust h2 font size */
  }
  
  h3 {
    font-size:18px; /* Adjust h3 font size */
  }
  
  h4 {
    font-size: 18px; /* Adjust h4 font size */
  }
  
  h5 {
    font-size: 18px; /* Adjust h5 font size */
  }
  
  h6 {
    font-size: 16px; /* Adjust h6 font size */
  }
  
  p {
    font-size: 16px; /* Adjust paragraph font size */
  }
  
  span {
    font-size: 14px; /* Adjust span font size */
  }
  .label{
    font-size: 14px; 
  }
  
  a {
    font-size: 14px; /* Adjust anchor font size */
  }
  li{
    font-size: 14px;
 }
}
 
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) { 
  li{
    font-size: 14px;
 }
 .label{
  font-size: 14px; 
}
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) { 
  body{


  h1 {
    font-size: 48px; /* Adjust h1 font size */
  }
  
  h2 {
    
    font-size: 36px; /* Adjust h2 font size */
  }
  
  h3 {
    font-size: 24px; /* Adjust h3 font size */
  }
  
  h4 {
    font-size: 20px; /* Adjust h4 font size */
  }
  
  h5 {
    font-size: 18px; /* Adjust h5 font size */
  }
  
  h6 {
    font-size: 16px; /* Adjust h6 font size */
  }
  
  p {
    font-size: 16px; /* Adjust paragraph font size */
  }
  
  span {
    font-size: 14px; /* Adjust span font size */
  }
  .label{
    font-size: 14px; 
  }
  
  a {
    font-size: 14px; /* Adjust anchor font size */
  }
  li{
    font-size: 14px;
 }
 }
}
// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  body{


  h1 {
    font-size: 48px; /* Adjust h1 font size */
  }
  
  h2 {
    
    font-size: 36px; /* Adjust h2 font size */
  }
  
  h3 {
    font-size: 24px; /* Adjust h3 font size */
  }
  
  h4 {
    font-size: 20px; /* Adjust h4 font size */
  }
  
  h5 {
    font-size: 18px; /* Adjust h5 font size */
  }
  
  h6 {
    font-size: 16px; /* Adjust h6 font size */
  }
  
  p {
    font-size: 16px; /* Adjust paragraph font size */
  }
  
  span {
    font-size: 14px; /* Adjust span font size */
  }
  
  a {
    font-size: 14px; /* Adjust anchor font size */
  }
  .label{
    font-size: 14px; 
  }
  li{
    font-size: 14px;
 }
}
}


.modal-title{
  margin-bottom: 0px;
    font-weight: 500;
}
.add-items-modal-body {
    text-align: center;
    padding: 10px;

    .modal-buttons{
     display: flex;
     justify-content: center;

    }
  
    .grid-button, .sheet-button {
      background-color: var(--dark-blue); // Use your desired color
      color:var(--white);
      padding: 0 30px;
      border-radius: 5px;
      cursor: pointer;
      border:none;
    }
  
    .modal-footer-text {
      color: var(--grey);
      margin-top: 20px;
      align-items: flex-start;
      text-align:left !important;
      font-size: 12px;
    }
  }
  
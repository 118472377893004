.my-profile {
padding:20px;
overflow-y: auto;

.breadcrumb-link {
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
    color: var(--dark-blue); /* Apply the dark blue color */
    text-decoration: none; /* Remove underline *
    margin-right: 5px; /* Add some spacing */
  }
  
  
    .user-info {
      position: relative; /* Establish a positioning context */
      background-color: #e0f7ff; /* Light blue background */
      padding: 20px;
      border-radius: 5px;
      margin-bottom: 20px;
  
      .user-details {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding:10px;
        color:var(--grey);


      }
  
      .edit-link {
        position: absolute; /* Position the Edit link */
        top: 10px;
        right: 10px;
        color:var(--dark-blue);
        font-weight: bold;
        text-decoration: none;
        cursor: pointer;
      }
    }
  
    .profile-nav {
      margin-bottom: 20px;
    }
  
    .add-address-btn {
      display: block;
      margin: 10px 0;
      padding: 10px;
      background-color: var(--white);
      border: 1px solid #007bff;
      color: var(--dark-blue);
      border-radius: 4px;
      cursor: pointer;
      text-align: center;
  
      &:hover {
        background-color: #f2f4f7;
      }
    }
  
    .address-details {
      .address-card {
        background-color: #f8f9fa;
        padding: 15px;
        border-radius: 5px;
        margin-bottom: 10px;
  
        .address-row {
          display: flex;
          align-items: center; /* Align items vertically in the center */
          justify-content: space-between; /* Space elements evenly */
          margin-top: 10px;
  
          .address-paragraph {
            color:var(--grey);
            margin: 0;
            flex: 1; /* Allow the paragraph to take available space */
          }
  
          .address-link {
            margin-left: 10px;
            color: #007bff;
            text-decoration: none;
            cursor: pointer;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 50%; 
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: transform 0.3s ease-in-out; 
  transform: translateX(100%); 
  overflow-y: auto; 

  &.open {
    display: block; /* Show sidebar when open */
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.sidebar .sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #104a99;
  color: white;
}

.sidebar .sidebar-header h3 {
  margin: 0;
}

.sidebar .sidebar-header .close-icon {
  cursor: pointer;
  color: white;
  font-size: 20px;
}

.sidebar .sidebar-content {
  padding: 10px;
}

/* Media Query for screen width between 992px and 1200px */
@media (min-width: 992px) and (max-width: 1200px) {
  .sidebar {
    width: 50%; /* Half width */
  }
}

/* Media Query for screen width above 1200px */
@media (min-width: 1201px) {
  .sidebar {
    width: 50%; /* Half width */
  }
}

@media (max-width: 991px) {
  .sidebar {
    width: 100%; /* Full width for smaller screens */
  }
}



.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

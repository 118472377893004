.bulk-discount-main-page{
  padding: 20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
      display: flex;
      align-items: center;
      padding: 10px ;
      font-size: 16px;
      color: var(--grey);
     
    background-color: var(--white);
  
    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }
  
    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none; // Remove underline
      color: inherit;
  &:hover {
    text-decoration: none; // Ensure no underline on hover
   
  }
    }
  
    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--fade-grey);
    }
    }
    .bulk-discount-container{

      padding:5px;
      border-radius: 10px;
  
      background-color: var(--whitesmoke);
    
}
.bulk-discount {
  padding: 20px ;
  background-color: var(--white);
  border-radius: 10px;


.offer-table {
  background-color: var(--white);
      border-radius: 10px;
    
      .header-row {
        display: flex;
        justify-content: space-between; /* Push items to opposite corners */
        align-items: center;
       
      }
    
      .text-heading1 {
          padding: 10px !important;
         
          text-align: left !important;
          align-self: flex-start; /* If parent is flexbox */
      }
    
      .add-new-button {
        display: flex;
        align-items: center;
        gap: 15px;
    
        .save-button {
          background-color: var(--dark-blue); /* Use brand-specific dark-blue color */
          color: var(--white);
          border: none;
          border-radius: 4px;
          padding: 7px 15px;
          cursor: pointer;
          font-weight: bold;
          transition: background-color 0.3s ease;
    
          &:disabled {
            background-color: var(--grey); /* Optional: Indicate disabled state */
            cursor: not-allowed;
          }
        }
      }
          
}

.table-container {
  margin-top: 40px;
  overflow-x: auto; /* Enable horizontal scrolling for wide tables */
  overflow-y: hidden; /* Disable vertical scrolling */
  height: auto; /* Allow dynamic height */
  white-space: nowrap; /* Prevent text wrapping */
  -ms-overflow-style: auto; /* Ensure scrollbars in IE and Edge */
  scrollbar-width: auto; /* Show scrollbar for Firefox */

  &::-webkit-scrollbar {
    height: 8px; /* Add height for horizontal scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background: var(--whitesmoke);  /* Thumb color */
    border-radius: 4px; /* Rounded thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--very-light-grey); /* Darker thumb color on hover */
  }

  .custom-table {
    height: auto;
    width: 100%; /* Full width of the container */
    border-collapse: collapse;
    table-layout: auto; /* Allow dynamic column resizing */

    thead {
      th {
        position: sticky; /* Makes the header sticky */
        top: 0; /* Fix the header to the top of the container */
        z-index: 2; /* Ensure the header is above the body rows */
        background-color: var(--th); /* Header background color */
        padding: 12px;
        text-align: center;
        border-bottom: 2px solid var(--border-bottom);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
      }
    }

    tbody {
      td {
        padding: 12px;
        text-align: center;
        border-bottom: 1px solid var(--border-bottom);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
        height: 50px; /* Uniform row height */
        box-sizing: border-box;
        white-space: nowrap; /* Prevent text wrapping */
      }

      .actions1 {
        text-align: center;
      }

      td input {
        padding: 10px;
        width: 100%;
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        outline: none;
        font-family: 'Poppins', sans-serif;
      }

      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 10px;
      }
    }
  }

  .icon-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: var(--grey);

    &:hover {
      color: var(--black);
    }
  }
}

  .button-row {
      display: flex;
      justify-content: flex-end; /* Aligns the button row to the right */
      align-items: center;
      margin-top: 20px;
      gap: 15px; /* Adds spacing between controls */

      .next-button {
          background-color: var(--dark-blue); /* Use dark-blue color for the Add Item button */
          color: var(--white);   
          border: none;
          border-radius: 4px;
          cursor: pointer;
      }
  }
}
}
@media (max-width: 575.98px) {
 
  }
.personal-detail-offcanvas {
  border-radius: none;

  .offcanvas-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .back-button {
      background: none;
      border: none;
      font-weight: 500;
      color: #000;
      cursor: pointer;
      font-size: 18px;
    }

    .btn-close {
      background: none;
      border: none;
    }
  }

  .offcanvas-body {
    padding: 20px;

    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px; // Add spacing between form groups

      label {
        margin-bottom: 10px;
        color: #333; // Ensure the label text is dark and visible
        font-weight: 500;
      }

      input {
       
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
      }

      .radio-group {
        display: flex; // Arrange radio buttons in a single line
        justify-content: flex-start; // Align to the left
        align-items: center; // Center-align radio buttons and labels vertically
        gap: 15px; // Add space between radio buttons
      
      }

      .radio-option {
        display: flex;
        align-items: center;
        gap: 5px; // Add space between the radio button and its label
      }

      .radio-label {
        color: #333; // Ensure radio button labels are visible
        font-weight: 500;
        font-size: 14px;
      }
    }

    .save-button-container {
      display: flex;
      align-items: center;
      justify-content: center; // Center align the button horizontally
      margin-top: 20px; // Add margin for spacing

      .save-button {
        background-color: #0d6efd;
        color: white;
        border: none;
        padding: 15px; // Increase padding for better visibility
        font-size: 16px;
        cursor: pointer;
        border-radius: 5px;
        transition: background-color 0.3s;
        width: 100%; // Set button width to full width
        max-width: 300px; // Set maximum width for larger screens
        text-align: center;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
}

// Media query for small screens (max-width: 575.98px)
@media (max-width: 575.98px) {
  .offcanvas-body {
    .radio-group {
      flex-direction: row; // Keep radio buttons in a row even for small screens
      flex-wrap: wrap; // Allow wrapping if space is constrained
      gap: 10px; // Adjust spacing for small screens
    }

    .save-button-container {
      .save-button {
        width: 100%; // Ensure button spans full width
        max-width: none; // Remove max-width constraint
      }
    }
  }
}

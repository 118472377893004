.add-order {
    background-color: var(--whitesmoke);
    padding: 20px;
    .breadcrumb-container {
        display: flex;
        align-items: center;
        padding: 10px ;
        font-size: 16px;
        color: var(--grey);
       
      background-color: var(--white);
    
      .breadcrumb-icon {
        color: var(--grey);
        margin-right: 8px;
      }
    
      .breadcrumb-text {
        margin: 0 5px;
        text-decoration: none; // Remove underline
        color: inherit;
    &:hover {
      text-decoration: none; // Ensure no underline on hover
     
    }
      }
    
      .breadcrumb-separator {
        margin: 0 5px;
        color: var(--fade-grey);
      }
      }
      .add-order-list-container{
        padding:5px;
        border-radius: 10px;
    
        background-color: var(--whitesmoke);
      }
    .add-order-page {
        padding: 20px;
        background-color: var(--white);
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    

    .add-order-header-container {
        border: 1px solid var(--border);
        padding: 10px;
        border-radius: 10px;

        .add-order-header {
            font-weight: bold;
            margin-bottom: 20px;
        }

        .add-order-form {
            .form-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                gap: 15px;
            }

            .form-group {
                display: flex;
                flex-direction: column;
                width: 48%;
            }


  
        }
    }

    .item-details {
        background-color: var(--white);
        border: 1px solid var(--border);
        padding: 10px;
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--border);

            .add-order-header1 {
                font-weight: bold;
                margin: 0;
            }

            .action-buttons {
                display: flex;
                gap: 15px;

                .add-new-button1 {
                    display: flex;
                    align-items: center;
                    background-color: var(--white);
                    color: var(--grey);
                    border-radius: 4px;
                    border: 1px solid var(--border);
                }

                .more-actions-button {
                    background-color: avr(--white);
                    border: 1px solid var(--border);
                    color: var(--grey);
                    padding: 6px 12px;
                    font-size: 14px;
                    border-radius: 4px;
                }
            }
        }

        .table-bordered {
            width: 100%;
            margin-top: 20px;
            border-collapse: collapse;
            padding: 5px;

            th, td {
                padding: 5px;
                border-bottom: 1px solid var(--border-bottom);
            }

            th {
                background-color: var(--th);
                text-align: left;
            }

            .table-input {
                border: none;
                border-bottom: 1px solid var(--border-bottom);
                width: 100%;
            }

        }

       

        .footer-buttons {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            padding: 10px;
          

            .btn-cancel {
                background-color: var(--white);
                border: 1px solid var(--dark-blue);
                color: var(--dark-blue);
                padding: 6px;
                border-radius: 4px;
            }

            .btn-save {
                background-color: var(--dark-blue);
                color: var(--white);
                padding: 10px 16px;
                border-radius: 4px;
            }
        }
    }
    }
    // Media queries for responsiveness
    @media (max-width: 1400px) {
        
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
    }

    @media (max-width: 1200px) {
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
    }

    @media (max-width: 992px) {
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
        
    }

    @media (max-width: 768px) {
        .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }
        .table-bordered {
            width: 100%; /* Ensures the table takes up the full width */
            margin-top: 20px; /* Adds space above the table */
            border-collapse: collapse; /* Prevents double borders */
            box-sizing: border-box; /* Includes padding and borders in width calculation */
            overflow-x: auto; /* Enables horizontal scrolling */
            display: block; /* Allows horizontal scrolling for the table */
          
            th, td {
              padding: 10px; /* Adds consistent spacing */
              border-bottom: 1px solid #ddd; /* Adds a border below each row */
              text-align: center; /* Aligns content to the center */
              white-space: nowrap; /* Prevents text wrapping or shrinking */
            }
          
            th {
              background-color: #ececec; /* Header background color */
              font-weight: bold; /* Ensures bold headers */
              text-align: center; /* Aligns header text to the center */
              white-space: nowrap; /* Prevents shrinking or wrapping */
            }
          
            .table-input {
              border: none;
              border-bottom: 1px solid #ddd;
              width: 100%; /* Ensures input fields take full width of the cell */
            }
          
            .empty {
              background-color: #f1f1f1; /* Sets a background color for empty rows */
            }
          }
          
          /* Custom Scrollbar Styling */
          .table-bordered::-webkit-scrollbar {
            height: 8px; /* Height of the horizontal scrollbar */
          }
          
          .table-bordered::-webkit-scrollbar-thumb {
            background-color: #888; /* Scrollbar thumb color */
            border-radius: 4px; /* Rounded corners */
          }
          
          .table-bordered::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Darker color on hover */
          }
          
          .table-bordered::-webkit-scrollbar-track {
            background: #f1f1f1; /* Scrollbar track background color */
          }
          
    }

    @media (max-width: 576px) {
        .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }
        .table-bordered {
            width: 100%; /* Ensures the table takes up the full width */
            margin-top: 20px; /* Adds space above the table */
            border-collapse: collapse; /* Prevents double borders */
            box-sizing: border-box; /* Includes padding and borders in width calculation */
            overflow-x: auto; /* Enables horizontal scrolling */
            display: block; /* Allows horizontal scrolling for the table */
          
            th, td {
              padding: 10px; /* Adds consistent spacing */
              border-bottom: 1px solid #ddd; /* Adds a border below each row */
              text-align: center; /* Aligns content to the center */
              white-space: nowrap; /* Prevents text wrapping or shrinking */
            }
          
            th {
              background-color: #ececec; /* Header background color */
              font-weight: bold; /* Ensures bold headers */
              text-align: center; /* Aligns header text to the center */
              white-space: nowrap; /* Prevents shrinking or wrapping */
            }
          
            .table-input {
              border: none;
              border-bottom: 1px solid #ddd;
              width: 100%; /* Ensures input fields take full width of the cell */
            }
          
            .empty {
              background-color: #f1f1f1; /* Sets a background color for empty rows */
            }
          }
          
          /* Custom Scrollbar Styling */
          .table-bordered::-webkit-scrollbar {
            height: 8px; /* Height of the horizontal scrollbar */
          }
          
          .table-bordered::-webkit-scrollbar-thumb {
            background-color: #888; /* Scrollbar thumb color */
            border-radius: 4px; /* Rounded corners */
          }
          
          .table-bordered::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Darker color on hover */
          }
          
          .table-bordered::-webkit-scrollbar-track {
            background: #f1f1f1; /* Scrollbar track background color */
          }
          
    }

    // Apply column layout for screens 575.98px and below
    @media (max-width: 575.98px) {
        .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }

        .header {
            .add-order-header1 {
                margin-bottom: 5px;
            
            flex-direction: column !important;
            align-items: center;
            overflow: hidden;
            }
        }

        .table-bordered {
            width: 100%; /* Ensures the table takes up the full width */
            margin-top: 20px; /* Adds space above the table */
            border-collapse: collapse; /* Prevents double borders */
            box-sizing: border-box; /* Includes padding and borders in width calculation */
            overflow-x: auto; /* Enables horizontal scrolling */
            display: block; /* Allows horizontal scrolling for the table */
          
            th, td {
              padding: 10px; /* Adds consistent spacing */
              border-bottom: 1px solid #ddd; /* Adds a border below each row */
              text-align: center; /* Aligns content to the center */
              white-space: nowrap; /* Prevents text wrapping or shrinking */
            }
          
            th {
              background-color: #ececec; /* Header background color */
              font-weight: bold; /* Ensures bold headers */
              text-align: center; /* Aligns header text to the center */
              white-space: nowrap; /* Prevents shrinking or wrapping */
            }
          
            .table-input {
              border: none;
              border-bottom: 1px solid #ddd;
              width: 100%; /* Ensures input fields take full width of the cell */
            }
          
            .empty {
              background-color: #f1f1f1; /* Sets a background color for empty rows */
            }
          }
          
          /* Custom Scrollbar Styling */
          .table-bordered::-webkit-scrollbar {
            height: 8px; /* Height of the horizontal scrollbar */
          }
          
          .table-bordered::-webkit-scrollbar-thumb {
            background-color: #888; /* Scrollbar thumb color */
            border-radius: 4px; /* Rounded corners */
          }
          
          .table-bordered::-webkit-scrollbar-thumb:hover {
            background-color: #555; /* Darker color on hover */
          }
          
          .table-bordered::-webkit-scrollbar-track {
            background: #f1f1f1; /* Scrollbar track background color */
          }
          
    }
}

.custom-quantity-input {
    display: flex;
    align-items: center;
  
    .buttoncustom {
      background-color: var(--dark-blue) !important; // Force override with !important
      color: var(--white) !important;
      border: none !important;    
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
  
      &:hover {
        background-color: var(--dark-blue) !important; // Darker blue on hover
      }
  
      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  
    .input-field {
      max-width: 50px;
     
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 0 !important;
      background-color: var(--white);
    }
  }
  
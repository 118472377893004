.App {
  display: flex;
  flex-direction: column;
  height: 100%; 
  overflow: hidden; 
}

.primary-navbar {
  position: fixed; /* Ensures the navbar stays fixed at the top */
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Keeps it above other elements */
  height: 70px; /* Set the navbar height */
  background-color: #23384d; /* Optional background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for visibility */
}

.content-container {
  height: calc(100vh - 70px); /* Subtracts navbar height from viewport height */
  overflow-y: auto; /* Enables vertical scrolling within the content area */
  box-sizing: border-box; /* Ensures padding doesn't affect height calculation */

  /* Hide scrollbar for Webkit-based browsers (e.g., Chrome, Safari) */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.content-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Webkit browsers */
}

.content-container.with-sidebar {
  margin-left: 50px; /* Adjust for the sidebar width */
  transition: margin-left 0.3s ease;
}

.sidebar {
  width: 250px;
  height: calc(100vh - 50px);
  position: fixed;
  top: 70px; /* Below the navbar */
  left: 0;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 999; /* Keeps it above the content */
}


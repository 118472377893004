.shop-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  text-align: center;
  padding: 16px;
  box-sizing: border-box;
  height: 100%; /* Uniform height for all cards */
  overflow: hidden;

  .shop-card-image {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin-bottom: 10px; /* Consistent spacing below the image */
  }

  .shop-card-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin: 8px 0; /* Adjust margin for uniform spacing */
    line-height: 1.2;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .shop-card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align to the top */
    gap: 8px; /* Add spacing between content elements */
    width: 100%;
  }

  .shop-card-footer {
    margin-top: auto; /* Push footer to the bottom */
    text-align: center;
  }
}

/* Responsive Adjustments */
@media (max-width: 575.98px) {
  .shop-card {
    width: 100%;
    padding: 12px; /* Reduce padding on smaller screens */
  }

  .shop-card-title {
    font-size: 14px; /* Smaller font size */
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .shop-card {
    width: calc(50% - 16px); /* Two cards per row */
  }
}

@media (min-width: 768px) {
  .shop-card {
    width: calc(33.333% - 16px); /* Three cards per row */
  }
}

@media (min-width: 992px) {
  .shop-card {
    width: calc(25% - 16px); /* Four cards per row */
  }
}

.subscribed-product-offcanvas {
    border-radius: 0 !important;
    .offcanvas {
      border-radius: 0 !important; /* Remove border-radius for the entire offcanvas */
    }
  
    .offcanvas-header {
      border-bottom: 1px solid var(--border);
      h5 {
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  
    .offcanvas-body {
        border-radius: 0 !important;
      .filter-options {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-top: 20px;
        border-radius: 0 !important;

        .filter-option {
          display: flex;
          align-items: center;
  
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 1rem;
            color: var(--grey);
  
            input[type="radio"] {
              appearance: none;
              background-color: var(--white);
              border: 1.5px solid var(--dark-blue);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              position: relative;
              cursor: pointer;
  
              &:checked {
                background-color: var(--dark-blue);
                &:before {
                  content: '';
                  position: absolute;
                  width: 10px;
                  height: 10px;
                  background-color: var(--white);
                  border-radius: 50%;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
    }
  }
  
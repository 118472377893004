.subscription-details-page1 {
  /* Use Flexbox to arrange the left and right sections in a row */
  flex-direction: row; /* Ensure the layout is in a row */
  gap: 20px; /* Add spacing between left and right sections */
  padding: 20px;


  .subscription-container{
  display: flex; /* Flexbox to arrange sections side by side */
  
  align-items: flex-start;
  }
  .left-section1 {
    flex: 2; /* Take two-thirds of the available width */
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    overflow-y: auto; /* Scroll if content overflows */
    max-height: 100vh;  /* Enable vertical scrolling */
    
    /* Hide scrollbar styles for various browsers */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .subscription-cancelled-details1 {
      background-color:var(--white);
      border-radius: 8px;
      border: 1px solid var(--border);
      padding: 20px;
      .subscription-main-header{
        margin-bottom:10px;
        color:var(--grey)
      }
      

      .subscription-detail-info1 {
        display: flex;
        justify-content: space-between; /* Ensures items are spaced out */
       /* Align items vertically in the center */
        gap: 5px; /* Space between the columns */
        margin-bottom: 15px;
      
        .subscription-order-details1,
        .subscription-order-details2 {
          font-size: 14px;
          color: var(--grey);
          margin: 0; /* Remove margin for proper alignment */
          white-space: nowrap; /* Prevent text from wrapping */
        }
      }
      
      .subscription-line1 {
        border: none;
        border-top: 1px solid var(--border);
        margin: 15px 0;
      }

      .subscription-ordered-items1 {
        margin-bottom: 20px;
      }
    }
    
   
    

    .subscription-details-box2 {
      padding: 10px;
      border: 1px solid var(--border);
      border-radius: 6px;
      margin-bottom: 10px;
      background-color: var(--white);
      margin-top: 10px;
    
      .subscription-bordered-box2 {
        padding: 15px;
       
        border-radius: 4px;
      
    
        .subscription-address-details2 {
         
          font-weight: bold;
          margin-bottom: 8px;
        }
    
        .subscription-address-label2 {
         
         
          color: var(--black);
        }
    
        p {
          
          color: var(--grey);
          margin: 5px 0;
        }
      }
    }
    .new-card1 {
      width: 100%;
      max-width: 800px;
      border: 1px solid var(--border);
      border-radius: 10px;
      background-color: var(--white);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
      margin-top:10px;
    
      .card-header1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .product-name1 {
          font-weight: bold;
          color: var(--grey-dark);
         
        }
      }
    
      .card-body1 {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 0px;
        .subscription-action-buttons {
          display: flex;
          gap: 10px;
          margin-top: 15px;
        
          button {
            padding: 8px 12px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
          }
        
          .btn-pause {
            background-color: #f0ad4e;
            color: white;
          }
        
          .btn-cancel {
            background-color: #d9534f;
            color: white;
          }
        
          .btn-resume {
            background-color: #5cb85c;
            color: white;
          }
        
          button:hover {
            opacity: 0.9;
          }
        }
        
        p {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          margin-bottom: 0px;
          color: var(--grey);
    
          .label {
            font-weight: bold;
            color: var(--fade-black);
            margin-bottom: 0px;
          }
    
          .value {
            font-weight: normal;
            color: var(--grey);
            margin-bottom: 0px;
          }
        }
    
        .time-dates-row1 {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: 10px 0;
    
          .time-slots1 {
            display: flex;
            align-items: center;
            gap: 5px;
    
            .label {
              font-weight: bold;
             
            }
    
            .value {
              font-weight: normal;
              color: var(--grey);
            }
          }
    
          .months-section {
            display: flex;
            align-items: center;
            gap: 5px;
    
            .label {
              font-weight: bold;
            }
    
            .value {
              font-weight: normal;
              color: var(--grey);
            }
          }
        }
    
        .selected-days-row1 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 20px;
          margin-bottom:10px;

    
          .days-section1,
          .selected-dates1 {
            display: flex;
            align-items: center;
            gap: 10px;
    
            .label {
              font-weight: bold;
              color: var(--fade-black);
            }
    
            .days-container1,
            .dates-container1 {
              display: flex;
              gap: 8px;
    
              .day1,
              .date1 {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25px;
                height: 25px;
                background-color: var(--fade-grey);
                color: var(--grey);
                font-weight: bold;
                border-radius: 50%;
                text-align: center;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    
  }

  .right-section1 {
    flex: 1; /* Take one-third of the available width */
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px;
    max-height: 600px;

    .subscription-card1 {
      padding: 15px;
      background-color: var(--white);
      border: 1px solid var(--border);
      border-radius: 8px;
      margin-bottom: 15px; /* Space between cards */
    
      .card-header-row {
        display: flex;
        justify-content: space-between; /* Align product name to left and status to right */
        align-items: center;
        margin-bottom: 10px;
      }
    
      .subscription-status-badge1 {
        color: var(--white);
        padding: 5px 10px;
        border-radius: 8px;
        font-weight: bold;
      }
    
      .product-name {
        font-weight: bold;
        color: var(--grey);
      }
    
      label {
        display: block;
        margin: 5px 0;
        font-size: 14px;
        color: var(--grey-dark);
      }
    
      p {
        margin: 10px 0;
        color: var(--grey);
      }
    
      .subscription-card-footer {
        display: none; /* Remove Resume Button */
      }
    }
    
    
    
  }
}
@media (max-width: 575.98px) {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: column; /* Stack sections vertically */
    gap: 15px;
  }

  .left-section1{
    flex: none;
    width: 100%; 
    .subscription-cancelled-details1{ 
    .subscription-detail-info1 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .subscription-order-details1,
      .subscription-order-details2 {
        font-size: 14px;
        color: var(--grey);
          margin: 0; /* Remove margin for proper alignment */
          white-space: normal; /* Allow text to wrap */
            word-break:normal; /* Break long words into the next line */
            flex: 1 1 auto;
      }
  }}
  .new-card1{ 
    flex-wrap: wrap; /* Move items down when they exceed the width */
    display: flex;
    flex-direction: column;
  .card-body1 {
    display: flex;
    flex-direction: column; /* Stack items vertically */
   
    width: 100%;

    .time-dates-row1 {
      display: flex;
      flex-wrap: wrap; /* Allow wrapping of items */
      align-items: center; /* Center align the content */
      justify-content: space-between; /* Distribute items evenly */
      gap: 15px; /* Add spacing between sections */
      width: 100%; /* Ensure full width */

      .time-slots1 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start; /* Align items to the left */
        gap: 8px; /* Add spacing between items */

        .label {
          font-weight: bold;
          color: var(--fade-black);
          margin-right: 5px;
        }

        .value {
          color: var(--grey);
          margin-right: 5px;
        }
      }

      .months-section1 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 8px;

        .label {
          font-weight: bold;
          color: var(--fade-black);
          margin-right: 5px;
        }

        .value {
          color: var(--grey);
        }
      }
    }

    .selected-days-row1 {
      display: flex;
      flex-wrap: wrap; /* Allow wrapping of items */
      align-items: center;
      /* Align sections evenly */
      gap: 15px;

      .days-section1,
      .selected-dates1 {
        display: flex;
        align-items: center;
        gap: 10px;

        .label {
          font-weight: bold;
          margin-right: 5px;
          color: var(--fade-black);
        }

        .dates-container1,
        .days-container1 {
          display: flex;
          gap: 8px; /* Add spacing between items */
          flex-wrap: wrap; /* Allow items to wrap */
        }

        .day1,
        .date1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          background-color: var(--fade-grey);
          color: var(--grey);
          font-weight: bold;
          border-radius: 50%; /* Circular shape */
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
}
}
  .right-section1 {
    flex: none;
    width: 100%; /* Full width for smaller screens */
  }
}
}
@media (min-width: 576px)  {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: column; /* Stack sections vertically */
    gap: 15px;
  }

  .left-section1{
    flex: none;
    width: 100%; 
    .subscription-detail-info1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Algn content to the left */
    
      .subscription-order-details1,
      .subscription-order-details2 {
        justify-content: flex-start; /* Align items to the left */
        text-align: left; /* Ensure text alignment is left */
        color: var(--grey);
        margin: 0; /* Remove margin for proper alignment */
        white-space: normal; /* Allow text to wrap */
        word-break: break-word; /* Break long words onto the next line */
      }
    }
    
.new-card1{ 
  flex-wrap: wrap; /* Move items down when they exceed the width */
  display: flex;
  flex-direction: column;
.card-body1 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
 
  width: 100%;

  .time-dates-row1 {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of items */
    align-items: center; /* Center align the content */
    justify-content: space-between; /* Distribute items evenly */
    gap: 15px; /* Add spacing between sections */
    width: 100%; /* Ensure full width */

    .time-slots1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start; /* Align items to the left */
      gap: 8px; /* Add spacing between items */

      .label {
        font-weight: bold;
        color: var(--fade-black);
        margin-right: 5px;
      }

      .value {
        color: var(--grey);
        margin-right: 5px;
      }
    }

    .months-section1 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;

      .label {
        font-weight: bold;
        color: var(--fade-black);
        margin-right: 5px;
      }

      .value {
        color: var(--grey);
      }
    }
  }

  .selected-days-row1 {
    display: flex;
    flex-wrap: wrap; /* Allow wrapping of items */
    align-items: center;
    /* Align sections evenly */
    gap: 15px;

    .days-section1,
    .selected-dates1 {
      display: flex;
      align-items: center;
      gap: 10px;

      .label {
        font-weight: bold;
        margin-right: 5px;
        color: var(--fade-black);
      }

      .dates-container1,
      .days-container1 {
        display: flex;
        gap: 8px; /* Add spacing between items */
        flex-wrap: wrap; /* Allow items to wrap */
      }

      .day1,
      .date1 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        background-color: var(--fade-grey);
        color: var(--grey);
        font-weight: bold;
        border-radius: 50%; /* Circular shape */
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
}
  }
  .right-section1 {
    flex: none;
    width: 100%; /* Full width for this range */
  }
}
}
@media (min-width: 768px)  {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: column; /* Stack sections vertically */
    gap: 15px;
  }

  .left-section1{
    flex: none;
    width: 100%; 
    .subscription-detail-info1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align content to the left */
    
      .subscription-order-details1,
      .subscription-order-details2 {
        justify-content: flex-start; /* Align items to the left */
        text-align: left; /* Ensure text alignment is left */
        color: var(--grey);
        margin: 0; /* Remove margin for proper alignment */
        white-space: normal; /* Allow text to wrap */
        word-break: break-word; /* Break long words onto the next line */
      }
    }
    
    .new-card1{ 
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    .card-body1 {
      display: flex;
      flex-direction: column; /* Stack items vertically */
     
      width: 100%;

      .time-dates-row1 {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping of items */
        align-items: center; /* Center align the content */
        justify-content: space-between; /* Distribute items evenly */
        gap: 15px; /* Add spacing between sections */
        width: 100%; /* Ensure full width */

        .time-slots1 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start; /* Align items to the left */
          gap: 8px; /* Add spacing between items */

          .label {
            font-weight: bold;
            color: var(--fade-black);
            margin-right: 5px;
          }

          .value {
            color: var(--grey);
            margin-right: 5px;
          }
        }

        .months-section1 {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 8px;

          .label {
            font-weight: bold;
            color: var(--fade-black);
            margin-right: 5px;
          }

          .value {
            color: var(--grey);
          }
        }
      }

      .selected-days-row1 {
        display: flex;
        flex-wrap: wrap; /* Allow wrapping of items */
        align-items: center;
        /* Align sections evenly */
        gap: 15px;

        .days-section1,
        .selected-dates1 {
          display: flex;
          align-items: center;
          gap: 10px;

          .label {
            font-weight: bold;
            margin-right: 5px;
            color: var(--fade-black);
          }

          .dates-container1,
          .days-container1 {
            display: flex;
            gap: 8px; /* Add spacing between items */
            flex-wrap: wrap; /* Allow items to wrap */
          }

          .day1,
          .date1 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            background-color: var(--fade-grey);
            color: var(--grey);
            font-weight: bold;
            border-radius: 50%; /* Circular shape */
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }
  
}
  .right-section1 {
    flex: none;
    width: 100%; /* Full width for this range */
  }
}
}
@media (min-width: 992px) {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: row; /* Row layout */
    gap: 20px;
  }

  .left-section1 {
    flex: 2; /* Two-thirds width */
    .subscription-detail-info1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start; /* Align content to the left */
    
      .subscription-order-details1,
      .subscription-order-details2 {
        justify-content: flex-start; /* Align items to the left */
        text-align: left; /* Ensure text alignment is left */
        color: var(--grey);
        margin: 0; /* Remove margin for proper alignment */
        white-space: normal; /* Allow text to wrap */
        word-break: break-word; /* Break long words onto the next line */
      }
    }
    
  }

  .right-section1 {
    flex: 1; /* One-third width */
  }
}
}
@media (min-width: 1200px) {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: row; /* Row layout */
    gap: 20px;
  }

  .left-section1 {
    flex: 2; /* Two-thirds width */

    .subscription-detail-info1 {
      display: flex;
      flex-direction: row;
    
      .subscription-order-details1,
      .subscription-order-details2 {
        font-size: 14px;
        color: var(--grey);
        margin: 0; /* Remove margin for proper alignment */
        white-space: nowrap; /* Prevent text from wrapping */
      }
  }
  }

  .right-section1 {
    flex: 1; /* One-third width */
  }
}
}
@media (min-width: 1400px) {
  .subscription-details-page1 {
  .subscription-container {
    flex-direction: row; /* Row layout */
    gap: 20px;
  }

  .left-section1 {
    flex: 2; /* Two-thirds width */
    .subscription-detail-info1 {
      display: flex;
      flex-direction: row;
    
      .subscription-order-details1,
      .subscription-order-details2 {
        font-size: 14px;
        color: var(--grey);
        margin: 0; /* Remove margin for proper alignment */
        white-space: nowrap; /* Prevent text from wrapping */
      }
  }
    
  }

  .right-section1 {
    flex: 1; /* One-third width */
  }
}

}
.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  .nav-item {
    text-align: center;
    cursor: pointer;

    .nav-icon {
      font-size: 20px;
      margin-bottom: 5px;
      color:var(--dark-blue);
    }
    .nav-text{
      color:var(--dark-blue);
    }
    
  }
}

/* Media queries for different screen sizes */
@media (min-width: 992px) {
  .bottom-navigation {
    display: none; /* Ensure it remains visible */
  }
}

@media (min-width: 1200px) {
  .bottom-navigation {
    display: none; /* Ensure it remains visible */
  }
}

@media (min-width: 1400px) {
  .bottom-navigation {
    display: none; /* Ensure it remains visible */
  }
}

.admin-user-section {
    padding: 20px;
    background-color: var(--whitesmoke);
  
    .admin-user-form {
      padding: 20px;
      background-color: var(--white);
      border-radius: 10px;
  
      .form-title {
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 20px;
      }
  
      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
        flex-wrap: wrap;
  
        .form-group {
          display: flex;
          flex-direction: column;
          width: 100%;
  
          label {
            margin-bottom: 5px;
            font-weight: bold;
          }
  
          input {
            width: 100%;
            border-radius: 7px;
            border: 1px solid var(--border);
            font-family: 'Poppins', sans-serif;
          }
  
          .file-upload {
            padding: 6px;
            border-radius: 7px;
            border: 1px solid var(--border);
          }
        }
      }
  
      .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
  
        .submit-button {
          background-color: var(--dark-blue);
          color: var(--white);
          border: none;
          border-radius: 4px;
          cursor: pointer;
          padding: 10px ;
  
          &:hover {
            background-color: #083d73;
          }
        }
      }
     /* Media Queries for Responsive Design */
    @media (min-width: 1400px) {
        .form-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    
      @media (min-width: 1200px) {
        .form-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    
      @media (min-width: 992px) {
        .form-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    
      @media (min-width: 768px) {
        .form-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    
      @media (min-width: 576px) {
        .form-row {
          display: flex;
          flex-direction: row;
          gap: 10px;
          flex-wrap: nowrap;
        }
      }
    
      /* Stack fields in a column for small screens */
      @media (max-width: 575.98px) {
        .form-row {
          display: flex;
          flex-direction: column;
        }
    
        .form-group {
          margin-bottom: 15px;
        }
     
        .form-row1{
          width:100%;
        }
        .submit-button {
          width: 100%;
          margin: 0 auto; /* Center the button on small screens */
        }
      }
    }
}
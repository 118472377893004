.user-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  
    .user-card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      color: #333;
    }
  
    .card-icon {
      font-size: 24px;
      color: #ccc;
    }
  
    .user-card-body {
      margin-top: 10px;
  
      .card-value {
        font-size: 24px;
        font-weight: bold;
        color: #333;
      }
  
      .card-orders {
        font-size: 14px;
        color: #777;
      
      .dots-icon{
        cursor:pointer;
      }
    }
    }
  }


.admin-page-layout {
   
    padding: 10px;
    background-color: var(--whitesmoke);
 .admin-page-container{
  display: flex;
  flex-direction: row;
  flex-shrink: 0 !important;
  .left-section {
   width:20%;
    padding-right: 10px;
    flex-shrink: 0 !important;
  }
  
  .right-section {
    flex:1;
   overflow-y: auto;
   background-color: var(--white);
  
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex-shrink: 0 !important;
  
  
  .toolbar {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  
    .back-button {
      background-color: var(--white);
      padding: 6px 12px;
      border-radius: 4px;
      color: var(--grey);
      border: 1px solid var(--border);
      cursor: pointer;
      display: none;
    }

    .justify-button {
      background-color: var(--white);
      padding: 6px 12px;
      border-radius: 4px;
      color: var(--grey);
      border: 1px solid var(--border);
      cursor: pointer;
      display: none;
    }
  
    .search-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      gap: 5px;
      border: 1px solid var(--border);
      padding: 4px;
      border-radius: 4px;
  
      .icon {
        font-size: 14px;
        color: var(--medium-grey);
      }
  
      .search-bar {
        border: none;
        outline: none;
        padding-left: 5px;
      }
    }
  
  .save-button {
    background-color: var(--dark-blue); /* Dark blue Save button */
    color: var(--white);
    border: none;
   
  }
}

.item-controls {
    display: flex;
    align-items: center;
    gap: 15px; /* Adds spacing between controls */
  
  
    .add-items-button {
      background-color: var(--dark-blue); /* Use red color for Add Items button */
      color: var(--white);
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  
    .availability {
      display: flex;
      align-items: center;
      background-color: var(--white);
      border: 1px solid var(--border);
      border-radius: 4px;
      padding: 8px 12px;
      font-size: 14px;
    }
  
    .availability-indicator {
      width: 12px;
      height: 12px;
      background-color: var(--green); /* Available indicator */
      border-radius: 50%;
      margin-left: 8px;
    }
  }
  .search-filters {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
  
    .filter-row {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
  
      input, select {
        width: 100%;
        border: 1px solid var(--border);
        border-radius: 6px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
        outline: none;
  
        &:focus {
          border-color: #333; /* Highlight on focus */
        }
      }
  
      .form-field {
        flex: 1;
        min-width: 200px;
      }
    }
  
    .filter-actions {
      display: flex;
      justify-content: flex-start;
      gap: 15px;
      
      .search-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border-radius: 6px;
        cursor: pointer;
        border: none;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: darken(#3942e6, 10%);
        }
      }
  
      .reset-button {
        background-color: var(--white);
        color: var(--grey);
        border-radius: 6px;
        border: 1px solid var(--border);
        cursor: pointer;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 20px;
  
    .form-field {
      flex: 1;
      min-width: 200px;
    }
  }
  
  /* Specific styles for the search and reset buttons */
  button {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    cursor: pointer;
  }
  
  button.search-btn {
    background-color: var(--red);
    color: var(--white);
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
  }
  
  button.reset-btn {
    background-color: var(--white);
    color: var(--grey);
    border: 1px solid var(--border);
    padding: 12px 20px;
    border-radius: 6px;
  }
  
  .table-container {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .table-container::-webkit-scrollbar {
      display: none;
    }
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 40px;

    th,
    td {
      padding: 6px 6px;
      text-align: left;
      border-bottom: 1px solid var(--border-bottom);
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      color: var(--grey);
    }
   
    th {
      background-color: var(--th);
    }

    td input {
      padding:none !important;
      width: 100%;
      border: none;
      border-bottom: 1px solid var(--border-bottom);
      outline: none;
      font-family: 'Poppins', sans-serif;
    }
  }
  
    .icon-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--grey);
  
      &:hover {
        color:var(--black);
      }
    }
  
  }

  
  .showing-records-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ddeff8;
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 20px;
  }
  
  .showing-records {
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  .pagination-section {
    display: flex;
    justify-content: flex-end;
  }
  
  .pagination {
    .page-item {
      .page-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--dark-blue);
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin: 0 5px;
      }
  
      &.active .page-link {
        background-color: var(--dark-blue);
        color: white;
      }
  
      &.disabled .page-link {
        cursor: not-allowed;
        opacity: 0.5;
      }
  
      &:hover .page-link {
        background-color: #ddeff8;
        color: white;
      }
    }
  }
}
 }

/* For screens less than or equal to 575.98px */
@media (max-width: 575.98px) {

  overflow: hidden;
  
  flex-direction: column;

  .left-section {
    display: none !important; /* Hide left section */
  }

  .right-section {
    width: 100% !important;
     
    .toolbar {
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .back-button {
        display: none !important; /* Hide justify button */
      }

      .justify-button {
        display: inline-block; /* Show justify button on small screens */
      }

      .search-wrapper {
        flex: 1;
        max-width: 100%; /* Full width for search bar */
      }
    }
  }
  .search-filters{
    overflow-y: auto;
   width:100% !important;
   .filter-row {
    flex-direction: column; /* Stack fields vertically */
    justify-content: flex-start;

    input, select {
      width: 100%; /* Ensure full width on small screens */
    }
  }

  .filter-actions {
    flex-direction: column;
    align-items: flex-start;
    
    .search-button, .reset-button {
      width: 100%; /* Buttons take full width */
    }
  }

  }
  .table-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Disable vertical scrolling */
    white-space: nowrap; /* Prevent table from wrapping */
  }
  .showing-records-pagination {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;

    .showing-records {
      width: 100%;
      text-align: center; /* Center text */
    }

    .pagination-section {
      width: 100%;
      justify-content: center; /* Center pagination */
    }
  }

}

/* For screens greater than or equal to 576px */
@media (min-width: 576px) {
  
    flex-direction: column;
    align-items: center;

    .right-section {
      width: 100% !important;
      .toolbar {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .back-button {
          display: none !important; /* Hide justify button */
        }
  
        .justify-button {
          display: inline-block; /* Show justify button on small screens */
        }
  
  
        .search-wrapper {
          flex: 1;
          max-width: 100%; /* Full width for search bar */
        }
      }
    }
    .table-container {
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: hidden; /* Disable vertical scrolling */
      white-space: nowrap; /* Prevent table from wrapping */
    }
    .showing-records-pagination {
      flex-direction: column;
      gap: 10px;
      align-items: flex-start;
  
      .showing-records {
        width: 100%;
        text-align: center; /* Center text */
      }
  
      .pagination-section {
        width: 100%;
        justify-content: center; /* Center pagination */
      }
    }
  }
 

/* For screens greater than or equal to 768px */
@media (min-width: 768px) {
 
    flex-direction: column;

    .right-section {
      width: 100%;
      .toolbar {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .back-button {
          display: none !important; /* Hide justify button */
        }
  
        .justify-button {
          display: inline-block; /* Show justify button on small screens */
        }
  

        .search-wrapper {
          flex: 1;
          max-width: 300px; /* Limit width for larger screens */
        }
      }

      .showing-records-pagination {
        flex-direction: row;
        justify-content: space-between;

        .showing-records {
          width: auto;
          text-align: left;
        }

        .pagination-section {
          justify-content: flex-end;
        }
      }
    }
  }



/* For screens greater than or equal to 992px */
@media (min-width: 992px) {
 
    flex-direction: row;

    .left-section {
      width: 25%; /* Show left section */
    }

    .right-section {
      width: 100%;

      .toolbar {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        .back-button {
          display: inline-block !important; /* Hide justify button */
        }
  
        .justify-button {
          display:none !important; /* Show justify button on small screens */
        }
  
        .search-wrapper {
          flex: 1;
          max-width: 400px;
        }
      }

      .showing-records-pagination {
        flex-direction: row;
        justify-content: space-between;

        .showing-records {
          width: auto;
          text-align: left;
        }

        .pagination-section {
          justify-content: flex-end;
        }
      }
    }
  }


/* For screens greater than or equal to 1200px */
@media (min-width: 1200px) {
  .admin-page-layout {
    flex-direction: row;

    .left-section {
      width: 25%;
    }

    .right-section {
      width: 100%;
      .toolbar {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .back-button {
          display: inline-block !important; /* Hide justify button */
        }
  
        .justify-button {
          display:none !important; /* Show justify button on small screens */
        }
      }
    }
  }
}

/* For screens greater than or equal to 1400px */
@media (min-width: 1400px) {
  
    flex-direction: row;

    .left-section {
      width: 25%;
    }

    .right-section {
      width: 100%;

      .toolbar .justify-button {
        display: none; /* Hide justify button */
      }
      .toolbar .back-button {
        display: inline-block !important; /* Hide justify button */
      }
    }
  }
}

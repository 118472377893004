.admin-import-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: var(--whitesmoke);

  .import-form {
    background-color: var(--white);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%; /* Occupy full width */
    max-width: 100%; /* No width restriction */
    
    .form-row {
      display: flex;
      flex-direction: column; /* Stack elements vertically */
      margin-bottom: 20px;
      background-color: var(--white);
  

        .form-label {
          font-weight: 600;
          color:var(--grey);
          margin-bottom: 8px;
        }

        .form-control {
          border: 1px solid var(--border);
          border-radius: 6px;
          width: 100%; /* Occupy full width */
          outline: none;
          padding: 8px;
          font-size: 14px;
          background-color: var(--white);

          &:focus {
            border-color: var(--dark-blue);
            box-shadow: 0 0 4px rgba(0, 123, 255, 0.2);
          }
        }

        .file-info{
          display: flex;
          align-items: center;
          margin-top: 10px;

          span {
            margin-right: 10px;
            font-size: 14px;
            color: var(--grey);
          }

          .remove-btn {
            background-color: var(--red);
            color: var(--white);
            border: none;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 12px;

            &:hover {
              background-color: #c82333;
            }
          }
        }
      
    }

    .error-message {
      color: var(--red);
      font-size: 12px;
      margin-top: 5px;
    }

    .form-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border:none;

      .template-download-btn{
        color: var(--dark-blue);
        font-size: 14px;
        text-decoration: none;
        border:none;
        background-color: var(--white);

        &:hover {
          text-decoration: underline;
        }
      }

      .submit-btn {
        background-color: var(--dark-blue);
        color:var(--white);
        padding: 10px 20px;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-size: 14px;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  @media (max-width: 575.98px) {
    .import-form {
      padding: 15px;

      .form-footer {
        flex-direction: column;
        align-items: flex-start;

        .submit-btn {
          margin-top: 10px;
          width: 100%;
        }

        .download-link {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.searchable-dropdown {
  position: relative;
  width: 100%;
  border: 1px solid rgb(248, 248, 248);
}

.searchable-dropdown input {
  width: 100%;
  padding: 6px;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 6px;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: scroll;
  border: 1px solid rgb(182, 182, 182);
  background-color: #fff;
  z-index: 10;
  list-style-type: none;
  padding: 0;
  margin: 0;

  &.bottom {
    top: 100%;
    margin-top: 4px;
  }

  &.top {
    bottom: 100%;
    margin-bottom: 4px;
  }

  /* Hide scrollbar while keeping scrolling functionality */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
  
.dropdown-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}

.image-product-carousel {
  position: relative;
  width: 250px; // Adjust as needed
  height: 250px;

  .carousel {
    position: relative;
    width: 100%;
    height: 250px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 5px;

    .carousel-image {
      width: 100%;
      display: block;
      border-radius: 5px;
      padding: 20px;
    }

    .carousel-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--dark-blue);
      border: none;
      font-size: 24px;
      padding: 5px 10px;
      cursor: pointer;
      border-radius: 50%;
      z-index: 1;

      &.prev {
        left: 10px;
      }

      &.next {
        right: 10px;
      }
    }
  }

  .carousel-indicators {
    display: flex;
    justify-content: center;

    .indicator {
      width: 10px;
      height: 10px;
      background-color: #ddd;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;

      &.active {
        background-color: #007bff;
      }
    }
  }
}

/* Media Queries for Centering Carousel */
@media (max-width: 575.98px), (min-width: 576px), (min-width: 768px) {
  .image-product-carousel {
    margin: 0 auto; // Center horizontally
    display: flex;
    justify-content: center; // Center horizontally
    align-items: center; // Center vertically, if needed
  }
}
/* Media Queries to Align Carousel to the Left Margin */
@media (min-width: 992px), (min-width: 1200px), (min-width: 1400px) {
  .image-product-carousel {
    margin-left: 0; // Align to the left margin
    display: block; // Remove flexbox centering
  }
}

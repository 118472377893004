.category-container {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-height: 400px;
  overflow-y: auto; /* Enable vertical scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome, Safari, and Edge */
  }

  .category-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--grey);
  }

  .category-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .category-item {
    padding: 10px 15px;
    border-left: 3px solid transparent;
    border-bottom: 1px solid var(--border-bottom); /* Border line after each category */
    font-family: 'Poppins', sans-serif;
    color:var(--grey);
    cursor: pointer;
    transition: background-color 0.3s, border-left-color 0.3s;

    &:hover {
      background-color: #f0f0f0; /* Hover effect */
    }

    &.active {
      background-color: #e6f0ff; /* Light blue for active background */
      border-left-color: var(--dark-blue); /* Dark blue border */
      color: var(--dark-blue); /* Dark blue text */
    }
  }
}

.pagination {
  margin-bottom: 0px;
    .page-item {
      .page-link {
        display:flex;
        justify-content: center;
        align-items: center;
        color: var(--dark-blue); /* Default link color */
       
      }
      &.active .page-link {
       
        color: white; /* Change text color to white for contrast */
       
      }
      &.disabled .page-link {
        cursor: not-allowed;
        opacity: 0.5; /* Styles for disabled items */
      }
    }
  }
  
.add-location-page {
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  
    .header-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      h4 {
        font-weight: 600;
      }
  
      .save-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        padding: 10px 20px; /* Adjust padding for a better button size */
        cursor: pointer;
        border-radius: 5px;
        display: flex;
        align-items: center;
        font-size: 14px;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  
    .search-container {
      margin-top: 20px;
      display: flex;
      justify-content: center;
  
      .search-bar {
        position: relative;
        width: 100%;
  
        .search-icon {
          position: absolute;
          top: 15px;
          left: 10px;
          color: #888;
        }
  
        .search-input {
          width: 100%;
          padding: 10px 10px 10px 35px;
          border-radius: 5px;
          border: 1px solid #ccc;
          font-size: 16px;
  
          &:focus {
            outline: none;
            border-color: var(--grey);
          }
        }
      }
    }
  
    .district-list {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .district-item {
        display: flex; /* Use flexbox for checkbox and text alignment */
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        background-color: #f8f9fa;
        border: 1px solid #ced4da;
        border-radius: 5px;
        color: #333;
  
        span {
          margin-left: 10px; /* Space between checkbox and district name */
          text-align: left;
          flex: 1; /* Ensures text takes up available space */
        }
      }
  
      .no-results {
        text-align: center;
        color: #888;
      }
    }
  }
  
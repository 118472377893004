.offcanvas-desktop {
  display: block; // Default display for large screens
  overflow-y:hidden;
  overflow-y:auto;

  @media (min-width: 992px) {
    display: block; // Show on large screens (min-width: 992px)
  }
}

.offcanvas-mobile {
  display: none; // Default hidden for large screens

  /* Show on medium screens (min-width: 768px) */
  @media (min-width: 768px) {
    display: block;
  }

  /* Show on small screens (min-width: 576px) */
  @media (min-width: 576px) {
    display: block;
  }

  /* Show on extra-small screens (max-width: 575.98px) */
  @media (max-width: 575.98px) {
    display: block;
    height: 100% !important; // Full height for extra-small screens
  }
}

.offcanvas {
  padding: 0px;

  .offcanvas-header {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    height: 70px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .offcanvas-title {
      color: var(--grey);
    }

    .btn-close {
      font-size: 1.25rem;
      color: #212529;
    }
  }

  .offcanvas-body {
    .offcanvas-input-with-apply {
      margin-top: 30px;
      position: relative;
      display: flex;
      align-items: center;

      .form-control {
        padding-right: 60px; // Add padding to make space for the "Apply" text
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        padding: 15px;
        margin-bottom: 20px;
      }

      .offcanvas-apply-text {
        position: absolute;
        right: 15px;
        top: 40%;
        transform: translateY(-50%);
        background-color: transparent;
        color: var(--light-grey);
        cursor: pointer;
      }
    }

    .card-offers {
      padding: 5px;
      padding-top: 20px;
      background-color: rgb(239, 248, 255);

      .card-offers-available-title {
        margin-bottom: 10px;
        color:var(--black);
      }

      .no-offers {
        color: #333; /* Use a visible color like dark gray */
        font-size: 16px; /* Optional: adjust size for better visibility */
        text-align: center; /* Center-align the message */
        margin-top: 20px; /* Add some spacing from other elements */
      }
      
    }
  }
}

/* Media Queries for specific device sizes */

/* Medium screens (min-width: 768px) */
@media (min-width: 768px) {
  .offcanvas {
    display: block; // Show offcanvas on medium screens

  }
}

/* Small screens (min-width: 576px) */
@media (min-width: 576px) {
  .offcanvas {
    width:100%;
    height:100% !important;
    display: block; // Show offcanvas on small screens
  }
}

/* Extra-small screens (max-width: 575.98px) */
@media (max-width: 575.98px) {
  .offcanvas {
    width: 100%;
    height: 100% !important; // Full height for extra-small screens
  }
}

.product-card {
  border: 1px solid #e0e0e0;
  padding: 16px;
  padding-top: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  width: 250px; /* Standardized card width */
  height: 350px; /* Adjusted for consistent height */
  display: flex;
  flex-direction: column;

  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  // Out of stock styling
  &.out-of-stock-card {
    filter: grayscale(100%);
    opacity: 0.6;
    pointer-events: none; // Disable interactions for out-of-stock items
  }

  .discount-badge {
    position: absolute;
    top: 10px;
    left: 0;
    background-color: #4caf50;
    color: #fff;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
  }
  .product-image-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    width: 100%; /* Ensure container takes up the necessary space */
    height: 50%; /* Set an appropriate height */
    
  }
  
  .product-image {
    max-width: 100%;
    height: 100px;
    object-fit: contain; /* Adjust image scaling behavior */
    cursor: pointer; /* Optional: Add pointer effect for clickable images */
  }
  
 
  .product-name {
    font-size: 14px; /* Keep font size consistent */
    font-weight: bold;
    color: #333;
    text-align: center;
    max-width: 100%;
    
    display: block; /* Ensure it behaves as a block element */
    overflow: visible; /* Prevent cutting off */
    white-space: normal; /* Allow wrapping */
    text-overflow: unset;
    line-height: 1.3; /* Adjust line height */
    height: auto; /* Allow height to adjust */
    min-height: 3em; /* Ensures enough space for longer names */
}

  
  .product-description {
    font-size: 12px; /* Adjust the font size for description */
    margin-bottom: 10px;
    height: 350px; /* Allow description to grow */
  }
  .product-info {
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: left;
    .product-price-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .product-mrp {
        color: #888;
        text-decoration: line-through;
        margin: 0;
      }

      .product-price {
        font-weight: bold;
        color: #333;
      }
    }

    .add-button {
      background-color: var(--white);
      color: var(--dark-blue);
      border: 1px solid var(--dark-blue);
      border-radius: 4px;
      padding: 2px 12px;
      cursor: pointer;
      width:120px;
      height:40px;

      &:hover {
        background-color: var(--dark-blue);
        color: var(--white);
      }
    }
  }
  
  .quantity-input-wrapper {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center;    /* Center vertically */
    margin-top: 8px;
    width: 100%;            /* Adjust width as needed */
  }
  .out-of-stock-button {
    background-color: #ccc;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
    cursor: not-allowed;
  }
}

/* Responsive Styles */
@media (max-width: 575.98px) {
  .product-card {
    width: 45%;
    height: 350px;
    padding-top: 20px;
    

    .product-info {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin-top: 10px;

      .add-button {
        margin-top: 10px;
        align-self: center;
      }
      .quantity-input-wrapper {
        align-self: center; /* Center-align the button */
        width: 100%; /* Full width */
        height: 40px; /* Consistent height for the button */
        text-align: center; /* Align text inside the button */
      }
      
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .product-card {
    width: 220px;
    height: 350px;
   

    .product-info {
      flex-direction: column;
    

      .add-button {
        margin-top: 10px;
        align-self: center;
      }
      .quantity-input-wrapper {
        align-self: center; /* Center-align the button */
        width: 100%; /* Full width */
        height: 40px; /* Consistent height for the button */
        text-align: center; /* Align text inside the button */
      }
      
    }
  }
}

@media (min-width: 768px) {
  .product-card {
    width: 250px;
    height: 350px;

    .product-info {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .product-price-info {
        align-items: flex-start;
      }

      .add-button {
        align-self: center;
      }
      .quantity-input-wrapper {
        align-self: center; /* Center-align the button */
        width: 100%; /* Full width */
        height: 40px; /* Consistent height for the button */
        text-align: center; /* Align text inside the button */
      }
    }
  }
}

/* For dimensions around 1013x482 */
@media (min-width: 992px) and (max-width: 1200px) {
  .product-card {
    width: 33.33%; /* Adjust to fit 3 cards per row */
    min-height: 320px; /* Ensure consistent height */
    padding: 16px;
    margin-bottom: 20px; /* Space between rows */

    .product-info {
      flex-direction: column; /* Stack price and buttons vertically */
      align-items: center; /* Center-align content */
      justify-content: space-between;
    }

  
    .add-button {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
    .quantity-input-wrapper {
      align-self: center; /* Center-align the button */
      width: 100%; /* Full width */
      height: 40px; /* Consistent height for the button */
      text-align: center; /* Align text inside the button */
    }
  }
}

@media (min-width: 1200px) {
  .product-card {
    width: 250px;
    height: 350px;
  }
}

@media (min-width: 1400px) {
  .product-card {
    width: 250px;
    height: 350px;
  }
}

.supplier-main-page {
  background-color: var(--whitesmoke);
  padding:20px;
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }
  .bulk-supplier-container {
    padding:5px;
    border-radius: 10px;
  }  
  .bulk-supplier{
    padding:20px;
    background: var(--white);

    .supplier-table {
      background-color: var(--white);
      border-radius: 10px;
  
      .header-row {
          display: flex;
          justify-content: space-between; /* Push items to opposite corners */
          align-items: center;
         
        
      
    .text-heading1 {
        padding: 10px !important;
        margin-bottom: 20px;
        text-align: left !important;
        align-self: flex-start; /* If parent is flexbox */
    }
  
    .add-new-button {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
  
      .add-button,
      .save-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 8px 16px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
  
        &:disabled {
          background-color:var(--light-grey);
          cursor: not-allowed;
        }
      }
    }
    }
  }
  }
  .table-container {
    max-height: 400px; /* Set the height of the table container */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    position: relative;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: thin; /* Firefox */
    scrollbar-color: #999 transparent;
  
    &::-webkit-scrollbar {
      height: 8px; /* Set the height for horizontal scrollbar */
    }
  
    &::-webkit-scrollbar-thumb {
      background: var(--whitesmoke); /* Color of the scrollbar */
      border-radius: 4px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent; /* Transparent background */
    }
  
    .custom-table {
      width: 100%; /* Ensure the table fills the container */
      border-collapse: collapse;
      margin-top: 20px;
  
      thead {
        position: sticky;
        top: 0; /* Fix the header at the top */
        z-index: 2;
        background-color: var(--whitesmoke); /* Header background color */
      }
  
      th,
      td {
        padding: 8px;
        text-align: center;
        border-bottom: 1px solid var(--border-bottom);
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: var(--grey);
        box-sizing: border-box; /* Include padding and border */
        min-width: 120px; /* Prevent columns from shrinking too much */
      }
  
      th {
        font-weight: 600;
        background-color: var(--th);
      }
  
      td {
        background-color: var(--white);
        height: 50px; /* Uniform row height */
      }
  
      .small-column {
        width: 80px;
      }
  
      td input {
        padding: 8px;
        width: auto;
        border: none;
        border-bottom: 1px solid var(--border-bottom);
        outline: none;
        font-family: 'Poppins', sans-serif;
        background: transparent;
      }
  
      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 5px;
      }
    }
  
    .icon-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 16px;
      color: var(--grey);
  
      &:hover {
        color: var(--black);
      }
    }
  }
  
    .button-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      gap: 15px;
  
      .next-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border: none;
        border-radius: 4px;
        cursor: pointer;
        padding: 8px 16px;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }

  
  /* Responsive Design */
  @media (max-width: 575.98px) {
    .table-container {
      max-height: 300px; /* Adjust container height for smaller screens */
      overflow-x: auto; /* Ensure horizontal scrolling works on small screens */
    }
  
    .button-row {
      justify-content: center; /* Center the buttons on small screens */
    }
  
    .add-new-button {
      flex-direction: column;
      gap: 10px;
    }
  }
  
.delivery-location-configuration-container {
  padding: 20px;

  .add-location-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .add-location-button {
      background-color: var(--dark-blue);
      color: white;
     
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .table-container {
    margin-bottom: 20px;

    .location-table {
      width: 100%;
      border-collapse: collapse;
      
      th, td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }

      th {
        background-color: #f2f2f2;
      }

      .table-input {
        width: 100%;
      }
    }
  }

  .save-button-container {
    display: flex;
    justify-content: flex-end;

    .save-button {
      background-color:var(--dark-blue);
      color:var(--white);
     
      border-radius: 5px;
      cursor: pointer;
    }
  }
}

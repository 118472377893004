.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1040;
}

.offcanvas {
  position: fixed;
  top: 0;
  right: -400px;
  width: 400px;
  height: 100%;
  background-color: var(--white);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
  border-radius: 0 !important; /* Remove border-radius */
}

.offcanvas.show {
  transform: translateX(0);
}

.offcanvas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  

  .btn-close7 {
    font-size: 1.5rem;
    color: var(--grey) !important;
    border: none;
    background: none;
    cursor: pointer;
    
  }
}

.offcanvas-body {
  padding: 20px;

  .datepickers6 {
    display: flex;
    gap: 15px;
    margin-top: 10px;

    .datepicker-container6 {
      flex: 1;

      label {
       
        color: var(--grey);
        margin-bottom: 5px;
        display: block;
      }

      .date-input6 {
        width: 100%;
        padding: 6px;
        border-radius: 4px;
        border: 1px solid var(--border);
        background-color: var(--whitesmoke);
        text-align: center;
      }
    }
  }
.subscription-text-box{
  margin-top: 20px;
  label {
   
    color: var(--grey);
    margin-bottom: 5px;
    display: block;
  }
  .subscription-pause-reason {
    margin-top: 30px;
    width: 100%;
    padding: 7px;
    border-radius: 4px;
    border: 1px solid var(--border);
    background-color: var(--white);
    
  }
}
  .subscription-button-container2 {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    .subscription-save-button {
      padding: 7px;
      color: var(--white);
      border: none;
      border-radius: 4px;
      cursor: pointer;
      
    
      &.pause-button {
        background-color: var(--dark-blue); /* Button color for Pause */
      }
    
      &.cancel-button {
        background-color: var(--red); /* Button color for Cancel */
      }
    }
    
  }
}

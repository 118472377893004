.product-section {
  max-width: 100%;

  .product-container {
    margin: 20px;
    justify-content: space-around;

    .product-section-title {
      font-style: normal;
      font-weight: 700;
      margin: 20px;
      text-align: left;
      color: #333; /* Text color */
    }

    .product-carousel {
      .carousel-inner {
        display: flex;
        justify-content: flex-start;
      }

      .carousel-item {
        transition: transform 1s ease-in-out; /* Smooth sliding transition */
      }

      .product-group {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        text-align: left;
        gap: 30px;
        padding: 0px;
        margin-bottom: 20px;
        margin-left: 5px;
      }

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        background-color: #858282;
        border-radius: 50%;
        width: 30px; /* Smaller control icon */
        height: 30px;
        background-size: 60%; /* Adjust size of arrow inside */
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease-in-out;
      }
      .carousel-control-prev,
      .carousel-control-next {
        width: 30px; /* Reduce control width */
    height: 30px; /* Reduce control height */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
      }
    }

    .product-card {
   
      text-align: left;
      border: 2px solid transparent; /* Default transparent border to avoid layout shift */
     
    }
    
   
    // Skeleton Loader for Products, Brands, and Categories
    .skeleton-card {
      width: 100%;
      height: 230px;
      background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
      background-size: 200% 100%;
      animation: skeleton-shimmer 1.5s infinite;
      border-radius: 8px;
    }
    
    @keyframes skeleton-shimmer {
      from {
        background-position: 200% 0;
      }
      to {
        background-position: -200% 0;
      }
    }
    
  }

  // Responsive styles for product cards

  @media (min-width: 768px) {
    .product-card {
      flex: 0 0 30%; /* 5 cards per row on desktop screens */
      max-width: 30%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #858282;
    border-radius: 50%;
    width: 30px; /* Smaller control icon */
    height: 30px;
    background-size: 60%; /* Adjust size of arrow inside */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    }
  }

  @media (min-width: 992px) {
    .product-card {
      flex: 0 0 19%; /* 5 cards per row on desktop screens */
      max-width: 19%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #858282;
    border-radius: 50%;
    width: 30px; /* Smaller control icon */
    height: 30px;
    background-size: 60%; /* Adjust size of arrow inside */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease-in-out;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px){
    .product-card {
      flex: 0 0 20%; /* 5 cards per row on desktop screens */
      max-width: 20%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #858282;
      border-radius: 50%;
      width: 30px; /* Smaller control icon */
      height: 30px;
      background-size: 60%; /* Adjust size of arrow inside */
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;
    }
  }

  @media (min-width: 1200px) {
    .product-card {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-color: #858282;
      border-radius: 50%;
      width: 30px; /* Smaller control icon */
      height: 30px;
      background-size: 60%; /* Adjust size of arrow inside */
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s ease-in-out;
    }
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    .product-card {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }

  @media (min-width: 1400px) {
    .product-card {
      flex: 0 0 18%;
      max-width: 18%;
    }
  }
}

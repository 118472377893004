.coupon-page-section {
  padding: 20px;
  background-color: var(--whitesmoke);

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    color: var(--grey);
   
  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover
 
}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }


  .coupon-container {
    padding:5px;
    border-radius: 10px;
  }
   .coupon-page-container{
  
  background-color: var(--white);
  border-radius: 10px;
.coupon-page {
  padding:20px;
  background: var(--white);

  .text-heading1 {
    padding: 10px !important;
    margin-bottom: 20px;
    text-align: left !important;
    align-self: flex-start; /* If parent is flexbox */
}
  .form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }

  .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    input, select {
     
    border: 1px solid var(--border);
      border-radius: 4px;
    }
  }

  .save-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    padding: 10px;
  }
  
  .submit-button {
    background-color: var(--dark-blue);
    color: var(--white);
  }
  
}

  /* Media Queries for Responsive Design */
  @media (min-width: 1400px) {
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 1200px) {
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 992px) {
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 768px) {
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  @media (min-width: 576px) {
    .form-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  /* Stack fields in a column for small screens */
  @media (max-width: 575.98px) {
    .form-row {
      display: flex;
      flex-direction: column;
    }

    .form-group {
      margin-bottom: 15px;
    }
   

    .submit-button {
      width: 100%;
      margin: 0 auto; /* Center the button on small screens */
    }
  }
}
}
.banner-page-section {
    padding: 20px;
    background-color: whitesmoke;
  
    .banner-page {
      padding: 20px;
      background-color: var(--white);
  
      .banner-container {
        border-radius: 5px;
        padding: 20px;
        border: 1px solid #ccc;
        margin-bottom: 10px;
      }
  
      .form-row {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;
      }
  
      .form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
  
        label {
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
  
      .action-buttons {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;

        .add-icon {
          color: var(--grey);
          margin-right: 8px;
        }
  
        .add-new-button1 {
          margin-top: 10px;
          background-color: #e7e7e7;
          color: black;
          border: none;
          border-radius: 4px;
          cursor: pointer;
  
          &:hover {
            background-color: #878787;
          }
        }
      }
  
      /* Table styles */
      .table-container {
        width: 100%;
        overflow-x: auto; /* Enable horizontal scrolling for all screen sizes */
  
        .banner-table {
          width: 100%; /* Ensure the table takes full width */
          min-width: 800px; /* Set a minimum width for the table to trigger scrolling */
          border-radius: 5px;
          border-collapse: collapse;
          margin-bottom: 20px;
  
          th, td {
            padding: 10px;
            text-align: center;
          }
  
          th {
            background-color: #e7e7e7;
          }
  
          td .icon-button {
            background-color: var(--white);
            border: none;
            margin: 0;
            padding: 5px;
          }
        }
      }
  
      /* Save button container */
      .save-button-container {
        display: flex;
        padding: 5px;
        justify-content: flex-end;
        background-color: #f3f8ff;
  
        .save-button {
          background-color: var(--dark-blue);
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
  
          &:hover {
            background-color: #083d73;
          }
        }
      }
  
      /* Responsive layout */
      @media (max-width: 575.98px) {
        flex-direction: column;
        .form-row {
          flex-wrap: wrap;
        }
      }
  
      @media (min-width: 576px) {
        .form-row {
          flex-wrap: wrap;
        }
      }
  
      @media (min-width: 768px) {
        .form-row {
          flex-wrap: nowrap;
        }
      }
  
      @media (min-width: 992px) {
        .form-row {
          flex-wrap: nowrap;
        }
      }
  
      @media (min-width: 1200px) {
        .form-row {
          flex-wrap: nowrap;
        }
      }
  
      @media (min-width: 1400px) {
        .form-row {
          flex-wrap: nowrap;
        }
      }
    }
  }

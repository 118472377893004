// src/common/CategoriesCard/CategoriesCard.scss
.categories-card {
    border: 1px solid #e0e0e0;
    padding: 16px;
    padding-top:70px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    width: 250px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    background-color: var(--white);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
    .discount-badge {
      position: absolute;
      top: 10px;
      left: 0px;
      background-color: var(--green);
      color:var(--white);
      padding: 4px 8px;
      font-size: 12px;
      font-weight: bold;
    }
  
    .product-image {
      max-width: 100%;
      height: 100px;
      object-fit: contain;
     
     
    }
  
    .product-title {
    
     margin-bottom: 0px;
      height: 40px;
      overflow: hidden;
      text-align: left;
    }
  
    .divider-line {
      border: none;
      border-top: 1px solid black;
      margin: 10px 0; // Adjust spacing as needed
    }
  
    .product-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .product-price {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      .original-price {
        text-decoration: line-through;
        color: #999;
       
      }
  
      .discounted-price {
        color:black;
        font-weight: bold;
        
      }
    }
  
    .add-button {
      background-color: var(--white);
        color: var(--dark-blue);
        border: 1px solid var(--dark-blue);
        border-radius: 4px;
        padding: 2px 12px; /* Adjusted padding for button size */
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: var(--dark-blue);
          color: white;
        }
    }
  }
  /* For very small screens */
  @media (max-width: 575.98px) {
    .categories-card {
        width: calc((100% - 70px) / 2); /* Example: 2 cards in a row with gaps */
        .product-footer {
          flex-direction: column; /* Stacks MRP, Price, and Button in a column */
          align-items: flex-start; /* Aligns items to the left */
    
          .product-price {
            margin-bottom: 10px; /* Adds space between prices and button */
          }
    
          .add-button {
            margin-top: 5px; /* Adds spacing below prices */
            align-self: flex-start; /* Align button to the left */
          }
        }
      }
  }
  @media (min-width: 578px) {
    .categories-card {
        width: calc((100% - 70px) / 2); /* Example: 2 cards in a row with gaps */
        .product-footer {
          flex-direction: column; /* Stacks MRP, Price, and Button in a column */
          align-items: flex-start; /* Aligns items to the left */
    
          .product-price {
            margin-bottom: 10px; /* Adds space between prices and button */
          }
    
          .add-button {
            margin-top: 5px; /* Adds spacing below prices */
            align-self: flex-start; /* Align button to the left */
          }
        }
      }
  }
  @media (min-width: 768px) {
    .categories-card {
      width: calc((100% - 40px) / 2); /* Example: 2 cards in a row with gaps */
      .product-footer {
        flex-direction: row; /* Stacks MRP, Price, and Button in a column */
       
      }
    }
  }
  
  @media (min-width: 992px) {
    .categories-card {
        border: 1px solid #e0e0e0;
        padding: 16px;
        padding-top:70px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        width: 250px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .product-footer {
          flex-direction: row; /* Stacks MRP, Price, and Button in a column */
         
        }
  }
}
  
  @media (min-width: 1200px) {
    .categories-card {
        border: 1px solid #e0e0e0;
        padding: 16px;
        padding-top:70px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        width: 250px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .product-footer {
          flex-direction: row; /* Stacks MRP, Price, and Button in a column */
         
        }
  }
}
  
  @media (min-width: 1400px) {
    .categories-card {
        border: 1px solid #e0e0e0;
        padding: 16px;
        padding-top:70px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        width: 250px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      
        background-color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .product-footer {
          flex-direction: row; /* Stacks MRP, Price, and Button in a column */
         
        }
  }
  
}
  
  
.input-component {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  
    label {
      margin-bottom: 5px;
      font-weight: bold;
    }
  
    input {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 16px;
      width: 100%;
    }
    .submit-return-btn{
        background-color: var(--dark-blue);
    }
  }
  
.subscription-page {
  padding: 20px;

  .page-title {
    text-align: center;
  }
  .breadcrumb-container {
    display: flex;
    align-items: center;
   
  
    color: var(--grey);
   
  background-color: var(--white);
  

  .breadcrumb-text {
   
    text-decoration: none; // Remove underline
    color: inherit;
  &:hover {
  text-decoration: none; // Ensure no underline on hover
  
  }
  }
  
  .breadcrumb-separator {
    margin: 0 5px;
    color: #aaa;
  }
  }
  .main-content {
    display: flex; /* Use flexbox to place left and right sections side by side */
    margin-top: 30px;
      margin:20px auto;
    .left-section {
      width: 30%;
      .filter-text{

      }
    
      .filters-section {
        margin-top: 10px;
       
        .filter-container {
          border: 1px solid var(--border);
          padding: 20px;
          border-radius: 8px; /* Rounded corners */
          background-color: #fff; /* White background */
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
          margin-top: 30px;
    
          .filter-title {
            display: flex;
            justify-content: space-between; /* Align left and right */
            align-items: center; /* Vertically center */
            margin-bottom: 10px;
      
            h6 {
              margin: 0; /* Remove default margins */
              font-weight: bold;
              color: var(--fade-black);
            }
      
           
          }
    
          .filter-options {
            display: flex;
            flex-direction: column;
            gap: 15px;
    
            .filter-option {
              display: flex;
              align-items: center;
              cursor: pointer;
    
              label {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                color: var(--grey);
    
                input[type="radio"] {
                  appearance: none; /* Remove default styling */
                  -webkit-appearance: none; /* Remove Safari default styling */
                  background-color: var(--white);
                  border: 1.5px solid var(--dark-blue); /* Blue border color */
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  position: relative;
                  cursor: pointer;
                  border-radius: 50%; /* Circle shape */
                  outline: none;
    
                  &:checked {
                    background-color: var(--dark-blue); /* Fill with blue when checked */
                    border-color:var(--dark-blue);
    
                    &:before {
                      content: '';
                      position: absolute;
                      width: 10px;
                      height: 10px;
                      background-color: var(--white);
                      border-radius: 50%;
                      top: 50%;
                      left: 50%;
                      transform: translate(-50%, -50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    

    .right-section {
      width: 100%;
      height: auto; 
      padding:0px;  
      margin-left:10px;
      .header-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
       
     
        .filter-button {
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 5px 10px;
          background-color: var(--white);
          border: 1px solid var(--border);
          border-radius: 15px;
          color: var(--grey);
          font-size: 0.9rem;
          cursor: pointer;
          transition: all 0.3s ease;
    
          i {
            font-size: 1rem;
          }
    
          &:hover {
            background-color: var(--fade-grey);
          }
        }
      }
    
  .subscription-card {
    width: 100%;
    max-width: 800px;
    border: 1px solid var(--border);
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 10px;
    

    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      

      .product-info {
        display: flex;
        align-items: center;
        gap: 15px;

        .product-image {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 5px;
        }

        .product-name {
          font-weight: bold;
          color: var(--grey);
        }
      }

      .status {
        font-weight: bold;
        color: var(--white);
        padding: 5px 10px;
        border-radius: 20px;
        text-transform: uppercase;
      }
    }

    .card-body {
      display:flex;
      .label {
        font-weight: bold;
        color: var(--fade-black);
      }

      .value {
        color: var(--grey);
      }

      .time-dates-row {
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Push to the left */
        // gap: 30px; /* Add space between the sections */
        margin: 10px 0;

        .time-slots,
        .selected-dates {
          display: flex;
          align-items: center;
          gap: 10px;

          .label {
            margin-right: 10px;
          }

          .dates-container {
            display: flex;
            gap: 8px;

            .date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 90%;
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }

      .selected-days-row {
        display: flex;
        align-items: center;
        justify-content: flex-start; /* Align everything to the left */
        gap: 20px; /* Add space between Days and Selected Dates */
        margin-top: 10px;
      
        .days-section,
        .selected-dates {
          display: flex;
          align-items: center; /* Align content vertically */
          gap: 10px; /* Space between label and content */
      
          .label {
            font-weight: bold;
            margin-right: 5px;
            color: var(--fade-black);
          }
      
          .days-container,
          .dates-container {
            display: flex;
            gap: 8px; /* Space between day/date items */
      
            .day,
            .date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
      

      p {
        margin: 10px 0;
      }
    }
  }
}
}
}

/* Max-width for smaller screens */
@media (max-width: 575.98px) {
  .subscription-page {
  .main-content {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Add spacing between sections */
  }

  .left-section {
    display: none !important; /* Hide left section completely */

    .filter-text {
      display: none !important; /* Ensure any text inside filters is hidden */
    }

    .filters-section {
      display: none !important;
    }
  }

  .right-section {
    width: 100%;
    padding: 10px;

    .filter-button {
      display: block !important; /* Show filter button */
      margin-bottom: 15px; /* Add spacing below the button */
    }

    .subscription-card {
      display: flex;
      flex-direction: column;
      gap: 15px; /* Add spacing between card elements */
      width: 100%;

      .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
      }

      .card-body {
        display: flex;
        flex-direction: column; /* Stack items vertically */
       
        width: 100%;

        .time-dates-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center; /* Center align the content */
          justify-content: space-between; /* Distribute items evenly */
          gap: 15px; /* Add spacing between sections */
          width: 100%; /* Ensure full width */

          .time-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start; /* Align items to the left */
            gap: 8px; /* Add spacing between items */

            .label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }

            .value {
              color: var(--grey);
              margin-right: 5px;
            }
          }

          .months-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }

            .value {
              color: var(--grey);
            }
          }
        }

        .selected-days-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center;
          justify-content: space-between; /* Align sections evenly */
          gap: 15px;

          .days-section,
          .selected-dates {
            display: flex;
            align-items: center;
            gap: 10px;

            .label {
              font-weight: bold;
              margin-right: 5px;
              color: var(--fade-black);
            }

            .dates-container,
            .days-container {
              display: flex;
              gap: 8px; /* Add spacing between items */
              flex-wrap: wrap; /* Allow items to wrap */
            }

            .day,
            .date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

}




/* Min-width for screens >= 576px */
@media (min-width: 576px) {
  .subscription-page {
  .main-content{
    display:flex;
  .left-section {
    display: none !important; /* Hide left section */
    .filter-text{
      display: none !important;   
    }
.filters-section{
  display:none;
}
  }

  .right-section {
    width: 100%;
    padding: 10px;

    .filter-button {
      display: block !important;
    }
    .subscription-card {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;

      .card-header{
        display: flex;
      justify-content: space-between;
      align-items: center;
     
      }
      .card-body {
        display: flex;
        flex-direction: column; /* Stack items vertically */
       
        width: 100%;

        .time-dates-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center; /* Center align the content */
          justify-content: space-between; /* Distribute items evenly */
          gap: 15px; /* Add spacing between sections */
          width: 100%; /* Ensure full width */

          .time-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start; /* Align items to the left */
            gap: 8px; /* Add spacing between items */

            .label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }

            .value {
              color: var(--grey);
              margin-right: 5px;
            }
          }

          .months-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;

            .label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }

            .value {
              color: var(--grey);
            }
          }
        }

        .selected-days-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center;
          /* Align sections evenly */
          gap: 15px;

          .days-section,
          .selected-dates {
            display: flex;
            align-items: center;
            gap: 10px;

            .label {
              font-weight: bold;
              margin-right: 5px;
              color: var(--fade-black);
            }

            .dates-container,
            .days-container {
              display: flex;
              gap: 8px; /* Add spacing between items */
              flex-wrap: wrap; /* Allow items to wrap */
            }

            .day,
            .date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
}
}
/* Min-width for screens >= 768px */
@media (min-width: 768px) {
  .subscription-page{
    .left-section {
      display: none !important; /* Hide left section */
      .filter-text{
        display: none !important;   
      }
    }
  
    .right-section {
      width: 100%;
      padding: 0 20px;
  
      .filter-button {
        display: block !important;
      }
      .subscription-card {
        width: 100%;
        max-width: 800px;
        border: 1px solid var(--border);
        border-radius: 10px;
        background-color: var(--white);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        display: flex;
        flex-direction: column;
  
        .card-header,
        .card-body {
          width: 100%; /* Ensure child elements take full width */
        }
      }
    }
  }
 
}

/* Min-width for screens >= 992px */
@media (min-width: 992px) {
  .subscription-page {
  .main-content{
    display:flex;
  .left-section {
    display: block !important; /* Hide left section */
    .filter-text{
      display: block !important;   
    }
.filters-section{
  display:block !important;
}
  }

  .right-section {
    width: calc(100% - 30%); /* Adjust width based on left section */
    .filter-button {
      display: none !important;
    }
    .subscription-card {
      width: 100%;
      max-width: 800px;
      border: 1px solid var(--border);
      border-radius: 10px;
      background-color: var(--white);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
    }
  }
}
}
}
/* Min-width for screens >= 1200px */
@media (min-width: 1200px) {
  .subscription-page {
  .main-content{
    display:flex;
  .left-section {
    display: block !important; /* Hide left section */
    .filter-text{
      display: block !important;   
    }
.filters-section{
  display:block;
}
  }

  .right-section {
    width: calc(100% - 30%); /* Adjust width based on left section */

    .filter-button {
      display: none !important;
    }
    .subscription-card {
      width: 100%;
      max-width: 800px;
      border: 1px solid var(--border);
      border-radius: 10px;
      background-color: var(--white);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
    }
  }
}
}
}
/* Min-width for screens >= 1400px */
@media (min-width: 1400px) {
  .subscription-page {
  .main-content{
    display:flex;
  .left-section {
    display: block !important; /* Hide left section */
    .filter-text{
      display: block !important;   
    }
.filters-section{
  display:block !important;
}
  }

  .right-section {
    width: calc(100% - 30%); /* Adjust width based on left section */

    .filter-button {
      display: none !important;
    }
    .subscription-card {
      width: 100%;
      max-width: 800px;
      border: 1px solid var(--border);
      border-radius: 10px;
      background-color: var(--white);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 20px;
    }
  }
}
}
}
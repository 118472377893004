.refer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9fafb;
  font-family: 'Arial', sans-serif;

  .refer-banner {
    background-color: #34a853;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .refer-banner-text {
      color: white;
      margin-bottom: 20px;

      .refer-para {
        font-size: 18px;
        margin: 0;
      }

      .refer-earn-text {
        font-size: 36px;
        margin: 0;
        font-weight: bold;
      }
    }

    .refer-code-container {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;

      .refer-code-input {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        width: 100%;

        label {
          margin-bottom: 10px;
          color: #333;
        }

        .copy-button {
          background: none;
          border: none;
          cursor: pointer;
          color: #007bff;
        }
      }

      .refer-button {
        background-color: #0069d9;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
      }
    }
  }

  .referral-info-container {
    background-color: #f0f4ff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 800px;
    margin-top: 20px;

    .referral-info-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      color: #333;

      .referal-heading {
        text-align: center;
        margin: 0;
      }

      .view-toggle {
        background: none;
        border: none;
        color: #0069d9;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .referral-steps {
      display: flex;
      justify-content: space-between;
      gap: 20px;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        flex: 1;

        .step-icon {
          font-size: 36px;
          color: #007bff;
          margin-bottom: 10px;
        }

        p {
          font-size: 14px;
          color: #333;
        }
      }
    }
  }

  .claim-reward-container {
    margin-top: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 600px;

    .claim-reward-input {
      display: flex;
      justify-content: center;
      gap: 10px;

      input {
        border: 1px solid #ccc;
        padding: 10px;
        font-size: 16px;
        border-radius: 4px;
        width: 200px;
      }

      .claim-button {
        background-color: #0069d9;
        color: white;
        border: none;
        padding: 10px 20px;
        border-radius: 4px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 575.98px) {
    .referral-steps {
      flex-direction: column;
      gap: 20px;

      .step {
        width: 100%;

        .step-icon {
          margin-right: 10px;
        }

        p {
          text-align: left;
        }
      }
    }

    .claim-reward-input {
      flex-direction: column;

      .claim-button {
        width: 100%;
      }
    }
  }
}

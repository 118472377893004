.offer-card {
  background: #ffffff;
  border: 1px dashed #e0e0e0;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .offer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .offer-icon-section {
      display: flex;
      align-items: center;

      .offer-icon {
        font-size: 20px;
        color: #6a1b9a;
        margin-right: 10px;
      }

      .offer-code {
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
    }

    .apply-button {
      background-color: #28a745;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      border: none;
      border-radius: 20px;
      padding: 5px 12px;
      cursor: pointer;

      &:hover {
        background-color: #218838;
      }

      &.remove {
        background-color: #dc3545;

        &:hover {
          background-color: #c82333;
        }
      }
    }
  }

  .offer-details {
    .min-purchase {
      background: #f2e8fb;
      color: #6a1b9a;
      font-size: 14px;
      font-weight: bold;
      padding: 5px 10px;
      border-radius: 6px;
      margin-bottom: 10px;
    }

    .offer-description {
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
    }

    .offer-info {
      list-style: none;
      padding: 0;

      li {
        font-size: 14px;
        color: #555;
        margin-bottom: 5px;
      }
    }
  }

  .terms-link {
    display: block;
    text-align: center;
    font-size: 14px;
    color: #6a1b9a;
    text-decoration: underline;
    margin-top: 10px;

    &:hover {
      color: #501e73;
    }
  }
}

.delivery-config-page-section {
    padding: 20px;
    background-color: var(--whitesmoke);

    .breadcrumb-container {
        display: flex;
        align-items: center;
        padding: 10px;
        color: var(--grey);
       
      background-color: var(--white);
    
      .breadcrumb-icon {
        color: var(--grey);
        margin-right: 8px;
      }
    
      .breadcrumb-text {
        margin: 0 5px;
        text-decoration: none; // Remove underline
        color: inherit;
    &:hover {
      text-decoration: none; // Ensure no underline on hover
     
    }
      }
    
      .breadcrumb-separator {
        margin: 0 5px;
        color: var(--fade-grey);
      }
      }
      .delivery-config-container {
        padding:5px;
        border-radius: 10px;
      }  
.config-page{
    padding:20px;
    background: var(--white);
    .text-heading1 {
        padding: 10px !important;
        margin-bottom: 20px;
        text-align: left !important;
        align-self: flex-start; /* If parent is flexbox */
    }
    .form-row {
      display: flex;
      gap: 20px;
      margin-bottom: 20px;
    }
  
    .form-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      label {
        margin-bottom: 5px;
        font-weight: bold;
      }
  
      input, select {
       
        border: 1px solid var(--border);
        border-radius: 4px;
      }
     
      
    }
    .form-row1{
      width:50%;
      .form-group{
        .applies-to-all {
          display: flex;
          align-items: center; /* Align vertically */
          gap: 8px; /* Add spacing between checkbox and label */
        }
        
        .applies-to-all input[type="checkbox"] {
          width: 16px;
          height: 16px;
          accent-color: #007bff; /* Adds blue color similar to the image */
        }
        
        .applies-to-all .checkbox-label {
          font-size: 16px;
          color: #333; /* Set text color */
          cursor: pointer;
        }
      }
    }

  
    .submit-button {
     
      background-color: var(--dark-blue);
      color: var(--white);
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-left: auto; /* Align the button to the right */
      
      &:hover {
        background-color: #083d73;
      }
    }
}
  
    /* Media Queries for Responsive Design */
    @media (min-width: 1400px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 1200px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 992px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 768px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    @media (min-width: 576px) {
      .form-row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: nowrap;
      }
    }
  
    /* Stack fields in a column for small screens */
    @media (max-width: 575.98px) {
      .form-row {
        display: flex;
        flex-direction: column;
      }
  
      .form-group {
        margin-bottom: 15px;
      }
     
  
      .submit-button {
        width: 100%;
        margin: 0 auto; /* Center the button on small screens */
      }
    }
  }
  
.subscription-offcanvas {
  .offcanvas {
   
      background-color: var(--white);
      transition: transform 0.3s ease-in-out;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For IE and Edge */
      border-radius: 0;
      height: 100%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
      &::-webkit-scrollbar {
        display: none; /* For Webkit browsers */
      }
    
      &.show {
        visibility: visible;
        transform: translateX(0);
      }
    
      &-body {
        padding: 20px;
        overflow-y: auto; /* Allow vertical scrolling */
        height: 100%;
        scrollbar-width: none; /* For Firefox */
        -ms-overflow-style: none; /* For IE and Edge */
    
        &::-webkit-scrollbar {
          display: none; /* For Webkit browsers */
        }
      }
    
    
    .offcanvas-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      border-bottom: 1px solid var(--light-grey);

      .subscription-title {
        font-size: 18px;
        font-weight: bold;
        color: var(--dark-blue);
        margin: 0;
      }

      .btn-close {
        background: none;
        border: none;
        font-size: 20px;
        cursor: pointer;
        color: var(--dark-blue);

       
      }
    }
  }

  .product-info-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow-y: auto;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
    &::-webkit-scrollbar {
      display: none; /* For Webkit browsers */
    }
    .product-image {
      flex: 0 0 60px;
      height: 60px;

      .product-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .product-details {
      flex: 1;
      margin-left: 10px;
      display: flex;
      flex-direction: column;

      .product-name {
        font-weight: bold;
        color: black;
        font-size: 16px;
        margin: 0;
      }

      .mrp {
        font-size: 14px;
        color: var(--grey);
        margin: 5px 0;

        span {
          text-decoration: line-through;
          color: var(--red);
        }
      }

      .price {
        font-size: 14px;
        color: var(--dark-blue);

        .price-per-unit {
          font-size: 12px;
          color: var(--grey);
          margin-left: 5px;
        }
      }
    }

    .product-actions {
      display: flex;
      flex-direction: column;

      .add-to-cart-button {
        padding: 8px 12px;
        font-size: 14px;
        border: none;
        border-radius: 4px;
        background-color: var(--dark-blue);
        color: var(--white);
        cursor: pointer;

       
      }
    }
  }
  .subscription-title {
    font-size: 16px;
    color: var(--dark-blue);
    margin: 20px 0;
  }


  .dropdown-section {
  

    .form-select {
      width: 100%;
      padding: 8px;
      border-radius: 4px;
      border: 1px solid var(--border);
    }
  }


  .weekly-selection {
    margin-top: 20px;
  
    label {
      color: var(--grey);
      margin-bottom: 10px;
    }

    .days-container {
      display: flex;
      justify-content: space-between;

      .day {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        background-color: var(--fade-grey);
        color: var(--grey);
        font-weight: bold;
        border-radius: 50%;
        text-align: center;
        cursor:pointer;
        &:hover,
        &.selected {
          background-color: var(--dark-blue);
          color: var(--white);
        }
      }
    }
    label {
     
      margin-bottom: 10px;
      color:var(--grey);
     }
  
    .dropdown {
      position: relative;
  
      .dropdown-toggle {
        text-align: left;
        position: relative;
        padding-right: 30px;
      }
  
      .dropdown-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;
      }
  
      .selected-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
  
        .tag {
          background-color: var(--grey);
          padding: 5px 10px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          color: var(--white);
  
          .remove-icon {
            margin-left: 5px;
            cursor: pointer;
            color: var(--white);
          }
        }
      }
  
      .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid var(--border);
  
        .dropdown-item {
          padding: 4px;
          cursor: pointer;
  
          .time-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            &.selected {
              color: var(--dark-blue);
              font-weight: bold;
            }
  
            .check-icon {
              color: var(--dark-blue);
              font-weight: bold;
            }
          }
  
          &:hover {
            background-color: var(--whitesmoke);
          }
        }
      }
  
      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 5px;
      }
    }
    .datepickers {
      display: flex;
      gap: 15px;
      margin-top: 10px;

      .datepicker-container {
        flex: 1;

        label {
          font-size: 14px;
          color: var(--grey);
          margin-bottom: 5px;
          display: block;
        }

        .date-input {
          width: 100%;
          padding: 6px;
          border-radius: 4px;
          border: 1px solid var(--border);
          color: var(--grey);
          text-align: center;
        }
      }
    }
  }

  .yearly-selection,
  .monthly-selection {
    margin-bottom: 20px;

    label {
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }
    
    .datepicker-container {
      position: relative;
      margin-bottom: 10px;

      .date-input {
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        color:var(--grey);
        border-radius: 4px;
        font-size: 14px;
        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }
    }

    .selected-dates {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 10px;
    
      .selected-date-tag {
        background-color: #f0f0f0;
        border: 1px solid #ddd;
        padding: 5px 10px;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 5px;
        color:var(--grey);
      
      
        .remove-icon {
          font-size: 12px;
          cursor: pointer;
          color: #dc3545;
          &:hover {
            color: #a71d2a;
          }
        }  
      
      }
    }
    .datepickers {
      display: flex;
      gap: 20px; 
      .datepicker-container {
        flex: 1; /* Ensure equal width for both fields */
        label {
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: bold;
        }
        .date-input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ddd;
          border-radius: 4px;
          font-size: 14px;
          &:focus {
            outline: none;
            border-color: #007bff;
          }
        }
      }
    
    
    
    }
  }

  .time-selection {
    .dropdown {
      .form-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
        color:var(--grey);
        cursor: pointer;

        .dropdown-icon {
          margin-left: auto;
          font-size: 16px;
        }

        &:hover,
        &:focus {
          outline: none;
          border-color: #007bff;
        }
      }

      .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid #ddd;
        border-radius: 4px;
        background-color: #fff;

        .dropdown-item {
          padding: 10px;
          cursor: pointer;

          &:hover {
            background-color: #007bff;
            color: #fff;
          }

          .time-option {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &.selected {
              background-color: #e9ecef;
            }

            .check-icon {
              color: #28a745;
            }
          }
        }
      }
    }
  }

  .save-button-container {
    margin-top: 20px;

    .save-button {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      background-color: #007bff;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  .error-message {
    color: #dc3545;
    font-size: 12px;
    margin-top: 5px;
  }
  
  

  .time-selection {
    label {
      margin-top: 10px;
      margin-bottom: 10px;
      color:var(--grey);
     }
  
    .dropdown {
      position: relative;
  
      .dropdown-toggle {
        text-align: left;
        position: relative;
        padding-right: 30px;
      }
  
      .dropdown-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        pointer-events: none;
      }
  
      .selected-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
  
        .tag {
          background-color: var(--grey);
          padding: 5px 10px;
          border-radius: 4px;
          display: inline-flex;
          align-items: center;
          color: var(--white);
  
          .remove-icon {
            margin-left: 5px;
            cursor: pointer;
            color: var(--white);
          }
        }
      }
  
      .dropdown-menu {
        max-height: 200px;
        overflow-y: auto;
        border: 1px solid var(--border);
  
        .dropdown-item {
          padding: 4px;
          cursor: pointer;
  
          .time-option {
            display: flex;
            justify-content: space-between;
            align-items: center;
  
            &.selected {
              color: var(--dark-blue);
              font-weight: bold;
            }
  
            .check-icon {
              color: var(--dark-blue);
              font-weight: bold;
            }
          }
  
          &:hover {
            background-color: var(--whitesmoke);
          }
        }
      }
  
      .error-message {
        color: var(--red);
        font-size: 12px;
        margin-top: 5px;
      }
    }
  
    .datepickers {
      display: flex;
      justify-content: space-between; /* Keeps the date pickers in the same row */
      gap: 20px; /* Adds spacing between the date pickers */
    
      .datepicker-container {
        display: flex;
        flex-direction: column; /* Stacks the label above the input */
        flex: 1; /* Ensures equal width for both date picker containers */
    
        label {
         
          margin-bottom: 5px; /* Adds spacing between the label and the input */
          text-align: left; /* Aligns the label text to the left */
        }
    
        .date-input {
          width: 100%; /* Makes the input span the full width of its container */
          padding: 4px; /* Adds padding for better click area */
          border-radius: 4px;
          border: 1px solid var(--border);
          background-color: var(--whitesmoke);
          text-align: center;
          color: var(--grey);
        }
      }
    }
    
  }


  .save-button-container {
    text-align: right;
    margin-top: 20px;

    .save-button {
      padding: 7px;
      background-color: var(--dark-blue);
      color: var(--white);
      border: none;
      border-radius: 4px;
      cursor: pointer;
      

    }
  }

  .error-message {
    color: var(--red);
    font-size: 12px;
    margin-top: 5px;
  }
}

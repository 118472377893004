.add-request-for-purchase1 {
    background-color: whitesmoke;
    padding: 20px;
    border:none;
   
    .add-request-page1 {
        padding: 20px;
        background-color: white;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      }
    
      .add-request-header-class1 {
        border: 1px solid #ccc;
        padding: 10px;
        border-radius: 10px;
    
        .add-request-header1 {
          font-weight: 400px;
          margin: 0px !important;
        }
      }
 
    // Invoice Details Container Styling
    .invoice-details-container1 {
      background-color: #fff;
      padding: 20px;
      border:none !important;
      margin-top: 20px; // Added margin-top for spacing
  
    
      .form-row {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        margin-bottom: 15px;
      }
  
      .form-group {
        display: flex;
        flex-direction: column;
        width: 48%;
      }
      .invoice-header1{
        padding:0px;
      }
  
      .date-picker1 {
        color:var(--grey);
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 8px 0; // Adjust padding to match the desired style
        font-size: 16px;
        width: 100%;
      
        &:focus {
          outline: none; // Remove default outline when the input is focused
          border-bottom: 1px solid #007bff; // Optional: change bottom border color on focus
        }
      
        &::placeholder {
          color: #999; // Optional: Style the placeholder text
        }
      }
      
      
    }
    .raw-material-details1 {
      background-color: white;
      border: 1px solid #ccc;
      padding: 10px;
      margin-top: 10px;
      border-radius: 10px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  
      .header1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #e6e6e6;
  
        .add-request-header2 {
            font-weight: 400px;
            margin: 0px !important;
        }
  
        .action-buttons {
          display: flex;
          gap: 15px;
  
          .add-new-buttons {
            display: flex;
            align-items: center;
            background-color: var(--white);
            color: var(--grey);
            border-radius: 4px;
            border: 1px solid #ccc;
          }
  
         
        }
      }
  
      .table-bordered1 {
        width: 100%;
        margin-top: 20px;
        border-collapse: collapse;
        padding: 5px;
        border-radius: 10px;
      
        th,
        td {
            font-weight: 400px;
            margin: 0px !important;
          padding: 10px;
          border-bottom: 1px solid #ddd;
        }
      
        th {
          background-color: #ececec;
          text-align: left;
          font-weight: 400px;
          margin: 0px !important;
        }
      
        td {
          background-color: #fff;
          text-align: left;
        }
      
        // Input with only bottom border
        .table-input1 {
          border: none;
          border-bottom: 1px solid #ddd;
          width: 100%;
          padding: 5px 0;
          font-size: 1rem;
          color: #333;
          background-color: transparent;
      
          &:focus {
            outline: none;
            border-bottom: 1px solid #007bff; // Bottom border change on focus
          }
      
          &::placeholder {
            color: #999; // Style the placeholder text
            font-size: 0.875rem;
          }
        }
      
        // Dropdown styled with only bottom border
        .dropdown-button1 {
          width: 100%;
          padding: 5px 0;
          border: none;
          border-bottom: 1px solid #ced4da !important;
          background-color: transparent;
          font-size: 1rem;
          color: #333;
          text-align: left;
      
          &:focus,
          &:active {
            outline: none;
            border-bottom: 1px solid #007bff; // Bottom border change on focus
            box-shadow: none; // Remove any shadow effects Bootstrap might add
          }
        }
      
        .actions1 {
          text-align: center;
      
          .action-icon1 {
            cursor: pointer;
            margin-left: 10px;
            font-size: 1.2rem;
            color: #333;
            
            &:hover {
              color: #007bff; // Optional: change color on hover
            }
          }
        }
      }
      
      .footer-buttons1 {
        margin-top: 30px;
        padding: 10px;
        display: flex;
        justify-content: flex-end;
        background-color: #effbff;
        gap:15px;

        .btn-cancel1 {
          background-color: var(--white);
          border: 1px solid var(--dark-blue);
          color: var(--dark-blue);
         padding:3px;
          border-radius: 4px;
        }
  
        .btn-save1 {
         background-color: var(--dark-blue);
            border: none;
        }
      }
    }
  
    // Media queries for responsiveness using min-widths
    @media (max-width: 1400px) {
      .form-row {
        flex-direction: row !important;
      }
      .form-group {
        width: 48%;
      }
    }
  
    @media (max-width: 1200px) {
      .form-row {
        flex-direction: row !important;
      }
      .form-group {
        width: 48%;
      }
    }
  
    @media (max-width: 992px) {
      .form-row {
        flex-direction: row !important;
      }
      .form-group {
        width: 48%;
      }
    }
  
    @media (max-width: 768px) {
      .form-row {
        flex-direction: column !important;
      }
      .form-group {
        width: 100% !important;
      }
     
      .table-bordered1 {
        overflow-x: auto;
        display: block;
        width: 100%;
      }
    }
    @media (max-width: 576px) {
      .form-row {
        flex-direction: column !important;
      }
    
      .form-group {
        width: 100% !important;
      }
    
      
      .table-bordered1 {
        overflow-x: auto;
        display: block;
        width: 100%;
      }
    }
    // Apply column layout for screens 575.98px and below
    @media (max-width: 575.98px) {
      .form-row {
        flex-direction: column !important;
      }
      .form-group {
        width: 100% !important;
      }
  
      
  
      .table-bordered1 {
        overflow-x: auto;
        display: block;
        width: 100%;
      }
    }
  }
  
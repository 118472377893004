.bulk-brand-main-page {
  background-color: var(--whitesmoke);
  padding: 20px;

  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 16px;
    color: var(--grey);
    background-color: var(--white);

    .breadcrumb-icon {
      color: var(--grey);
      margin-right: 8px;
    }

    .breadcrumb-text {
      margin: 0 5px;
      text-decoration: none;
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    .breadcrumb-separator {
      margin: 0 5px;
      color: var(--fade-grey);
    }
  }

  .bulk-brand-container {
    padding: 5px;
    border-radius: 10px;
    background-color: var(--whitesmoke);
  }

  .bulk-brand {
    padding: 20px;
    background-color: var(--white);
    border-radius: 10px;

    .brand-table {
      background-color: var(--white);
      border-radius: 10px;

      .header-row {
        display: flex;
      
        justify-content: space-between;
      

      .text-heading1 {
        padding: 5px !important;
        text-align: left !important;
        align-self: flex-start;
      }

      .add-new-button {
        display: flex;
       
        gap: 15px;

        .add-button,
          .save-button {
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            padding: 5px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;

            &:disabled {
              background-color: var(--grey);
              cursor: not-allowed;
            }
          
  }
}
      }
    }
  
    .table-container {
      position: relative; /* For positioning the scroll icon */
      margin-top: 40px;
      overflow-x: auto; /* Enable horizontal scrolling */
      overflow-y: auto; /* Enable vertical scrolling */
      height: 300px; /* Fixed height for vertical scrolling */
      white-space: nowrap; /* Prevent text wrapping */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: thin; /* Firefox */
    
      /* Custom scrollbar for Webkit browsers */
      &::-webkit-scrollbar {
          height: 8px; /* Height of the horizontal scrollbar */
      }
    
      &::-webkit-scrollbar-track {
        background: transparent; /* Background of the scrollbar track */
      }
    
      &::-webkit-scrollbar-thumb {
        background: var(--whitesmoke); /* Thumb color */
          border-radius: 4px;
      }
    
      &::-webkit-scrollbar-thumb:hover {
          background: var(--very-light-grey); /* Thumb color on hover */
      }
    
      .custom-table {
          width: 100%; /* Ensure the table takes full width */
          border-collapse: collapse;
          table-layout: fixed; /* Consistent column widths */
    
          thead {
              th {
                  position: sticky; /* Sticky header */
                  top: 0; /* Fix header at the top of the container */
                  z-index: 2;
                  background-color: var(--th);
                  padding: 12px;
                  text-align: center;
                  border-bottom: 2px solid var(--border-bottom);
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                  color: var(--grey);
              }
          }
    
          tbody {
              td {
                  padding: 12px;
                  text-align: center;
                  border-bottom: 1px solid var(--border-bottom);
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                  color: var(--grey);
                  height: 50px;
                  box-sizing: border-box;
              }
    
              td input {
                  padding: 10px;
                  width: 100%;
                  border: none;
                  border-bottom: 1px solid var(--border-bottom);
                  outline: none;
                  font-family: 'Poppins', sans-serif;
              }
          }
      }
    
      .icon-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          font-size: 16px;
          color: var(--grey);
    
          &:hover {
              color: var(--black);
          }
      }
    }

    .button-row {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 20px;
      gap: 15px;

      .next-button {
        background-color: var(--dark-blue);
        color:  var(--white);
        border: none;
        border-radius: 4px;
        padding: 10px 20px;
        cursor: pointer;
        font-weight: bold;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--hover-blue);
        }
      }
    }
  
  }


@media (max-width: 575.98px) {
  
  .bulk-brand {
    .brand-table {
      .header-row {
        display: flex;
        flex-direction: column; /* Stack heading and buttons vertically */
        align-items: flex-start; /* Align heading and buttons to the left */
    
        .text-heading1 {
          margin-bottom: 10px; /* Add spacing between heading and buttons */
          text-align: left;
        }
    
        .add-new-button {
          display: flex; /* Place buttons in a row */
          flex-direction: row; /* Ensure buttons are in a single row */
          gap: 10px; /* Spacing between buttons */
    
          .add-button,
          .save-button {
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            padding: 5px;
            cursor: pointer;
            font-weight: bold;
            transition: background-color 0.3s ease;
    
            &:disabled {
              background-color: var(--grey);
              cursor: not-allowed;
            }
          }
        }
      }
    
    
  }
  }
  .table-container {
    overflow-x: scroll;
    white-space: nowrap; /* Prevent shrinking */
    height: auto; /* Allow the container to adjust height dynamically */

    .custom-table {
      width: auto; /* Prevent shrinking of the table */
      min-width: 600px; /* Ensure a minimum width for scrolling */
    }
  }

}

@media (min-width: 576px) and (max-width: 767.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 600px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .table-container {
    overflow-x: scroll;
    white-space: nowrap;

    .custom-table {
      width: auto;
      min-width: 800px; /* Slightly larger minimum width for medium devices */
    }
  }
}
/* Media Queries for Different Screen Sizes */
@media (max-width: 1024px) {
  .bulk-brand .table-container {
    max-height: calc(100vh - 200px); /* Adjust height for medium screens */
  }
}
}
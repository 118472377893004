.success-page {
  display: flex; /* Default row layout */
  flex-direction: column; /* Default to column layout */
  -webkit-box-pack: center;
  margin: 0px;
  padding: 20px;

  .left-section {
    margin: 0px;
    border-radius: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: 100vh; /* Set maximum height to control scrolling area */
    
    /* Hide scrollbar styles for various browsers */
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none; /* Hide scrollbar in Internet Explorer and Edge */
    
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .number {
      font-weight: 700;
      background-color: #e6f7ff;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
    }

    .status {
      text-align: left; /* Align text to the left */
      padding: 10px;
      border-radius: 5px;
      font-weight: bold;
      color: #fff; /* White text for contrast */
      text-transform: capitalize; /* Capitalize the first letter of each word */
      margin-bottom:15px;
  
        
      &.failed {
        background-color: #dc3545; /* Red for failed */
      }
  
      &.success {
        background-color: #027a34; /* Green for success */
      }
    }
    

    .delivery-info {
      background-color: #e6f7ff;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-bottom: 10px;
      
      .delivery-date {
        color: #333;
      }
    }

    .progress-tracker {
      position: relative;
      margin-bottom: 20px;

      .progress-step {
        text-align: center;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
      }

      .completed {
        color: rgb(130, 188, 130); /* Different color for completed steps */
      }

      .popover {
        max-width: 250px;
      }
    }

    .order-details {
      margin: 20px 0;
      color: var(--grey);

      .step-text {
        font-weight: bold;
        margin-bottom: 10px;
      }

      .status-message {
        background-color: #d0e7ff;
        padding: 10px;
        border-radius: 5px;
        margin-bottom: 20px;
      }
    }

    .view-details-box {
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 20px;
      text-align: center;

      .view-details-button {
        border: 1px solid blue;
        color: var(--dark-blue);
        border: none;
        margin: 0px;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .ordered-items {
      margin-top: 20px;
      padding: 10px;
      border-radius: 5px;

      .ordered-items-header {
        margin-bottom: 10px;
      }
    }

    .delivery-address {
      margin-top: 20px;
      border: 1px solid #ddd;
      padding: 10px;
      border-radius: 5px;

      .delivery-address-header {
        font-weight: 700;
        margin-bottom: 5px;
      }
    }

    .additional-info {
      margin-top: 20px;

      .info-box {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 10px;

        .info-item {
          display: flex;
          justify-content: space-between;
          padding: 5px 0;

          .info-title {
            font-weight: bold;
            color: #333;
          }

          .info-detail {
            margin-left: 10px;
            color: #555;
          }
        }
      }

      .info-item1 {
        border: 1px solid #ddd;
        padding: 10px;
        border-radius: 5px;
        margin-top: 10px; /* Optional: Add margin for spacing */
        display: flex; /* Flexbox for alignment */
        justify-content: space-between; /* Aligns items horizontally with space between */
        align-items: center; /* Center-aligns items vertically */

        .info-title1 {
          font-weight: bold;
          color: #333;
        }

        .info-detail1 {
          margin-left: auto; /* Pushes this item to the right */
          color: #555;
        }
      }


      .order-cancelled {
       
        border-radius: 5px;

        .cancelled-header {
          width: 100%;
          font-weight: bold;
          margin-top: 10px;
         
          padding: 5px;
          background-color: #e6f7ff;

          .cancelled-title {
           
            color: #333;
          }

          .cancelled-date {
            color: #555;
            margin-left: 10px;
          }
        }
        .return-button{
          width:100%;
          margin-top: 10px;
          background-color: white;
          color:var(--dark-blue);
          border: 1px solid #ddd;
        }

        .cancelled-reason {
          margin-top: 10px;
          color: #666;
        }
      }
    }
  }

  .right-section {
    margin: 0px;
    background-color: white;
    width: 35%;
  }
}

/* Column layout for small devices */
@media (max-width: 575.98px) {
  .success-page {
    flex-direction: column; /* Column layout for medium devices */

    .left-section {
      display: block !important; /* Ensure both sections are visible */
      width: 100% !important;
    }
    .right-section {
      display: block !important; /* Ensure both sections are visible */
      width: 100% !important;
    }
  }
}

/* Column layout for small devices */
@media (min-width: 576px) {
  .success-page {
    flex-direction: column; /* Column layout for medium devices */

    .left-section,
    .right-section {
      display: block; /* Ensure both sections are visible */
      width: 100%;
    }
  }
}

/* Column layout for larger small devices */
@media (min-width: 768px) {
  .success-page {
    flex-direction: column; /* Column layout for larger small devices */

    .left-section,
    .right-section {
      display: block; /* Ensure both sections are visible */
      width: 100%;
    }
  }
}

/* Row layout for larger screens */
@media (min-width: 992px) {
  .success-page {
    flex-direction: row; /* Row layout for large devices */
    min-height: 100dvh;
    -webkit-box-pack: center;
    margin: 0 50px;
    padding: 20px;

    .left-section {
      display: block; /* Ensure both sections are visible */
      width: 70%; /* Adjust width for larger screens */
      padding: 20px;
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    }

    .left-section::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section {
      display: block; /* Ensure both sections are visible */
      width: 30%;
      background-color: white;
      padding: 20px;
    }
  }
}

/* Additional media queries for larger devices */
@media (min-width: 1200px) {
  .success-page {
    flex-direction: row; /* Row layout for extra large devices */
    min-height: 100dvh;
    -webkit-box-pack: center;
    margin: 0 50px;
    padding: 20px;

    .left-section {
      display: block; /* Ensure both sections are visible */
      width: 70%;
      padding: 20px;
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    }

    .left-section::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section {
      display: block; /* Ensure both sections are visible */
      width: 30%;
      background-color: white;
      padding: 20px;
    }
  }
}

@media (min-width: 1400px) {
  .success-page {
    flex-direction: row; /* Row layout for even larger screens */
    min-height: 100dvh;
    margin: 0 50px;
    padding: 20px;

    .left-section {
      display: block; /* Ensure both sections are visible */
      overflow-y: auto; /* Enable scrolling */
      scrollbar-width: none; /* Hide scrollbar in Firefox */
      -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
    }

    .left-section::-webkit-scrollbar {
      display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
    }

    .right-section {
      display: block; /* Ensure both sections are visible */
    }
  }
}
.cancel-order {
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center horizontally */
  margin-top: 20px; /* Add space above */

  .cancel-button {
    background-color: #dc3545; /* Red color for the button */
    color: #fff; /* White text color */
    border: none; /* Remove default border */
    padding: 15px 20px; /* Add padding for better size */
    font-size: 16px; /* Larger font size */
    font-weight: bold; /* Bold text */
    border-radius: 5px; /* Rounded corners */
    width: 60%; /* Increase width to make it prominent */
    text-align: center; /* Center the text inside the button */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */

    &:hover {
      background-color: #c82333; /* Darker red on hover */
    }
  }
}
.ordered-items {
  margin-top: 20px;

  .ordered-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 15px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    .item-info {
      display: flex;
      flex: 1;
      align-items: center;

      .item-image {
        width: 50px;
        height: 50px;
        margin-right: 15px;

        img {
          max-width: 100%;
          max-height: 100%;
          border-radius: 5px;
        }
      }

      .item-details {
        display: flex;
        flex-direction: column;

        .item-title {
          font-weight: bold;
          font-size: 16px;
          margin-bottom: 5px;
          color: #333;
        }

        .item-price {
          font-size: 14px;
          margin-bottom: 5px;

          .mrp {
            text-decoration: line-through;
            color: #999;
            margin-right: 10px;
          }

          .discount {
            color: #28a745;
            font-weight: bold;
          }
        }
      }
    }

    .quantity {
      font-size: 14px;
      font-weight: bold;
      color: #333;
      text-align: right; /* Align the text to the right */
    }
  }
}
.order {
 

  .bordered-box {
    border: 1px solid#dad7d7;; /* Add black border for nested elements */
    padding: 15px; /* Inner padding */
    margin-bottom: 15px; /* Spacing between items */
    border-radius: 5px; /* Rounded corners */
    // background-color: #f9f9f9; /* Optional: Light background */
  }



  .address-details {
    margin-bottom: 10px; /* Spacing below the address details */
    font-weight: bold; /* Highlight the address title */
    color: #555; /* Darker text color */
  }

  .address-label {
    font-weight: bold; /* Highlight the address type */
    color: #555; /* Slightly muted text color */
  }

  .order-cancel-details {
    font-weight: bold;
    color: #b71c1c; /* Red color for cancel details */
   
  }
}

.textbox-component {
    
    flex-direction: column;
   
  
    label {
      font-weight: 600;
      margin-bottom: 5px;
     
    }
  
    textarea {
      width: 100%;  
      border: 1px solid #ddd;
      border-radius: 5px;
     padding:3px;
      transition: border-color 0.3s ease; /* Smooth hover effect */
      
      &:hover {
        border-color: black; /* Change border color on hover */
      }
  
      &:focus {
        outline: none;
        border-color: var(--grey); /* Focus state */
      }
    }
  }
  
.subscription-checkout-section {
  background-color: var(--whitesmoke);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;

  .subscription-checkout-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; /* Align content at the top */
    gap: 20px;
    background-color: var(--white);

    .left-section {
      flex: 2; /* Take up more space */
      padding: 20px;
      border-radius: 8px;
      background-color: var(--white);

      .subscription-checkout-selected-address-card {
        padding: 16px;
        border-radius: 8px;
        background-color: var(--white);

        .subscription-checkout-address-header {
          display: flex;
          align-items: center;
          gap: 10px;

          .location-icon {
            color: var(--black);
            font-size: 20px;
          }

          .deliver-to {
            font-weight: 500;
            color: var(--black);
          }

          .address-name {
            font-weight: bold;
            color: var(--black);
          }

          .subscription-checkout-change-address-btn {
            margin-left: auto;
            background: none;
            border: none;
            color: var(--dark-blue);
            font-weight: 500;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        .subscription-checkout-address-details {
          margin-top: 8px;

          .address-line {
            color: var(--grey);
            line-height: 1.5;
          }

          .delivery-date {
            color: var(--green);
            font-weight: 500;
            margin-top: 4px;

            strong {
              font-weight: bold;
            }
          }
        }
      }

      .subscription-items-header2 {
        font-weight: bold;
        margin: 20px 0;
        color: var(--grey);
      }

      .subscription-checkout-card-items-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 10px;
      
        .subscription-checkout-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: var(--white);
          border: 1px solid var(--border);
          border-radius: 8px;
          padding: 15px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      
          .subscription-card-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 20%; /* Adjust width as per design */
      
            img {
              width: 30px;
              height: 30px;
              object-fit: contain;
              margin-bottom: 5px;
            }
      
            .subscription-card-title {
             
              font-weight: 500;
              text-align: center;
              color: var(--grey-dark);
            }
          }
      
          .subscription-card-details {
            width: 50%; /* Adjust width as per design */
            padding-left: 15px;
      
            .product-title {
             
              font-weight: bold;
              color: var(--grey);
            }
      
            .product-price {
             
              color: var(--grey);
              margin-top: 5px;
      
              .product-mrp {
                text-decoration: line-through;
                margin-left: 10px;
                color: var(--light-grey);
              }
      
              .product-discount {
                margin-left: 10px;
                color: var(--green);
                font-weight: bold;
              }
            }
          }
      
          .subscription-card-quantity {
            width: 20%; /* Adjust width as per design */
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      

      .subscription-checkout {
        border: 1px solid var(--border);
        border-radius: 8px;
        padding: 15px;
        .subscription-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
      
          .subscription-title {
            font-size: 16px;
            color: var(--dark-blue);
          }
      
          .edit-icon {
            cursor: pointer;
            color: var(--dark-blue);
            font-size: 18px;
          }
        }
      
      
        .dropdown-section {
        
      
          .form-select {
            width: 100%;
            padding: 8px;
            border-radius: 4px;
            border: 1px solid var(--border);
          }
        }
        .time-selection {
          margin-bottom: 1rem;
        }
        .disabled-dropdown button {
          pointer-events: none; /* Prevent interaction */
          opacity: 0.6; /* Dim the dropdown */
          cursor: not-allowed; /* Show "not-allowed" cursor */
        }
        
        
        label {
          display: block;
          font-size: 14px;
          margin-bottom: 8px;
        }
        
        .form-control {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #dcdcdc;
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
        }
        
        .dropdown-menu {
          list-style: none;
          margin: 0;
          padding: 0;
          border: 1px solid #dcdcdc;
          background: #fff;
          position: absolute;
          z-index: 10;
          width: 100%;
          max-height: 200px;
          overflow-y: auto;
        }
        
        .dropdown-item {
          padding: 8px 12px;
          cursor: pointer;
        }
        
        .time-option {
          padding: 8px 12px;
          border-radius: 4px;
          cursor: pointer;
        }
        
        .time-option.selected {
          background-color: #444;
          color: #fff;
        }
        
      
        .monthly-selection {
          margin-bottom: 20px;
        
          label {
            font-weight: bold;
            display: block;
            margin-bottom: 10px;
          }
        
          .selected-dates {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-bottom: 10px;
        
            .selected-date-tag {
              background-color: #f0f0f0;
              border: 1px solid #ddd;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: 14px;
              display: flex;
              align-items: center;
              gap: 5px;
        
              .remove-icon {
                font-size: 12px;
                cursor: pointer;
                color: #dc3545;
        
                &:hover {
                  color: #a71d2a;
                }
              }
            }
          }
        
          .datepicker-container {
            position: relative;
            margin-bottom: 10px;
        
            .date-input {
              width: 100%;
              padding: 10px;
              border: 1px solid #ddd;
              border-radius: 4px;
              font-size: 14px;
        
              &:focus {
                outline: none;
                border-color: #007bff;
              }
            }
          }
        
          .datepickers {
            display: flex;
            gap: 20px;
        
            .datepicker-container {
              flex: 1;
        
              label {
                display: block;
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: bold;
              }
            }
          }
        }
        
      
        .monthly-selection {
          margin-top: 20px;
          label {
            color: var(--grey);
             margin-bottom: 10px;
            
           }
      
           .datepicker-containers {
            width: 100% !important;
          
          
            .date-input1 {
              width: 100% ; /* Ensures full width of container */
              padding: 4px;
              border-radius: 4px;
              border: 1px solid var(--border);
              background-color: var(--whitesmoke);
              text-align: center;
              box-sizing: border-box; /* Includes padding and border in the width */
            }
          }
          
          .datepickers {
            margin-top:10px;
            display: flex;
            justify-content: space-between; /* Keeps the date pickers in the same row */
            gap: 20px; /* Adds spacing between the date pickers */
          
            .datepicker-container {
              display: flex;
              flex-direction: column; /* Stacks the label above the input */
              flex: 1; /* Ensures equal width for both date picker containers */
          
              label {
               
                margin-bottom: 5px; /* Adds spacing between the label and the input */
                text-align: left; /* Aligns the label text to the left */
              }
          
              .date-input {
                width: 100%; /* Makes the input span the full width of its container */
                padding: 4px; /* Adds padding for better click area */
                border-radius: 4px;
                border: 1px solid var(--border);
                background-color: var(--whitesmoke);
                text-align: center;
              }
            }
          }
          
        }
        .weekly-selection {
          margin-top: 20px;
        
          label {
            color: var(--grey);
            margin-bottom: 10px;
          }
      
          .days-container {
            display: flex;
            justify-content: space-between;
      
            .day {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%;
              text-align: center;
              cursor:pointer;
              &:hover,
              &.selected {
                background-color: var(--dark-blue);
                color: var(--white);
              }
            }
          }
          label {
           
            margin-bottom: 10px;
            color:var(--grey);
           }
        
          .dropdown {
            position: relative;
        
            .dropdown-toggle {
              text-align: left;
              position: relative;
              padding-right: 30px;
            }
        
            .dropdown-icon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              pointer-events: none;
            }
        
            .selected-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
        
              .tag {
                background-color: var(--grey);
                padding: 5px 10px;
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                color: var(--white);
        
                .remove-icon {
                  margin-left: 5px;
                  cursor: pointer;
                  color: var(--white);
                }
              }
            }
        
            .dropdown-menu {
              max-height: 200px;
              overflow-y: auto;
              border: 1px solid var(--border);
        
              .dropdown-item {
                padding: 4px;
                cursor: pointer;
        
                .time-option {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
        
                  &.selected {
                    color: var(--dark-blue);
                    font-weight: bold;
                  }
        
                  .check-icon {
                    color: var(--dark-blue);
                    font-weight: bold;
                  }
                }
        
                &:hover {
                  background-color: var(--whitesmoke);
                }
              }
            }
        
            .error-message {
              color: var(--red);
              font-size: 12px;
              margin-top: 5px;
            }
          }
          .datepickers {
            display: flex;
            gap: 15px;
            margin-top: 10px;
      
            .datepicker-container {
              flex: 1;
      
              label {
                font-size: 14px;
                color: var(--grey);
                margin-bottom: 5px;
                display: block;
              }
      
              .date-input {
                width: 100%;
                padding: 6px;
                border-radius: 4px;
                border: 1px solid var(--border);
                color: var(--grey);
                text-align: center;
              }
            }
          }
        }
        .yearly-selection {
          margin-top: 20px;
          padding: 10px;
          border-radius: 8px;
          background-color: #f9f9f9;
        
          label {
            font-weight: 600;
            margin-bottom: 8px;
            display: block;
          }
        
          .datepicker-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 8px;
          }
        
          .datepickers {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            margin-top: 10px;
        
            .datepicker-container {
              flex: 1;
        
              .date-input {
                width: 100%;
                padding: 8px;
                border-radius: 5px;
                border: 1px solid #ccc;
                font-size: 14px;
              }
            }
          }
        
          .selected-dates {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            margin-top: 10px;
          
            .selected-date-tag {
              display: flex;
              align-items: center;
              justify-content: space-between;
              background-color: #f2f2f2;
              color: #333;
              padding: 8px 14px;
              border-radius: 12px;
              font-size: 16px;
              font-weight: 500;
              min-width: 80px;
              box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
              position: relative;
          
              .remove-icon {
                margin-left: 12px;
                cursor: pointer;
                font-size: 14px;
                color: #d9534f;
                transition: all 0.2s ease-in-out;
                padding: 5px;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                border: 1px solid #d9534f;
          
                &:hover {
                  background-color: #d9534f;
                  color: white;
                }
          
                &:active {
                  transform: scale(0.9);
                }
              }
            }
          }
          
          /* ✅ Responsive Styling for iPads */
          @media (max-width: 1024px) {
            .selected-dates {
              justify-content: center;
            }
          
            .selected-date-tag {
              font-size: 14px;
              padding: 6px 12px;
              min-width: 75px;
            }
          
            .remove-icon {
              font-size: 12px;
              margin-left: 8px;
              width: 18px;
              height: 18px;
              padding: 4px;
            }
          }
          
          
          /* iPad Specific Styles */
          @media (max-width: 1024px) {
            .selected-dates {
              justify-content: center;
            }
          
            .selected-date-tag {
              font-size: 14px;
              padding: 5px 10px;
              min-width: 60px;
            }
          
            .remove-icon {
              font-size: 12px;
              margin-left: 8px;
              width: 16px;
              height: 16px;
            }
          }
          
          /* iPad Specific Styles */
          @media (max-width: 1024px) {
            .selected-dates {
              justify-content: center; // Center align on iPads
            }
          
            .selected-date-tag {
              font-size: 14px;
              padding: 5px 10px;
              min-width: 60px;
            }
          
            .remove-icon {
              font-size: 12px;
              margin-left: 8px;
            }
          }
        }
        
        
        
      
        .time-selection {
          label {
            margin-top: 10px;
            margin-bottom: 10px;
            color:var(--grey);
           }
        
          .dropdown {
            position: relative;
        
            .dropdown-toggle {
              text-align: left;
              position: relative;
              padding-right: 30px;
            }
        
            .dropdown-icon {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              pointer-events: none;
            }
        
            .selected-tags {
              display: flex;
              flex-wrap: wrap;
              gap: 5px;
        
              .tag {
                background-color: var(--grey);
                padding: 5px 10px;
                border-radius: 4px;
                display: inline-flex;
                align-items: center;
                color: var(--white);
        
                .remove-icon {
                  margin-left: 5px;
                  cursor: pointer;
                  color: var(--white);
                }
              }
            }
        
            .dropdown-menu {
              max-height: 200px;
              overflow-y: auto;
              border: 1px solid var(--border);
        
              .dropdown-item {
                padding: 4px;
                cursor: pointer;
        
                .time-option {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
        
                  &.selected {
                    color: var(--dark-blue);
                    font-weight: bold;
                  }
        
                  .check-icon {
                    color: var(--dark-blue);
                    font-weight: bold;
                  }
                }
        
                &:hover {
                  background-color: var(--whitesmoke);
                }
              }
            }
        
            .error-message {
              color: var(--red);
              font-size: 12px;
              margin-top: 5px;
            }
          }
        
          .datepickers {
            display: flex;
            justify-content: space-between; /* Keeps the date pickers in the same row */
            gap: 20px; /* Adds spacing between the date pickers */
          
            .datepicker-container {
              display: flex;
              flex-direction: column; /* Stacks the label above the input */
              flex: 1; /* Ensures equal width for both date picker containers */
          
              label {
               
                margin-bottom: 5px; /* Adds spacing between the label and the input */
                text-align: left; /* Aligns the label text to the left */
              }
          
              .date-input {
                width: 100%; /* Makes the input span the full width of its container */
                padding: 4px; /* Adds padding for better click area */
                border-radius: 4px;
                border: 1px solid var(--border);
                background-color: var(--whitesmoke);
                text-align: center;
              }
            }
          }
          
        }
      
      
        .save-button-container {
          text-align: right;
          margin-top: 20px;
      
          .save-button {
            padding: 7px;
            background-color: var(--dark-blue);
            color: var(--white);
            border: none;
            border-radius: 4px;
            cursor: pointer;
      
          }
        }
      
        .error-message {
          color: var(--red);
          font-size: 12px;
          margin-top: 5px;
        }
      
    
  }
    .right-section2 {
      display: block;
        width: 100%;
        margin: 0px;/* Full width for smaller screens */
    }
  }
}
  }
  @media (max-width: 576px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: column; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      display: block;
      width: 100%;
      margin: 0px;
      overflow-y: visible; /* Disable scrolling for this screen size */
      max-height: none; 
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      .subscription-checkout-body {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        width: 100%;
    
        .subscription-checkout-time-dates-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center; /* Center align the content */
          justify-content: space-between; /* Distribute items evenly */
          gap: 15px; /* Add spacing between sections */
          width: 100%; /* Ensure full width */
    
          .subscription-checkout-time-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start; /* Align items to the left */
            gap: 8px; /* Add spacing between items */
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
              margin-right: 5px;
            }
          }
    
          .subscription-checkout-months-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
            }
          }
        }
    
        .subscription-checkout-selected-days-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center;
          gap: 15px;
    
          .subscription-checkout-days-section,
          .subscription-checkout-selected-dates {
            display: flex;
            align-items: center;
            gap: 10px;
    
            .subscription-checkout-label {
              font-weight: bold;
              margin-right: 5px;
              color: var(--fade-black);
            }
    
            .subscription-checkout-dates-container,
            .subscription-checkout-days-container {
              display: flex;
              gap: 8px; /* Add spacing between items */
              flex-wrap: wrap; /* Allow items to wrap */
            }
    
            .subscription-checkout-day,
            .subscription-checkout-date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
    
  }
    .right-section2 {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}
  }
  @media (min-width: 576px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: column; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      display: block;
      width: 100%;
      margin: 0px;
      overflow-y: visible; /* Disable scrolling for this screen size */
      max-height: none; 
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      .subscription-checkout-body {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        width: 100%;
    
        .subscription-checkout-time-dates-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center; /* Center align the content */
          justify-content: space-between; /* Distribute items evenly */
          gap: 15px; /* Add spacing between sections */
          width: 100%; /* Ensure full width */
    
          .subscription-checkout-time-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start; /* Align items to the left */
            gap: 8px; /* Add spacing between items */
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
              margin-right: 5px;
            }
          }
    
          .subscription-checkout-months-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
            }
          }
        }
    
        .subscription-checkout-selected-days-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center;
          gap: 15px;
    
          .subscription-checkout-days-section,
          .subscription-checkout-selected-dates {
            display: flex;
            align-items: center;
            gap: 10px;
    
            .subscription-checkout-label {
              font-weight: bold;
              margin-right: 5px;
              color: var(--fade-black);
            }
    
            .subscription-checkout-dates-container,
            .subscription-checkout-days-container {
              display: flex;
              gap: 8px; /* Add spacing between items */
              flex-wrap: wrap; /* Allow items to wrap */
            }
    
            .subscription-checkout-day,
            .subscription-checkout-date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
    
  }
    .right-section2 {
      display: block;
      width: 100%;
      margin: 0px;
    }
  }
}
  }
  @media (min-width: 768px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: column; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      display: block;
      width: 100%;
      margin: 0px;
      overflow-y: visible; /* Disable scrolling for this screen size */
      max-height: none;
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      .subscription-checkout-body {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        width: 100%;
    
        .subscription-checkout-time-dates-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center; /* Center align the content */
          justify-content: space-between; /* Distribute items evenly */
          gap: 15px; /* Add spacing between sections */
          width: 100%; /* Ensure full width */
    
          .subscription-checkout-time-slots {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start; /* Align items to the left */
            gap: 8px; /* Add spacing between items */
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
              margin-right: 5px;
            }
          }
    
          .subscription-checkout-months-section {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 8px;
    
            .subscription-checkout-label {
              font-weight: bold;
              color: var(--fade-black);
              margin-right: 5px;
            }
    
            .subscription-checkout-value {
              color: var(--grey);
            }
          }
        }
    
        .subscription-checkout-selected-days-row {
          display: flex;
          flex-wrap: wrap; /* Allow wrapping of items */
          align-items: center;
          gap: 15px;
    
          .subscription-checkout-days-section,
          .subscription-checkout-selected-dates {
            display: flex;
            align-items: center;
            gap: 10px;
    
            .subscription-checkout-label {
              font-weight: bold;
              margin-right: 5px;
              color: var(--fade-black);
            }
    
            .subscription-checkout-dates-container,
            .subscription-checkout-days-container {
              display: flex;
              gap: 8px; /* Add spacing between items */
              flex-wrap: wrap; /* Allow items to wrap */
            }
    
            .subscription-checkout-day,
            .subscription-checkout-date {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 25px;
              height: 25px;
              background-color: var(--fade-grey);
              color: var(--grey);
              font-weight: bold;
              border-radius: 50%; /* Circular shape */
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
    
  }
    .right-section2 {
      display: block;
        width: 100%;
        margin: 0px;/* Full width for smaller screens */
    }
  }
}
  }
  
  @media (min-width: 992px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: row; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      flex: 2; 
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      
    }
    
  }
    .right-section2 {
     
      flex:1; /* Full width for smaller screens */
    }
  }
  }
}
  @media (min-width: 1200px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: row; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      flex: 2; 
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      
    }
    
  }
    .right-section2 {
     
      flex:1; /* Full width for smaller screens */
    }
  }
  }
}
 
  @media (min-width: 1400px) {
    .subscription-checkout-section {
    .subscription-checkout-container {
      flex-direction: row; /* Stack sections vertically */
      gap: 15px;
    
  
    .left-section{
      flex: 2; 
      
    .subscription-checkout {
      flex-wrap: wrap; /* Move items down when they exceed the width */
      display: flex;
      flex-direction: column;
    
      
    }
    
  }
    .right-section2 {
     
      flex:1; /* Full width for smaller screens */
    }
  }
  } }
.email-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .email-modal-content {
      background-color: white;
      padding: 20px;
      border-radius: 8px;
      width: 400px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
  
    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }
  
    .cancel-btn {
      background-color: white;
      border: 1px solid var(--dark-blue);
      padding: 5px 15px;
      color: var(--dark-blue);
      border-radius: 4px;
      cursor: pointer;
    }
  
    .save-btn {
      background-color: var(--dark-blue);
      color: var(--white);
      border: none;
      padding: 5px 15px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  
  @media (max-width: 575.98px) {
    .email-modal-content {
        padding: 5px;
        width: 350px !important; /* Smaller width for smaller screens */
      }
   
  }
  
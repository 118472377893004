.details-table{
  padding:20px;
  background-color: var(--whitesmoke);
  .breadcrumb-container {
    display: flex;
    align-items: center;
    padding: 10px ;
    font-size: 16px;
    color: var(--grey);

  background-color: var(--white);

  .breadcrumb-icon {
    color: var(--grey);
    margin-right: 8px;
  }

  .breadcrumb-text {
    margin: 0 5px;
    text-decoration: none; // Remove underline
    color: inherit;
&:hover {
  text-decoration: none; // Ensure no underline on hover

}
  }

  .breadcrumb-separator {
    margin: 0 5px;
    color: var(--fade-grey);
  }
  }

  .basic-details-container{
    padding:5px;
    border-radius: 10px;

    background-color: var(--whitesmoke);
  }
.details-table-container{
  background-color: var(--white);
  border-radius: 10px;
  padding: 20px;
 width:100%;
  margin: 0 auto;
  
  .text-heading1 {
    padding: 10px !important;
    margin-bottom: 20px;
    text-align: left !important;
    align-self: flex-start; /* If parent is flexbox */
}
  .details-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-row {
      display: flex;
      justify-content: space-between;
      gap: 20px;


      .form-group {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 5px;

        label {
       
          font-weight: bold;
          margin-bottom: 5px;
        }
        .image-container {
          position: relative;
          display: inline-block;
          width: 200px; /* Adjust to fit the size shown in your image */
          height: 100px;
          border: 1px dashed var(--border); /* Border around the container */
          border-radius: 8px;
          overflow: hidden;
        
          .uploaded-image {
            width:200px;
            height: 100px;
            object-fit: cover; /* Ensures the image fills the container */
            border-radius: 8px;
          }
        
          .close-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            font-size: 16px;
            color: var(--white);
            background-color: #f44336; /* Red background for the close icon */
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Adds shadow for a floating effect */
          }
        }
      }
    }
  
    .form-row1{
      width: 50%;
    }
      .label{
        font-weight: bold !important;
        margin-bottom: 5px;
      }
  

    .button-row {
      display: flex;
      justify-content: flex-end;

      .next-button {
        background-color: var(--dark-blue);
        color: var(--white);
        border-radius: 5px;
        display: flex;
        align-items: center;

        .right-arrow-icon {
          margin-left: 10px;
        }

        &:hover {
          background-color: #0056b3;
        }
      }
  }
  }
}
 /* Media Queries for Responsive Design */
 @media (min-width: 1400px) {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 1200px) {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 992px) {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 768px) {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
}

@media (min-width: 576px) {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: nowrap;
  }
}

/* Stack fields in a column for small screens */
@media (max-width: 575.98px) {
  .form-row {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    margin-bottom: 15px;
  }
  .form-row1{
      width:100% !important;
    }

  .submit-button {
    width: 100%;
    margin: 0 auto; /* Center the button on small screens */
  }
}

}

.add-subscription {
    background-color: var(--whitesmoke);
    padding: 20px;

    .breadcrumb-container {
        display: flex;
        align-items: center;
        padding: 10px ;
        font-size: 16px;
        color: var(--grey);

      background-color: var(--white);

      .breadcrumb-icon {
        color: var(--grey);
        margin-right: 8px;
      }

      .breadcrumb-text {
        margin: 0 5px;
        text-decoration: none; // Remove underline
        color: inherit;
    &:hover {
      text-decoration: none; // Ensure no underline on hover

    }
      }

      .breadcrumb-separator {
        margin: 0 5px;
        color: var(--fade-grey);
      }
      }
      .subscription-list-container{
        padding:5px;
      border-radius: 10px;
      }
    .add-subscription-page {
        border: 1px solid var(--border);
        padding: 10px;
        border-radius: 10px;
        background-color: var(--white);

        .add-subscription-header {
            font-weight: bold;
            margin-bottom: 20px;
        }

        .add-subscription-form{
            .form-row {
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;
                gap: 15px;
            }

            .form-group {
                display: flex;
                flex-direction: column;
                width: 48%;
            }

            
        }
    }

    

    .item-details {
        background-color: var(--white);
        border: 1px solid var(--border);
        padding: 10px;
        margin-top: 10px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--border-bottom);

            .add-subscription-header {
                font-weight: bold;
                margin: 0;
            }

        }

        .table-container {
            width: 100%;
            overflow-x: auto; /* Enable horizontal scrolling */
            margin-top: 20px;

            .table-bordered {
                width: 100%;
                border-collapse: collapse;

                th, td {
                    font-size: 14px;
                    padding: 10px; /* Adjust padding for better spacing */
                    border-bottom: 1px solid var(--border-bottom);
                    white-space: nowrap; /* Prevent text wrapping */
                }

                th {
                    background-color: var(--th);
                    text-align: left;
                    font-weight: bold;
                    min-width: 150px; /* Set a minimum width for each header */
                }

                .table-input {
                    border: none;
                    border-bottom: 1px solid var(--border-bottom);
                    width: 100%;
                }

                .empty {
                    background-color: var(--whitesmoke);
                }
            }
        }



        .button-class {
            display: flex;
            justify-content: flex-end;

            .add-new-button2 {
                background-color: var(--white);
                color: var(--grey);
                border: 1px solid var(--border);
                border-radius: 6px;
                margin: 0px;
            }
        }

        .form-actions {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            padding: 10px;
         


            .btn-save {
                background-color: var(--dark-blue);
                color: var(--white);
                padding: 7px;
                border-radius: 4px;
            }
        }
    }

    // Media queries for responsiveness
    @media (max-width: 1400px) {
        .add-subscription-page{
            .add-subscription-form{
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
    }
}
    }

    @media (max-width: 1200px) {
        .add-subscription-page{
            .add-subscription-form{
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
    }
}
    }

    @media (max-width: 992px) {
        .add-subscription-page{
            .add-subscription-form{
        .form-row {
            flex-direction: row !important;
        }
        .form-group {
            width: 48%;
        }
    }

}
    }
    @media (max-width: 768px) {
        .add-subscription-page{
            .add-subscription-form{
        .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }
        .table-bordered {
            overflow-x: auto;
            display: block;
            width: 100%;
        }
    }
}
    }

    @media (max-width: 576px) {
        .add-subscription-page{
            .add-subscription-form{
        .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }
        .table-bordered {
            overflow-x: auto;
            display: block;
            width: 100%;
        }
    }
}
    }

    // Apply column layout for screens 575.98px and below
    @media (max-width: 575.98px) {
        .add-subscription-page{
        .add-subscription-form{
      .form-row {
            flex-direction: column !important;
        }
        .form-group {
            width: 100% !important;
        }

        .header {
            .add-subscription-header {
                margin-bottom: 5px;
            }
            flex-direction: column !important;
            align-items: center;
            overflow: hidden;
        }

        .table-bordered {
            overflow-x: auto;
            display: block;
            width: 100%;
        }
    }
}
    }
}